import React, { useState, useEffect } from 'react';
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import ManagerHeader from "../../pages/Manager/layout/MangerHeader"
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneIcon from '@mui/icons-material/Done';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';



export default function UserData(props) {
    const [GetReservations, setGetReservations] = useState([]);
    const [getspecificdata, setGetspecificdata] = useState([]);


    const Role = props.role
    const [loader, setLoader] = useState(false);
    const [searchdata, setSearchdata] = useState("");
    console.log("searchdata",searchdata)


    const navigate = useNavigate();

    // Add MOdal
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");



    // Edit

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState('');
    const [id, setId] = useState("");
    const [pagination, setPagination] = useState("");
    const [previous, setPrevious] = useState("");
    console.log("pagination==",pagination)

 // SUCCESS STATE
 const [error, setError] = useState(false);
 const [message, setMessage] = useState("");
 const handleCloseB = () => setError(false);

 // INFO STATES 
 const [error1, setError1] = useState(false);
 const [message1, setMessage1] = useState("");
 const [message2, setMessage2] = useState("");
 const handleCloseB1 = () => setError1(false);

 //   Failed STATES 

 const [error2, setError2] = useState(false);
 const [message3, setMessage3] = useState("");
 const handleCloseB2 = () => setError2(false);




    console.log("pagination",pagination)
    console.log("previous",previous)










    useEffect(() => {
        GetData()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")




    const GetData = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/customerRegister?role=superadmin&endpage=0`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("user Data", result);
                        setGetReservations(result.data);
                        setPagination(result.nextindex)
                        setPrevious(result.previous)


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/customerRegister?role=subadmin&endpage=0`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("userDATA MANAGER", result);
                        setGetReservations(result.data);
                        setPagination(result.nextindex)
                        setPrevious(result.previous)



                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }

// Add data 

const AddCustomer = () => {
    if (Role == "superadmin") {
        setLoader(true)

        if (fname == "" || lname == "" || email == "" || contact == "" || password=="") {
            setLoader(false)
            setError2(true)
            setMessage3("All keys are required ")
        }
        else {
            const form = new FormData()
            form.append("fname", fname);
            form.append("lname", lname);
            // form.append("address", address);
            form.append("contact", contact);
            form.append("password", password);
            form.append("email", email);


            var requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/addcustomer?role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status && result.status != "False") {
                        GetData()
                        setFname("")
                        setLname("")
                        setAddress("")
                        setEmail("")
                        setContact("")
                        toggle1()
                        setError(true)
                        setMessage(result.message)
                       

                    }

                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        }
                        else {
                            toggle1()
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }

                })
                .catch(error => {
                    toggle1()
                    setLoader(false);
                    setError2(true)
                     setMessage3(error)
                });

        }
    }
    else {

        setLoader(true)

        if (fname == "" || lname == ""|| email == "" || contact == "" || password=="") {
            setLoader(false)
            setError2(true)
            setMessage3("All keys are required ")
        }
        else {
            const form = new FormData()
            form.append("fname", fname);
            form.append("lname", lname);
            // form.append("address", address);
            form.append("password", password);
            form.append("email", email);
            form.append("contact", contact);



            var requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/addcustomer?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status && result.status != "False") {
                        GetData()
                        setFname("")
                        setLname("")
                        setAddress("")
                        setEmail("")
                        setContact("")
                        toggle1()
                        setError(true)
                        setMessage(result.message)
                       
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        }
                        else {
                            toggle1()
                            setError(true)
                        setMessage(result.message)
                        }
                    }
                })
                .catch(error => {
                    toggle1()
                    setLoader(false);
                    setError(true)
                    setMessage(error)
                });

        }
    }

}



    // Edit api
    const Update = () => {
        if (Role == "superadmin") {
            setLoader(true)

            if (fname == "" || lname == "" || contact == "" || id == "") {
                setLoader(false)
                setError2(true)
                setMessage3("All keys are required ")
            }
            else {
                const form = new FormData()
                form.append("fname", fname);
                form.append("lname", lname);
                // form.append("address", address);
                form.append("contact", contact);
                form.append("id", id);

                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/customerEditDelete?role=superadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            GetData()
                            toggle()
                            setError(true)
                            setMessage(result.message)
                        }

                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            }
                            else {
                                toggle()
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }

                    })
                    .catch(error => {
                        toggle()
                        setLoader(false);
                        setError2(true)
                                setMessage3(error)
                    });

            }
        }
        else {

            setLoader(true)

            if (fname == "" || lname == ""  || contact == "" || id == "") {
                setLoader(false)
                setError2(true)
                setMessage3("All keys are required ")
            }
            else {
                const form = new FormData()
                form.append("fname", fname);
                form.append("lname", lname);
                // form.append("address", address);
                form.append("contact", contact);
                form.append("id", id);

                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/customerEditDelete?role=subadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            GetData()
                            toggle()
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            }
                            else {
                                toggle()
                                setError(true)
                            setMessage(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        toggle()
                        setLoader(false);
                        setError(true)
                        setMessage(error)
                    });

            }
        }

    }

    const ConfirmDelete = (a)=>{
        if (a) {
            if (Role == "superadmin") {


                setLoader(true)
                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                };
                fetch(`${baseUrl.baseUrl}webapi/customerEditDelete?role=superadmin&id=${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {
                            setError(true)
                            setMessage(result.message)
                            setError1(false)

                            GetData()
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            } else {
                                setError2(true)
                                setMessage3(result.message)



                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });

            }
            else {

                setLoader(true)
                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                };
                fetch(`${baseUrl.baseUrl}webapi/customerEditDelete?role=subadmin&id=${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {
                            setError(true)
                            setMessage(result.message)
                            setError1(false)

                            GetData()
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            } else {
                                setError2(true)
                                setMessage3(result.message)



                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });


            }
        }

    }



    const DeleteData = () => {

        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to delete this User data?")
       .then((result) => {
           ConfirmDelete(result.isConfirmed)
           
        })
    }

const handleNext = ()=>{
    if(pagination == "null"){
        return true
    }
    if (Role == "superadmin") {


        console.log("pagination",pagination)
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/customerRegister?role=superadmin&endpage=${pagination}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("user Data", result);
                    setGetReservations(result.data);
                    setPagination(result.nextindex)
                    setPrevious(result.previous)


                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });

    }
    else {

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + ManagerToken
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/customerRegister?role=subadmin&endpage=${pagination}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("userDATA MANAGER", result);
                    setGetReservations(result.data);
                    setPagination(result.nextindex)
                    setPrevious(result.previous)




                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/ManagerLogin")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }

}

const handlePrevious = ()=>{
    if(previous=="null"){
        return true
    }
    if (Role == "superadmin") {
        console.log("pagination",previous)
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/customerRegister?role=superadmin&endpage=${previous}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("user Data", result);
                    setGetReservations(result.data);
                    setPagination(result.nextindex)
                    setPrevious(result.previous)



                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });

    }
    else {

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + ManagerToken
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/customerRegister?role=subadmin&endpage=${previous}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("userDATA MANAGER", result);
                    setGetReservations(result.data);
                    setPagination(result.nextindex)
                    setPrevious(result.previous)




                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/ManagerLogin")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }

}



// SPECIFIC DETAIL 


const GetSpecificData = (id) => {
    if (Role == "superadmin") {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/specificDetails?role=superadmin&customerid=${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("Specific user Data", result.data);

                    setGetspecificdata(result.data)
                   
                    if(result.data.length> 0){
                         setLoader(true)

                        navigate(
                            '/SpecificData',
                            {
                              state: {data:result.data,Role:"superadmin" }  }     )

                    }

                   
                  


                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });

    }
    else {

        // setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + ManagerToken
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/specificDetails?role=subadmin&customerid=${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status && result.status != "False") {
                    console.log("Specific user Data", result);

                    setGetspecificdata(result.data)
                   
                    if(result.data.length> 0){
                         setLoader(true)

                        navigate(
                            '/SpecificData',
                            {
                              state: {data:result.data ,Role:"subadmin" }  }     )

                    }

                   



                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/ManagerLogin")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }

}





    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={ConfirmDelete} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ? <AdminHeader /> : <ManagerHeader />}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">

                                    <h4 className="item_title">Customers Data</h4>
                                    <button type="button" onClick={()=>setModal1(true)} className="main-btn float-right">Add Customer</button>
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                onSearchChange={(e) => setSearchdata(e)}
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Last Name', field: 'lname' },
                                                        { title: 'First Name', field: 'fname' },
                                                        { title: 'Email ', field: 'email' },
                                                        // { title: 'Address', field: 'address' },
                                                        { title: 'Contact', field: 'contact' },
                                                        { title: 'Role', field: 'role' },

                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        rowData => ({

                                                            icon: Edit,
                                                            tooltip: 'Edit',
                                                            onClick: (event, rowData) => {
                                                                toggle()
                                                                setFname(rowData.fname)
                                                                setLname(rowData.lname)
                                                                setAddress(rowData.address)
                                                                setContact(rowData.contact)
                                                                setId(rowData.id)

                                                                // console.log("rowda/ta=> 1", rowData)

                                                            }
                                                        }),

                                                        rowData => ({

                                                            icon: DeleteIcon,
                                                            tooltip: 'Delete',
                                                            onClick: (event, rowData) => {
                                                                   setId(rowData.id)
                                                                DeleteData(rowData.id)
                                                                // console.log("rowdata=> 1", rowData)

                                                            }
                                                        }),


                                                        
                                                        rowData => ({

                                                            icon: RemoveRedEyeIcon,
                                                            tooltip: 'Detail',
                                                            onClick: (event, rowData) => {
                                                                 
                                                                GetSpecificData(rowData.id)
                                                                console.log("redeye=> 1", rowData)

                                                            }
                                                        }),
                                                        

                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        pageSize:10,

                                                        // exportButton: true
                                                    }}
                                                    components={{
                                                        Pagination: (props) => {
                                                            // console.log(props);
                                                            return (
                                                                <div style={{
                                                                    float: "right",
                                                                    overflowX: "auto"
                                                                }}>
                                                                    <ArrowLeftIcon fontSize={"large"} color={previous=="null" ? "dark" :"primary"}    onClick={()=>handlePrevious()} />

                                                                    <ArrowRightIcon fontSize={"large"} color={pagination=="null" ? "dark" :"primary"}  onClick={()=>handleNext()}/>
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ? <AdminFooter /> : <ManagerFooter />}



{/* ADD MODAL  */}
            <Modal className='text-center' isOpen={modal1} size="md" >
                <ModalHeader >
                    <modalTitle> Add New Customer</modalTitle>
                    <i onClick={toggle1} style={{ fontSize: "24px", marginLeft: "302px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">



                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={fname} onChange={(e) => { setFname(e.target.value) }} className="form-control" placeholder='First Name' />
                                <input type="text" value={lname} onChange={(e) => {
                                    setLname(e.target.value)
                                }} className="form-control" placeholder='Last Name' />




                            </div>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} className="form-control" placeholder='Email' />


                                <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control" placeholder='Password' />


                            </div>

                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={contact} onChange={(e) => { setContact(e.target.value) }} className="form-control" placeholder='Contact Number' />
                                {/* <input type="text" value={address} onChange={(e) => { setAddress(e.target.value) }} className="form-control" placeholder='Address' /> */}



                              


                            </div>


                        </div>

                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => setModal1(false)} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={()=>AddCustomer()}  className="col-2 main-btn">Add</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

{/* Edit modal  */}

            <Modal className='text-center' isOpen={modal} size="md" >
                <ModalHeader >
                    <modalTitle> Update User Data</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "302px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">



                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={fname} onChange={(e) => { setFname(e.target.value) }} className="form-control" placeholder='First Name' />
                                <input type="text" value={lname} onChange={(e) => {
                                    setLname(e.target.value)
                                }} className="form-control" placeholder='Last Name' />




                            </div>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                {/* <input type="text" value={address} onChange={(e) => { setAddress(e.target.value) }} className="form-control" placeholder='Address' /> */}


                                <input type="text" value={contact} onChange={(e) => { setContact(e.target.value) }} className="form-control" placeholder='Contact No' />


                            </div>


                        </div>

                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => setModal(false)} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => Update()} className="col-2 main-btn">Update</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>






        </>
    );
}
