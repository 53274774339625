import React from 'react'
import Booking from '../../../components/booking/Booking'


export default function ManagerBooking() {
  return (
    <>
<Booking role={"subadmin"}/>
    </>
  )
}
