import React from 'react'
import Category from "../../../components/categories/Categories"


export default function ManagerCategory() {
  return (
    <>
<Category role={"subadmin"}/>
    </>
  )
}
