import React, { useState, useEffect } from 'react';
import ManagerHeader from "../layout/MangerHeader"
import ManagerFooter from "../layout/ManagerFooter"
import { Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from "material-table";
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import baseUrl from "../../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';

import EditIcon from '@material-ui/icons/Edit';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
// import Avator from "../../Admin/auth/avator.jpeg"
import Avator from "../../Admin/auth/dummy-profile.png"
import { color, fontSize, fontWeight } from '@mui/system';
import { Button, Checkbox, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import img from "./Profile.png"
import State from "./State.json"
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed'
import TimeConverter from '../../../components/timeconverter/TimeConverter';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));




export default function RiderRegistrattion(props) {
    const classes = useStyles();
    const [Selectedprofile, setSelectedprofile] = useState("")
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [getmanager, setGetmanager] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [selectedRow, setSelectedRow] = useState("");
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [lname, setLname] = useState("");
    const [fname, setFname] = useState("");
    const [address, setAddress] = useState("");
    const [schedules, setSchedules] = useState([]);

    const [profile, setProfile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contact, setContact] = useState("");
    const [state, setState] = useState("");
    const [shift, setShift] = useState([]);
    const [shiftForPost, setShiftForPost] = useState([]);
    const [riders, setRiders] = useState([]);

    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);
    const [id, setId] = useState("");
    const [check, setCheck] = useState(false);


    // EDIT STATES 
    const [elname, seteLname] = useState("");
    const [efname, seteFname] = useState("");
    const [eaddress, seteAddress] = useState("");
    const [eemail, seteEmail] = useState("");
    const [econtact, seteContact] = useState("");
    const [estate, seteState] = useState("");
    const [eprofile, seteProfile] = useState("");
    const [eid, seteId] = useState("");


    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal(!modal1);

    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal(!modal2);
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [hours, setHours] = useState([]);
    const [totalhours, setTotalhours] = useState([]);
    const [loading, setLoading] = useState(false);



    console.log("shift", shift)
    console.log("shiftForPost", shiftForPost)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const navigate = useNavigate();
    const Token = localStorage.getItem("ManagerToken")


    const [personName, setPersonName] = useState([]);
    useEffect(() => {
        GetSchedules()
        GetRiders()
    }, []);

    const GetRiders = async () => {
        setLoader(true)
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/rider?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false)

                if (result.status != "False") {
                    setRiders(result.data);
                    console.log("get Scedule", result.data);
                }

                else {
                    if (result.message == "Unauthorized") {
                        navigate('/ManagerLogin')
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setError2(true)
                setMessage3(error)
            });
    }
    // GET API
    const GetSchedules = async () => {
        setLoader(true)

        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/schedule?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status != "False") {
                    setLoader(false)

                    setSchedules(result.data);
                    console.log("get Scedule 1", result.data);
                }

                else {
                    if (result.message == "Unauthorized") {
                        navigate('/ManagerLogin')
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setError2(true)
                setMessage3(error)
            });
    }


    // ADD API 

    const AddRider = () => {
        setLoader(true)
        if (fname == "" || lname == "" || address == "" || email == "" || password == "" || contact == "") {
            setLoader(false)
            setError2(true)
            setMessage3("All keys are required ")
        }
        else {
            toggle()
            const form = new FormData()
            form.append("fname", fname)
            form.append("lname", lname)
            form.append("address", address)
            form.append("email", email)
            form.append("password", password)
            form.append("contact", contact)
            form.append("profile", profile)
            form.append("state", state)
            form.append("shift", JSON.stringify(shiftForPost))


            var requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/rider?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);

                    if (result.status) {
                        setError(true)
                        setMessage(result.message);
                        toggle()
                        GetRiders()
                        setFname("")
                        setLname("")
                        setAddress("")
                        setContact("")
                        setState("")
                        setShift("")
                        setShiftForPost([])


                    }
                    else {
                        setMessage3(result.message)
                        setModal(true)
                        setTimeout(() => {
                            setError2(true)
                        }, 100);
                    }
                })
                .catch(error => {
                    setModal(true)
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
    }


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const addPackageModal = () => {
        // setFname("")
        setModal(true);
        setSelectedprofile(img)
        // setLname("")
        setProfile(null)
        setFname("")
        setLname("")
        setEmail("")
        setAddress("")
        setPassword("")
        setmodaltitle("Vallet Registration Form");
    }

    // DELETE API 

    const ConfirmDelete = (a) => {
        if (a) {
            setLoader(true)

            var requestOptions = {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + Token
                },
            };
            fetch(`${baseUrl.baseUrl}webapi/rider?role=subadmin&id=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status) {
                        setError(true)
                        setMessage(result.message)
                        setError1(false)
                        GetRiders()
                    }
                    else {
                        setError2(true)
                        setMessage3(result.message)

                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });
        }

    }

    const DeleteService = (id) => {
        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to delete this vallet ?")
            .then((result) => {
                ConfirmDelete(result.isConfirmed)

            })
    }




    // rESERVATIION API 
    const handleChangeStatus = (a) => {


        if (a) {
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                },
            };
            fetch(`${baseUrl.baseUrl}webapi/riderActive_Deactivate?role=subadmin&id=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status) {
                        setCheck(false)
                        setError(true)
                        setMessage(result.message)
                        setError1(false)
                        GetRiders()
                    }
                    else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }

    const Reserve = () => {

        setError1(true)
        setCheck(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to change vallet status?")
            .then((result) => {

                handleChangeStatus(result.isConfirmed)



            })
    }






    // FOR PROFILE UPLOAD CODE
    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            setError2(true)
            setMessage3("plz select your image less then and equals to 1mb ")


        } else {
            var url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);
            };

            func(event.target.files[0])

        }
    }

    const Remove = (index) => {
        const a = shiftForPost.filter((v, i) => i !== index)
        setShiftForPost(a)
    }


    // EDIT API 

    const EditRider = () => {


        setLoader(true)


        if (efname == "" || elname == "" || eaddress == "" || eemail == "" || econtact == "") {
            setLoader(false)
            setError2(true)
            setMessage3("All keys are required ")
        }
        else {
            setLoader(true)

            const form = new FormData()
            form.append("id", eid);
            form.append("fname", efname)
            form.append("lname", elname)
            form.append("address", eaddress)
            form.append("email", eemail)
            form.append("contact", econtact)
            form.append("state", estate)
            if (UpdateThumbnail != false) {

                form.append("profile", UpdateThumbnail)
            }

            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/rider?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status) {

                        setModal1(false)
                        GetRiders()
                        setError(true)
                        setMessage(result.message)


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setModal1(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {
                    setModal1(true)
                    setLoader(false);

                    setError2(true)
                    setMessage3(error)

                });

        }



    }


    const Time = (sid) => {
        setStartdate("")
        setEnddate("")

        console.log("check id", id, sid)
        // setId(id)
        setModal2(true)
        GetRiderhours(sid)

    }


    // GET RIDER HOURS 

    const GetRiderhours = (sid) => {
        // setLoader(true)
        setLoading(true)

        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/riderattendence?role=subadmin&id=${sid ? sid : id}&startdate=${enddate}&enddate=${startdate}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status != "False") {
                    console.log("check", result.data)
                    // setLoader(false)
                    setLoading(false)

                    setHours(result.data)
                    setTotalhours(result.totalhours)



                }

                else {
                    if (result.message == "Unauthorized") {
                        navigate('/')
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setError2(true)
                setMessage3(error)
            });
    }



    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={ConfirmDelete} reserve={handleChangeStatus} checker={check} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            <ManagerHeader />
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Vallet Managment </h4>
                                    <button type="button" onClick={() => addPackageModal()} className="main-btn float-right">Add Vallet</button>
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Rider Name', field: 'fname' },
                                                        { title: 'Address', field: 'address', render: item => <> {item.address.slice(0, 10)}......</> },
                                                        { title: 'Contact', field: 'contact', render: item => <> {item.contact}</> },
                                                        { title: 'Email', field: 'email', render: item => <> {item.email}</> },
                                                        {
                                                            title: 'Time Calculation', render: (rowData) =>
                                                                <>

                                                                    <i className='far fa-clock' style={{ fontSize: "25px" }} onClick={() => { setId(rowData.id); Time(rowData.id) }} />
                                                                </>
                                                        },
                                                        {
                                                            title: 'Status', field: 'status',
                                                            render: item => <> {item.status} </>
                                                        },
                                                    ]}
                                                    data={riders}
                                                    actions={[
                                                        rowData => ({
                                                            hidden: rowData.status != "Active",

                                                            icon: DoneIcon,
                                                            tooltip: 'Change Status',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)

                                                                Reserve()
                                                            }
                                                        }),
                                                        rowData => ({
                                                            hidden: rowData.status != "Disable",

                                                            icon: CloseIcon,
                                                            tooltip: 'Change Status',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)
                                                                Reserve()
                                                            }
                                                        }),

                                                        {



                                                            icon: DeleteIcon,
                                                            tooltip: 'Delete User',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)

                                                                DeleteService()
                                                            }
                                                        },
                                                        {
                                                            icon: Edit,
                                                            tooltip: 'Edit User',
                                                            onClick: (event, rowData) => {
                                                                console.log(" eDIT rowdata=>", rowData)
                                                                setModal1(true)
                                                                seteFname(rowData.fname)
                                                                seteLname(rowData.lname)
                                                                seteEmail(rowData.email)
                                                                seteAddress(rowData.address)
                                                                seteProfile(rowData.profile)
                                                                seteContact(rowData.contact)
                                                                seteState(rowData.state)
                                                                seteId(rowData.id)
                                                            }
                                                        }
                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true
                                                    }}
                                                />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ManagerFooter />

            {/* //Add MOdal  */}

            <Modal className='text-center' isOpen={modal} size="md" >
                <ModalHeader >
                    <modalTitle> {modaltitle}</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "400px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    {/* <Button variant="contained" component="label">
                                        Upload */}
                                    {/* <input hidden accept="image/*"  type="file" */}



                                    <div className="card-body">
                                        <center className="m-t-30">

                                            <div className="upload_photo_main photo rounded-circle" >
                                                <input
                                                    onChange={(e) => handleInputFileChange(e, setProfile)}

                                                    accept="image/*"
                                                    className={classes.input}
                                                    id="icon-button-file"
                                                    type="file"

                                                />
                                                <label htmlFor="icon-button-file">
                                                    <IconButton
                                                        color="#414141"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        className="upload_photo_main "
                                                    >
                                                        <img src={Selectedprofile ? Selectedprofile : baseUrl.image_url + profile} style={{ minWidth: "170px", maxWidth: "175px", borderRadius: "20px" }} className="rounded-circle upload_photo_main" />
                                                    </IconButton>

                                                </label>


                                            </div>
                                            {/* <img src={baseUrl.image_url + profile} {Selectedprofile ? Selectedprofile : profileimage} loading="lazy" className="img-circle" width={150} height={150} alt="Profile" />
                            <h4 className="card-title m-t-10 text-uppercase">{fname} {lname}</h4> */}
                                        </center>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={fname} onChange={(e) => { setFname(e.target.value) }} className="form-control" placeholder='First Name' />
                                <input type="text" value={lname} onChange={(e) => {
                                    setLname(e.target.value)
                                }} className="form-control" placeholder='Last Name' />


                                {/* <TextField id="standard-basic" label="First Name" variant="outlined"
                                    fullWidth
                                    defaultValue={fname}
                                    value={fname} onChange={(e) => {
                                        setFname(e.target.value)
                                    }} /> */}

                                {/* <TextField id="standard-basic" label="Last Name" variant="outlined"
                                    fullWidth
                                    defaultValue={lname}
                                    value={lname} onChange={(e) => {
                                        setLname(e.target.value)
                                    }} /> */}

                            </div>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={address} onChange={(e) => { setAddress(e.target.value) }} className="form-control" placeholder='Address' />

                                {/* <TextField id="standard-basic" label="Address" variant="outlined"
                                    fullWidth
                                    type="text"
                                    defaultValue={address}
                                    value={address} onChange={(e) => {
                                        setAddress(e.target.value)
                                    }} /> */}
                                <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} className="form-control" placeholder='Email ' />

                                {/* <TextField id="standard-basic" label="Email  " variant="outlined"
                                    fullWidth
                                    type="email"
                                    defaultValue={email}
                                    value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} /> */}
                            </div>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={contact} onChange={(e) => { setContact(e.target.value) }} className="form-control" placeholder='Contact No' />
                                {/* 
                                <TextField id="standard-basic" label="Contact No." variant="outlined"
                                    fullWidth
                                    defaultValue={contact}
                                    value={contact} onChange={(e) => {
                                        setContact(e.target.value)
                                    }} /> */}
                                <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control" placeholder='Password' />

                                {/* <TextField id="standard-basic" label="Password" variant="outlined"
                                    fullWidth
                                    type="password"
                                    defaultValue={password}
                                    value={password} onChange={(e) => {
                                        setPassword(e.target.value)
                                    }} /> */}


                            </div>
                            <label style={{ marginBottom: "0px", padding: "15px" }}>Select State*</label>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <select value={state} onChange={(e) => setState(e.target.value)} class="form-select" style={{ width: "100%", padding: "1.5rem" }}>
                                    <option selected>Select state...</option>
                                    {State.map((v, i) => {
                                        return <option value={v.name} >{v.name}</option>
                                    })}

                                </select>


                            </div>

                        </div>

                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle()} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => AddRider()} className="col-2 main-btn">Add</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>





            {/* //Edit MOdal  */}

            <Modal className='text-center' isOpen={modal1} size="md" >
                <ModalHeader >
                    <modalTitle> Edit</modalTitle>
                    <i onClick={toggle1} style={{ fontSize: "24px", marginLeft: "302px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">




                                    <div className="card-body">
                                        <center className="m-t-30">

                                            <div className="upload_photo_main photo rounded-circle" >
                                                <input
                                                    onChange={(e) => handleInputFileChange(e, seteProfile)}

                                                    accept="image/*"
                                                    className={classes.input}
                                                    id="icon-button-file"
                                                    type="file"

                                                />
                                                <label htmlFor="icon-button-file">
                                                    <IconButton
                                                        color="#414141"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        className="upload_photo_main "
                                                    >
                                                        <img src={Selectedprofile ? Selectedprofile : eprofile} style={{ minWidth: "170px", maxWidth: "175px", borderRadius: "20px" }} className="rounded-circle upload_photo_main" />
                                                    </IconButton>

                                                </label>


                                            </div>

                                        </center>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={efname} onChange={(e) => { seteFname(e.target.value) }} className="form-control" placeholder='First Name' />
                                <input type="text" value={elname} onChange={(e) => {
                                    seteLname(e.target.value)
                                }} className="form-control" placeholder='Last Name' />


                            </div>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={eaddress} onChange={(e) => { seteAddress(e.target.value) }} className="form-control" placeholder='Address' />


                                <input type="email" value={eemail} className="form-control" placeholder='Email ' disabled />


                            </div>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="text" value={econtact} onChange={(e) => { seteContact(e.target.value) }} className="form-control" placeholder='Contact No' />

                                {/* <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control" placeholder='Password' /> */}




                            </div>
                            <label style={{ marginBottom: "0px", padding: "15px" }}>Select State*</label>
                            <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <select value={estate} onChange={(e) => seteState(e.target.value)} class="form-select" style={{ width: "100%", padding: "1.5rem" }}>
                                    <option selected>Select state...</option>
                                    {State.map((v, i) => {
                                        return <option value={v.name} >{v.name}</option>
                                    })}

                                </select>


                            </div>

                        </div>

                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => setModal1(false)} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => EditRider()} className="col-2 main-btn">Edit</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>


            {/* TIME CALCUALTION  */}

            <Modal className='text-center' isOpen={modal2} size="lg" >
                <ModalHeader >
                    <modalTitle> Time Calculation</modalTitle>
                    <i onClick={toggle2} style={{ fontSize: "24px", marginLeft: "302px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    {/* <button type="button" className="main-btn float-right">Reset</button> */}
                    <LoadingButton
                        className="main-btn float-right"
                        onClick={() => GetRiderhours()}
                        endIcon={<SendIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        style={{ backgroundColor: "rgb(3,186,254)" }}
                    >
                        Search
                    </LoadingButton>
                    {/* <button type="button" onClick={()=>GetRiderhours()} className="main-btn float-right">Search</button> */}
                    <input type="date" value={startdate} onChange={(e) => setStartdate(e.target.value)} className="main-btn float-right" />
                    <input type="date" value={enddate} onChange={(e) => setEnddate(e.target.value)} className="main-btn float-right" />
                    <h3>Total Hours : {totalhours}</h3>
                    <div className="la5lo1">
                        <div className="row">
                            <div className="col-md-12 item">
                                <MaterialTable
                                    title=""
                                    icons={{
                                        Check: Check,
                                        DetailPanel: ChevronRight,
                                        // Delete: DeleteOutline,
                                        Export: SaveAlt,
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        Search: Search,
                                        ThirdStateCheck: Remove,
                                        Add: Add,
                                        SortArrow: ArrowDownward,
                                        Clear: Clear,
                                        Edit: Edit,
                                        ViewColumn: ViewColumn,
                                        ResetSearch: Clear
                                    }}

                                    columns={[
                                        { title: 'Date', field: 'date' },
                                        // { title: 'Address', field: 'address', render: item => <> {item.address.slice(0, 10)}......</> },
                                        { title: 'Time In', field: 'timecome' },
                                        { title: 'Time Out', field: 'timego' },
                                        { title: 'Duration', field: 'duration' },

                                        // { title: 'Total Hours', field: 'totalhours' },


                                    ]}
                                    data={hours}


                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#03bafe',
                                            color: '#FFF'
                                        },
                                        actionsColumnIndex: -1,
                                        // exportButton: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                </ModalBody>
                <ModalFooter>

                    <button type="button" onClick={() => setModal2(false)} className="col-2 main-btn cancel mx-3">Close</button>
                    {/* <button type="button" className="col-2 main-btn">Edit</button> */}


                </ModalFooter>

            </Modal>


        </>
    );
}
