import { useState } from "react";
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader"
import logo from "../../Admin/auth/newlogo.png"
import { useNavigate,useLocation } from "react-router-dom";
import baseUrl from "../../../baseurl/BaseUrl";


export default function ManagVerificationCode() {
  const [Email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()




  const [Code, setCode] = useState("");
  console.log("location",location.state.userId)




  const handleInputChange = (event, func) => {
    func(event.target.value);

  }
  const Token = localStorage.getItem("ManagerToken")

 const VerifyCode = () => {

        setLoader(true)
if(Code==""){

    setLoader(false)
    Swal.fire({
        title: "Error",
        text: "Code is required",
        icon: "error",
        confirmButtonColor: "#03bafe",
    });

}
else{



        const formData = new FormData();
    formData.append("token", Code);
    formData.append("id", location.state.userId);


        var requestOptions = {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + Token
            },
            body: formData,
        };
        // setLoader(true);
        fetch(`${baseUrl.baseUrl}webapi/forgettokenCheck?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                  console.log("result===>verification",result.id);

                  navigate( "/ManagNewPassword",{ state:{userId: location.state.userId}})

                    Swal.fire({
                        title: "Success",
                        text: result.message,
                        icon: "success",
                        confirmButtonColor: "#03bafe",
                    });
                }
                else {

                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }
            })
            .catch(error => {

                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });
        }
    }




  return (<>
    {/* {loader == true ? <Loader fullPage loading /> : null} */}
    <div className="sign_in_up_bg">
      <div className="container">
        <div className="row justify-content-lg-center justify-content-md-center">

          <div className="col-lg-6 col-md-8">
            <div className="sign_form">
              <img src={logo} width={"80px"} height={"80px"} alt=""></img>

              <p className="mt-5">We have emailed a 5-digit confirmation code to your email, please enter the code in below box to verify your email.</p>
              <form>
                <div className="ui search focus mt-50">


                    <label className="label25 text-left">Varification Code<span className="text-danger">*</span></label>
                    <div className="ui left icon input swdh95">
                      <input value={Code}  onChange={(e) => handleInputChange(e, setCode)} className="prompt srch_explore"  type="number" name="varification code"  required maxLength={64} placeholder="Enter varification Code" />
                      {/* <i className="uil uil-envelope icon icon2" /> */}
                    </div>


                </div>

                  <button onClick={()=>VerifyCode()}  className="login-btn" type="button" >Confirmation</button>



              </form>

            </div>

          </div>
        </div>
      </div>

    </div>
  </>

  )
}