import React, { useState, useEffect } from 'react';
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton'
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';




export default function PendingReservations(props) {
    console.log("superadmin role? pending", props.role)
    const Role = props.role
    const [GetReservations, setGetReservations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);



    // Edit

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [ids, setIds] = useState("");
    const [actions, setActions] = useState("");
    const [reason, setReason] = useState("");
    // console.log("action",action)

    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);
    const [id, setId] = useState("");










    const Reject = () => {
  
      
        setModal(true)
    }

    console.log("id=>", ids)


    useEffect(() => {
        GetServices()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")


    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=pending`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=pending`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
               setMessage3(error)
                });
        }

    }




    // Edit api
    const EditReservation = (a) => {

        if (a) {

        if (Role == "superadmin") {
            setLoader(true)


            const form = new FormData()

            form.append("action", actions);
            form.append("id", id);
            form.append("reason", reason);


            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    setError1(false)
                    console.log(result);
                    if (result.status) {
                        GetServices()
                        setModal(false)
                        setError(true)
                        setMessage(result.message)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });
        }
        else {

            setLoader(true)


            const form = new FormData()
            form.append("action", actions);
            form.append("id", id);
            form.append("reason", reason);


            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    setError1(false)

                    console.log(result);
                    if (result.status) {
                        GetServices()
                        setModal(false)

                        setError(true)
                            setMessage(result.message)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });
        }
    }

    }


    const Reserve = () => {
       
        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to change this reservation status?")
        .then((result) => {
        EditReservation(result.isConfirmed)
           
        })
    }
    return (
        <>
         <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={EditReservation} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ? <AdminHeader /> : <ManagerHeader />}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Pending Reservations</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Customer Name', field: 'customername' },
                                                        { title: 'Service Name', field: 'servicename' },
                                                        { title: 'License Plate ', field: 'licensePlate' },
                                                        { title: 'Start Date', field: 'startdate' },
                                                        { title: 'End Date', field: 'enddate' },
                                                        { title: 'Total Amount', field: 'totalamout' ,render:rowData=>"$"+rowData.totalamout.toFixed(2)},
                                                        { title: 'Depature Date', field: 'departure_airline' },
                                                        { title: 'Return Date', field: 'return_airline' },
                                                        { title: 'Status', field: 'status' },


                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        {
                                                            icon: AutorenewIcon,
                                                            tooltip: 'Accept',
                                                            onClick: (event, rowData) => {

                                                                console.log("rowdata=> 1", rowData.id)
                                                                setId(rowData.id)
                                                                setActions("accept")

                                                                Reserve()

                                                            }
                                                        },
                                                        {
                                                            icon: CloseIcon,
                                                            tooltip: 'Reject',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)
                                                                setActions("reject")
                                                                setModal(true)
                                                                // Reject()


                                                                // setId(rowData.id)

                                                                // setAction("reject")

                                                            }
                                                        }
                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize:10,

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ? <AdminFooter /> : <ManagerFooter />}

            <Modal className='text-center' isOpen={modal} size="md" centered={true}>
                <ModalHeader >
                    <modalTitle className="">Rejection Reason</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "250px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-md-12 ">
                                <p for="w3review"> Reason</p>
                                <textarea value={reason} onChange={(e) => setReason(e.target.value)} className='form-control' id="w3review" name="w3review" rows="6" cols="50"></textarea>
                            </div>


                        </div>
                        <div className="row text-center justify-content-end mt-2">

        
                        <LoadingButton
                                
                                onClick={() => Reserve()}
                                color="primary"
                                loading={loader}
                                
                                 variant="contained"
                            >
                              Submit
                            </LoadingButton>

                            <button type="button" onClick={() => toggle()} className="col-2 main-btn cancel mx-3">Close</button>

                          
                            {/* <button type="button" onClick={() => Reserve(ids,actions)} className="col-2 main-btn" >Add</button> */}
                        </div>
                    </form>
                </ModalBody>
            </Modal>




        </>
    );
}
