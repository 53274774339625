import baseUrl from "../baseurl/BaseUrl";

export const TIMEIN = "TIMEIN";


const Timein=(data)=>{
    return(dispatch)=>{
        const Token = localStorage.getItem("RiderToken")

        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };

        fetch(`${baseUrl.baseUrl}webapi/ridertimingDetails`, requestOptions)
        .then(response => response.json())
        .then(result => {
       

            if (result.length > 0) {
                console.log("donedata",result)
              

                    console.log("response",result);
                dispatch({type:'TIMEIN',  payload:result})
            }  
        })
        .catch(error => {
            console.log(error);
        });
        
   
       
    
    
    
      }
     
}

export { Timein}