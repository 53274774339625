import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Admin/auth/newlogo.png"
import { Loader } from "react-overlay-loader";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2";
import baseUrl from "../../../baseurl/BaseUrl";
import Avator from "../../Admin/auth/avator.jpeg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed';
import Clock from "../../../components/clock/Clock";
import { useDispatch } from "react-redux";
import { useSelector, } from 'react-redux';
import { Timein } from "../../../redux/action";
import moment from 'moment'
import TimeConverter from "../../../components/timeconverter/TimeConverter";
import { io } from 'socket.io-client';
// const socket = io("https://sockets.laxairportvaletservices.com/")
const socket = io("https://sockets.hnhsofttechsolutions.com/")



export default function Header() {
    const [Sidebar, setSidebar] = useState("");
    const [profileDrop, setprofileDrop] = useState("");
    const [profileData, setprofileData] = useState([]);
    const [profileimg, setprofileimg] = useState(null);


    const [loader, setLoader] = useState(false);
    // forgot
    const [OldPassword, setOldPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmNewPassword, setConfirmNewPassword] = useState("");
    const [modaltitle, setmodaltitle] = useState("");
    const [modal, setModal] = useState(false);
    const toggle2 = () => setModal(!modal);
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);


    const Token = localStorage.getItem("RiderToken")
    const Riderid = JSON.parse(localStorage.getItem("RiderData"))
    const riderid = Riderid.id



    const navigate = useNavigate()

    const location = useLocation();
    const dispatch = useDispatch()
    const store = useSelector(store => store)

    const [tim, setTim] = useState("");
    const [dur, setDur] = useState("");


    useEffect(() => {
        if (store.TimeIn.timein.length > 0) {
            setDur(store.TimeIn.timein[0].duration)
            setTim(store.TimeIn.timein[0].In)
        }


    }, [store])



    //destructuring pathname from location
    const { pathname } = location;

    const [active, setActive] = useState("");


    useEffect(() => {
        setActive(pathname)
        if (localStorage.getItem("RiderToken") == null) {
            navigate("/rider/login")
        }
        else {
            getProfile()
            dispatch(Timein())

        }
    }, []);

    useEffect(() => {


        var update = function () {
            setDur(prev => moment(prev, 'HH:mm:ss').add(1, "second").format('HH:mm:ss'))
        }

        const interval = setInterval(update, 1000);
        return () => clearInterval(interval)


    }, [])

    // redux data 




    const notify = () => {
        toast("Admin Assign You New Ride!", {
            autoClose: 5000,

            style: { backgroundColor: "#6BE521", color: "black" },

        }

        );

        // navigate("/RiderReservation")
    }

    useEffect(() => {
        socket.on(riderid, (data) => {
            if (data.status == "drop") {
                navigate("/RiderComplete")


            }
            else {

                navigate("/RiderReservation")

            }



            notify()



        });

        return () => {
            socket.off(riderid);
        };



    }, [])




    const sidebarToggle = (e) => {
        if (e == Sidebar) {
            setSidebar("")
        } else {
            setSidebar(e)
        }
    }



    const profiledropdown = (e) => {
        if (e == profileDrop) {
            setprofileDrop("")
        } else {
            setprofileDrop(e)
        }
    }


    const getProfile = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=rider`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {

                    setprofileData(result.data)

                    setprofileimg(result.data.profile)

                    document.getElementById("profileid").src = result.data.profile



                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/')
                    }
                    setError2(true)
                    setMessage3(result.message)

                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }

    const logout = () => {
        localStorage.removeItem("RiderToken");
        localStorage.removeItem("RiderData");
        navigate("/rider/login");
    }



    const handleInputChange = (e, func) => {
        func(e.target.value);
    }
    // Forget password api

    const forgot = () => {

        if (NewPassword == "" || ConfirmNewPassword == "" || OldPassword == "") {

            setError2(true)
            setMessage3("All keys are required ")

        }
        else {



            if (NewPassword == ConfirmNewPassword) {
                toggle2();
                setLoader(true);
                const form = new FormData()
                form.append("oldpassword", OldPassword);
                form.append("password", NewPassword);
                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                fetch(`${baseUrl.baseUrl}webapi/changepassword?role=rider`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);

                        if (result.status) {


                            setOldPassword("");
                            setNewPassword("");
                            setConfirmNewPassword("");
                            setError(true)
                            setMessage(result.message)
                            setError1(false)
                        }
                        else {
                            setModal(true)
                            setError2(true)
                            setMessage3(result.message)
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            } else {
                setError2(true)
                setMessage3("Password & Confirm Password does not match ")
            }
        }
    }


    const TimeIn = () => {

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}attendence?role=rider&action=in`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {

                    // console.log("time in", result)
                    dispatch(Timein())
                    setError(true)
                    setMessage(result.message)
                    setError1(false)


                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/rider/login')
                    }
                    setError2(true)
                    setMessage3(result.message)

                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }



    const TimeOut = (a) => {

        if (a) {

            setLoader(true);

            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}attendence?role=rider&action=out`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setDur("")
                    setTim("")
                    dispatch(Timein())
                    setLoader(false);
                    setError1(false)
                    if (result.status) {


                        setError(true)
                        setMessage(result.message)


                    }
                    else {

                        if (result.message == "Unauthorized") {
                            navigate('/rider/login')
                        }
                        setError2(true)
                        setMessage3(result.message)

                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
    }

    const Reserve = () => {



        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to Timeout ?")

            .then((result) => {
                TimeOut(result.isConfirmed)

            })
    }

    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={TimeOut} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {/* {loader == true ? <Loader fullPage loading /> : null} */}
            <header className="header clearfix" style={{ backgroundColor: "#03bafe" }}>
                <button onClick={() => sidebarToggle("smallScreen")} type="button" id="toggleMenu" className="toggle_menu">
                    <i className='uil uil-bars'></i>
                </button>
                <button onClick={() => sidebarToggle("largeScreen")} className="collapse_menu">
                    <i className="uil uil-bars collapse_menu--icon "></i>
                    <span className="collapse_menu--label"></span>
                </button>
                <div className="main_logo" id="logo">

                    <Link to="#"><a href="#"><img src={logo} width={"55px"} alt="" /></a></Link>
                    {/* <Link to="#"><a href="#"><img className="logo-inverse" src={logo} width={"190px"} alt="" /></a></Link> */}
                </div>
                <div className="d-flex justify-content-between" style={{ width: "1000px" }}>
                    <h3 className="text-white m-0 " >TimeIn: {tim ? TimeConverter(tim) : "00:00"}</h3>
                    <h3 className="text-white m-0" id="dur">Duration:{dur == "Invalid date" ? "00:00" : dur}</h3>


                </div>




                <div className="header_right">
                    <ul>

                        {/* <li >
                        <Clock/>
                        </li> */}
                        <li className="ui dropdown">

                            <a href="#" className="option_links"><i className="fas fa-user-friends" style={{ color: "white", fontSize: "18px" }}></i></a></li>



                        <li onClick={() => profiledropdown("profile")} className={profileDrop == "profile" ? "ui dropdown active visible" : "ui dropdown"}>
                            <a href="#" className="opts_account" title="Account Information">

                            </a>
                            <img className="rounded-circle" id="profileid" src={Avator} alt="" width="40px" height="40px" />
                            <div className={profileDrop == "profile" ? "menu dropdown_account left transition visible d-block" : "menu dropdown_account"}>
                                <div className="channel_my">
                                    <div className="profile_link">
                                        <img src={profileimg == null ? Avator : profileimg} alt="" />
                                        <div className="pd_content">
                                            <div className="rhte85">
                                                <h6 className="text-capitalize">{profileData?.fname + " " + profileData?.lname}</h6>
                                                <div className="mef78" title="Verify">
                                                    <i className='uil uil-check-circle'></i>
                                                </div>
                                            </div>
                                            <span>
                                                <a href="#"
                                                    className="__cf_email__  text-capitalize"
                                                ></a>
                                            </span>
                                        </div>
                                    </div>
                                    <a href="/rider/profile" className="item channel_item " >View Profile</a>
                                </div>

                                <a href="#" onClick={() => toggle2()} className="item channel_item">Change Password</a>

                                <a onClick={logout} href="#" className="item channel_item">Logout</a>
                            </div>
                        </li>

                    </ul>
                </div>
            </header>


            <nav style={{ backgroundColor: "#03bafe" }} className={Sidebar == "largeScreen" ? "vertical_nav vertical_nav__minify" : (Sidebar == "smallScreen" ? "vertical_nav vertical_nav__opened" : "vertical_nav")}>
                <div className="left_section menu_left" id="js-menu">
                    <div className="sidebar-user">

                        <div className='row ' style={{ backgroundColor: "white" }} >
                            <div className='col safdar' >
                                <img className="rounded-circle" src={profileimg ? profileimg : Avator} width={"60px"} />

                            </div>
                            <div className='col ahmed' style={{ alignItems: "center" }}>
                                <h5>{profileData?.fname}</h5>
                                <p>Vallet</p>
                            </div>


                        </div>
                        <ul>


                            <li className="menu--item">

                                <Link to="/RiderReservation"><a href="#" className={active == "/RiderReservation" ? "menu--link  navActive" : "menu--link "} title="Pickup">
                                    <i className="	fas fa-car menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Pickup</span>
                                </a></Link>

                            </li>

                            <li className="menu--item">

                                <Link to="/RiderComplete"><a href="#" className={active == "/RiderComplete" ? "menu--link  navActive" : "menu--link "} title="Drop">
                                    <i className="fa fa-check-square menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Drop</span>
                                </a></Link>

                            </li>

                            {tim ?
                                <li className="menu--item">

                                    <Link to="/RiderBooking"><a href="#" className={active == "/RiderBooking" ? "menu--link  navActive" : "menu--link "} title="Booking">
                                        <i className="fa fa-book menu--icon d-flex justify-content-center align-items-center"></i>
                                        {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                        <span className=" menu--label">Booking Form</span>
                                    </a></Link>

                                </li>
                                : ""}




                            <li className="menu--item">

                                <Link to="/rider/Profile">  <a href="#" className={active == "/rider/Profile" ? "menu--link  navActive" : "menu--link "} title="Profile">
                                    <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>

                                    <span className="menu--label">Profile</span>
                                </a></Link>

                            </li>

                            <li className="menu--item">

                                <a onClick={TimeIn} href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="timein">
                                    <i className="far fa-clock menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Time In</span>
                                </a>

                            </li>
                            <li className="menu--item">

                                <a onClick={Reserve} href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="timeout">
                                    <i className="fas fa-stopwatch menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Time Out</span>
                                </a>

                            </li>

                            <li className="menu--item">

                                <a onClick={logout} href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="Logout">
                                    <i className="fas fa-sign-out-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Logout</span>
                                </a>

                            </li>






                        </ul>
                    </div>

                    {/* <div className="left_section pt-2">
                        <ul>
                            <li className="menu--item">

                                    <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "}  title="Profile">

                                        <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>
                                        <span className="menu--label">Profile</span>
                                    </a>

                            </li>
                            <li className="menu--item">
                                <a  href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="website">

                                    <i className="fas fa-globe menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Website</span>
                                </a>
                            </li>
                            <li className="menu--item">
                                <a  href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Logout">

                                    <i className="fas fa-sign-out-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Logout</span>
                                </a>
                            </li>

                        </ul>
                    </div> */}
                    <div className="left_footer">



                        <div className="left_footer_content" style={{ paddingTop: "20vh" }}>
                            {/* <img src={logo} width={"100px"} /> */}
                        </div>
                    </div>
                </div>
            </nav>



            <Modal className='text-center' isOpen={modal} toggle={toggle2} size="md" centered={true}>
                <ModalHeader >
                    <modalTitle> Password</modalTitle>
                    <i onClick={toggle2} style={{ fontSize: "24px", marginLeft: "450px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">Old Password*</label>
                                    <input type="password" value={OldPassword} onChange={(e) => handleInputChange(e, setOldPassword)} className="form-control" placeholder='Write Old Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">New Password*</label>
                                    <input type="password" value={NewPassword} onChange={(e) => handleInputChange(e, setNewPassword)} className="form-control" placeholder='Write New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">Confirm New Password*</label>
                                    <input type="password" value={ConfirmNewPassword} onChange={(e) => handleInputChange(e, setConfirmNewPassword)} className="form-control" placeholder='Write Confirm New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle2()} className="col-3 main-btn cancel">Close</button>
                            <button type="button" onClick={() => forgot()} className="col-3 main-btn" >Update</button>
                        </div>
                        {/* <button type="button" onClick={() => addCourses(Token)} className="login-btn btn-block">Add</button> */}
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}