import React, { useState, useEffect } from 'react';
import ManagerHeader from "../../Manager/layout/MangerHeader"
import ManagerFooter from "../../Manager/layout/ManagerFooter"
import { Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
// import ClearIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';

import {
    Typography,
    Grid, TextField,
    InputAdornment

} from '@mui/material';
import baseUrl from "../../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed'







export default function ManagerSchedule() {
    const [loader, setLoader] = useState(false);
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);
    const [id, setId] = useState("");
    const [check, setCheck] = useState(false);



    const current = new Date()
  const Time = current.toLocaleTimeString()
  const finaltime = Time.slice(0, -3)
  console.log("time ",  finaltime)




    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const isLight = theme.palette.mode === 'light';

    const [MondayShedules, setMondayShedules] = useState([{ starttime:finaltime, endtime:finaltime }])
    console.log("MondayShedules", MondayShedules)

    const InputChangeHandle = (e, index) => {
        const { name, value, files, type } = e.target;
        const list = [...MondayShedules];
        list[index][name] = e.target.value;
        setMondayShedules(list);
    }

    const handleAddClick = () => {
        setMondayShedules([...MondayShedules, {}]);
    }
    const handleRemoveClick = (index) => {
        MondayShedules.splice(index, 1);
        setMondayShedules([...MondayShedules]);
    }


    const [TuesdayShedules, setTuesdayShedules] = useState([{ starttime:finaltime, endtime:finaltime }])
    console.log("TuesdayShedules", TuesdayShedules)

    const InputChangeHandle1 = (e, index) => {
        const { name, value, files, type } = e.target;
        const list1 = [...TuesdayShedules];
        list1[index][name] = e.target.value;
        setTuesdayShedules(list1);
    }

    const handleAddClick1 = () => {
        setTuesdayShedules([...TuesdayShedules, {}]);
    }
    const handleRemoveClick1 = (index) => {
        TuesdayShedules.splice(index, 1)
        setTuesdayShedules([...TuesdayShedules])
    }
    // tuesday

    // wednesday
    const [WednesdayShedules, setWednesdayShedules] = useState([{ starttime:finaltime, endtime:finaltime }])
    // console.log("WednesdayShedules", WednesdayShedules)

    const InputChangeHandle2 = (e, index) => {
        const { name, value, files, type } = e.target;
        const list2 = [...WednesdayShedules];
        list2[index][name] = e.target.value;
        setWednesdayShedules(list2);
    }

    const handleAddClick2 = () => {
        setWednesdayShedules([...WednesdayShedules, {}]);
    }
    const handleRemoveClick2 = (index) => {
        WednesdayShedules.splice(index, 1)
        setWednesdayShedules([...WednesdayShedules])
    }
    // wednesday

    // thursday
    const [ThursdayShedules, setThursdayShedules] = useState([{ starttime:finaltime, endtime:finaltime}])
    // console.log("ThursdayShedules", ThursdayShedules)

    const InputChangeHandle3 = (e, index) => {
        const { name, value, files, type } = e.target;
        const list3 = [...ThursdayShedules];
        list3[index][name] = e.target.value;
        setThursdayShedules(list3);
    }

    const handleAddClick3 = () => {
        setThursdayShedules([...ThursdayShedules, {}]);
    }
    const handleRemoveClick3 = (index) => {
        ThursdayShedules.splice(index, 1)
        setThursdayShedules([...ThursdayShedules])
    }
    // thursday

    // friday
    const [FridayShedules, setFridayShedules] = useState([{ starttime:finaltime, endtime:finaltime }])
    //  console.log("FridayShedules", FridayShedules)

    const InputChangeHandle4 = (e, index) => {
        const { name, value, files, type } = e.target;
        const list4 = [...FridayShedules];
        list4[index][name] = e.target.value;
        setFridayShedules(list4);
    }

    const handleAddClick4 = () => {
        setFridayShedules([...FridayShedules, {}]);
    }
    const handleRemoveClick4 = (index) => {
        FridayShedules.splice(index, 1)
        setFridayShedules([...FridayShedules])
    }
    // friday


    // saturday
    const [SaturdayShedules, setSaturdayShedules] = useState([{ starttime:finaltime, endtime:finaltime }])
    // console.log("SaturdayShedules", SaturdayShedules)

    const InputChangeHandle5 = (e, index) => {
        const { name, value, files, type } = e.target;
        const list5 = [...SaturdayShedules];
        list5[index][name] = e.target.value;
        setSaturdayShedules(list5);
    }

    const handleAddClick5 = () => {
        setSaturdayShedules([...SaturdayShedules, {}]);
    }
    const handleRemoveClick5 = (index) => {
        SaturdayShedules.splice(index, 1)
        setSaturdayShedules([...SaturdayShedules])
    }
    // saturday

    // sunday
    const [SundayShedules, setSundayShedules] = useState([{ starttime:finaltime, endtime:finaltime }])
    console.log("SundayShedules", SundayShedules)

    const InputChangeHandle6 = (e, index) => {
        const { name, value, files, type } = e.target;
        const list6 = [...SundayShedules];
        list6[index][name] = e.target.value;
        setSundayShedules(list6);
    }

    const handleAddClick6 = () => {
        setSundayShedules([...SundayShedules, {}]);
    }
    const handleRemoveClick6 = (index) => {
        SundayShedules.splice(index, 1)
        setSundayShedules([...SundayShedules])
    }
    // sunday

    const Token = localStorage.getItem("ManagerToken")

    useEffect(() => {
        GetSchedules()


    }, []);

    // GET API
    const GetSchedules = async () => {
        setLoader(true)


        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/schedule?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status != "False" || result.status) {

                    console.log("get Scedule", result.data.length);
                    if (result.data.length > 0) {


                        result.data.map((e, i) => {

                            console.log("e", e, "i", i)

                            if (e.day === "sunday") {

                                setSundayShedules(e.time)

                            }
                            else if (e.day === "monday") {
                                setMondayShedules(e.time)
                            }
                            else if (e.day === "tuesday") {
                                setTuesdayShedules(e.time)
                            }
                            else if (e.day === "wednesday") {
                                setWednesdayShedules(e.time)
                            }
                            else if (e.day === "thursday") {
                                setThursdayShedules(e.time)
                            }
                            else if (e.day === "friday") {
                                setFridayShedules(e.time)
                            }
                            else if (e.day === "saturday") {
                                setSaturdayShedules(e.time)
                            }
                            return e
                        })



                    }

                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate('/ManagerLogin')
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });







    }



    const AddSchedule = (e,data) => {

        setLoader(true)
        if(e == "" || data== " "){

            setLoader(false)
            setError2(true)
            setMessage3("All keys are required ")
        }

        else{







        console.log("e in api ", e)
        const formData = new FormData();
        formData.append("day", e);
        formData.append("time", JSON.stringify(data));
        var requestOptions = {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + Token
            },
            body: formData,
        };
        // setLoader(true);
        fetch(`${baseUrl.baseUrl}webapi/schedule?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                console.log(result);
                if (result.status) {


                    setError(true)
                    setMessage(result.message)
                }
                else {

                    setError2(true)
                   setMessage3(result.message)
                }
            })
            .catch(error => {

                setLoader(false);
                setError2(true)
                   setMessage3(error)
            });
        }

    }







 
    return (
        <>
        <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1}  />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            <ManagerHeader />
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Schedule </h4>
                                    {/* <button type="button" className="main-btn float-right">Add Manager</button> */}
                                    <div className="la5lo1">
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>
                                                    Monday
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary' }} />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {MondayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            <Grid item xs={12} xl={4} md={4} >
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    label="Start Time"
                                                                    id="outlined-start-adornment"
                                                                    type="time"
                                                                    name="starttime"
                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle(e, i) }}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start" />
                                                                    }}
                                                                />


                                                            </Grid>
                                                            <Grid item xs={12}  xl={4} md={4} >


                                                                <TextField sx={{ m: 1, width: '33ch' }}
                                                                    // id="outlined-start-adornment"
                                                                    label="End Time"
                                                                    variant="outlined"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    name="endtime"
                                                                    onChange={(e) => { InputChangeHandle(e, i) }}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start" />
                                                                    }}


                                                                />








                                                            </Grid>

                                                            <Grid item xs={12} md={4} xl={4} >






                                                                {MondayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={handleAddClick} aria-label="add" sx={{ mx: 2 ,   backgroundColor:"white" ,color:"black"}}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {MondayShedules.length !== 0 &&
                                                                    <Fab  className='Fab' onClick={(e) => { handleRemoveClick(i) }}  aria-label="edit" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }






                                                            </Grid>


                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {MondayShedules.length === 0 &&
                                                    <Fab  className='Fab' onClick={handleAddClick}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"}}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Monday",MondayShedules)}  className='Fab'  variant="outlined"   sx={{    mr: 4, mt:2  , mt:2 , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2bh-content"
                                                id="panel2bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>Tuesday</Typography>
                                                <Typography sx={{ color: 'text.secondary' }} />


                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {TuesdayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            < Grid item xs={12}  xl={4} md={4} >
                                                                <TextField sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="Start Time"
                                                                    variant="outlined"
                                                                    type="time"
                                                                    name="starttime"

                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle1(e, i) }} />



                                                            </Grid>
                                                            < Grid item xs={12}  xl={4} md={4}>
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="End Time"
                                                                    variant="outlined"
                                                                    name="endtime"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    onChange={(e) => { InputChangeHandle1(e, i) }} />




                                                            </Grid>

                                                            < Grid item xs={12}  xl={4} md={4}>


                                                                {TuesdayShedules.length !== 0 &&
                                                                    <Fab className='Fab'  onClick={handleAddClick1}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {TuesdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={(e) => { handleRemoveClick1(i) }}  aria-label="edit"  sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }



                                                            </Grid>

                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {TuesdayShedules.length === 0 &&
                                                    <Fab className='Fab' onClick={handleAddClick1}  aria-label="add"  sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Tuesday",TuesdayShedules)}className='Fab'  variant="outlined"  sx={{    mr: 4, mt:2 , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3bh-content"
                                                id="panel3bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>
                                                    Wednesday
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary' }} />


                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {WednesdayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            < Grid item xs={12}  xl={4} md={4} >
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="Start Time"
                                                                    variant="outlined"
                                                                    type="time"
                                                                    name="starttime"
                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle2(e, i) }} />



                                                            </Grid>
                                                            < Grid item xs={12}  xl={4} md={4}>
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="End Time"
                                                                    variant="outlined"
                                                                    name="endtime"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    onChange={(e) => { InputChangeHandle2(e, i) }} />




                                                            </Grid>

                                                            < Grid item xs={12}  xl={4} md={4}>


                                                                {WednesdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={handleAddClick2}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {WednesdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={(e) => { handleRemoveClick2(i) }}  aria-label="edit" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }



                                                            </Grid>

                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {WednesdayShedules.length === 0 &&
                                                    <Fab className='Fab' onClick={handleAddClick2}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Wednesday",WednesdayShedules)}  className='Fab'  variant="outlined"  sx={{    mr: 4, mt:2    , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4bh-content"
                                                id="panel4bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>Thursday</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {ThursdayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            < Grid item xs={12}  xl={4} md={4} >
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="Start Time"
                                                                    variant="outlined"
                                                                    type="time"
                                                                    name="starttime"
                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle3(e, i) }} />



                                                            </Grid>
                                                            < Grid item xs={12}  xl={4} md={4}>
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="End Time"
                                                                    variant="outlined"
                                                                    name="endtime"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    onChange={(e) => { InputChangeHandle3(e, i) }} />




                                                            </Grid>

                                                            < Grid item xs={12}  xl={4} md={4}>


                                                                {ThursdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={handleAddClick3}  aria-label="add" sx={{ mx: 2 ,backgroundColor:"white" ,color:"black"  }}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {ThursdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={(e) => { handleRemoveClick3(i) }}  aria-label="edit" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }



                                                            </Grid>

                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {ThursdayShedules.length === 0 &&
                                                    <Fab className='Fab' onClick={handleAddClick3}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Thursday",ThursdayShedules)}  className='Fab'  variant="outlined"  sx={{    mr: 4, mt:2    , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel5bh-content"
                                                id="panel5bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>Friday</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {FridayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            < Grid item xs={12}  xl={4} md={4} >
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="Start Time"
                                                                    variant="outlined"
                                                                    type="time"
                                                                    name="starttime"
                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle4(e, i) }} />



                                                            </Grid>
                                                            < Grid item xs={12}  xl={4} md={4}>
                                                                <TextField sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic" label="End Time"
                                                                    variant="outlined"
                                                                    name="endtime"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    onChange={(e) => { InputChangeHandle4(e, i) }} />




                                                            </Grid>

                                                            < Grid item xs={12}  xl={4} md={4}>


                                                                {FridayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={handleAddClick4}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {FridayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={(e) => { handleRemoveClick4(i) }}  aria-label="edit" sx={{ mx: 2 ,backgroundColor:"white" ,color:"black" }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }



                                                            </Grid>

                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {FridayShedules.length === 0 &&
                                                    <Fab className='Fab' onClick={handleAddClick4}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Friday",FridayShedules)}  className='Fab'  variant="outlined"  sx={{    mr: 4, mt:2    , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>


                                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel6bh-content"
                                                id="panel6bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>saturday</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {SaturdayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            < Grid item xs={12}  xl={4} md={4} >
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="Start Time"
                                                                    variant="outlined"
                                                                    type="time"
                                                                    name="starttime"
                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle5(e, i) }} />



                                                            </Grid>
                                                            < Grid item xs={12}  xl={4} md={4}>
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="End Time"
                                                                    variant="outlined"
                                                                    name="endtime"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    onChange={(e) => { InputChangeHandle5(e, i) }} />




                                                            </Grid>

                                                            < Grid item xs={12}  xl={4} md={4}>


                                                                {SaturdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={handleAddClick5}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {SaturdayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={(e) => { handleRemoveClick5(i) }}  aria-label="edit" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }



                                                            </Grid>

                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {FridayShedules.length === 0 &&
                                                    <Fab className='Fab' onClick={handleAddClick5}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Saturday", SaturdayShedules)}  className='Fab'  variant="outlined"  sx={{    mr: 4, mt:2  , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>



                                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel7bh-content"
                                                id="panel7bh-header"
                                            >
                                                <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: "bolder" }}>Sunday</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {SundayShedules.map((e, i) => {
                                                    return (


                                                        <Grid container spacing={2}>

                                                            < Grid item xs={12}  xl={4} md={4} >
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="Start Time"
                                                                    variant="outlined"
                                                                    type="time"
                                                                    name="starttime"
                                                                    value={e.starttime}
                                                                    onChange={(e) => { InputChangeHandle6(e, i) }} />



                                                            </Grid>
                                                            < Grid item xs={12}  xl={4} md={4}>
                                                                <TextField
                                                                    sx={{ m: 1, width: '33ch' }}
                                                                    id="outlined-basic"
                                                                    label="End Time"
                                                                    variant="outlined"
                                                                    name="endtime"
                                                                    value={e.endtime}
                                                                    type="time"
                                                                    onChange={(e) => { InputChangeHandle6(e, i) }} />




                                                            </Grid>

                                                            < Grid item xs={12}  xl={4} md={4}>


                                                                {SundayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={handleAddClick6}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                                        <AddIcon />
                                                                    </Fab>
                                                                }

                                                                {SundayShedules.length !== 0 &&
                                                                    <Fab className='Fab' onClick={(e) => { handleRemoveClick6(i) }}  aria-label="edit" sx={{ mx: 2 , backgroundColor:"white" ,color:"black"  }}>
                                                                        <ClearIcon />
                                                                    </Fab>

                                                                }



                                                            </Grid>

                                                        </Grid>

                                                    )
                                                })

                                                }

                                                {SundayShedules.length === 0 &&
                                                    <Fab className='Fab' onClick={handleAddClick6}  aria-label="add" sx={{ mx: 2 , backgroundColor:"white" ,color:"black" }}>
                                                        <AddIcon />
                                                    </Fab>

                                                }
                                                <Button onClick={() => AddSchedule("Sunday",SundayShedules)}  className='Fab'  variant="outlined" sx={{    mr: 4, mt:2  , backgroundColor:"#03bafe" ,color:"white" }} endIcon={<SendIcon />}>
                                                    Save
                                                </Button>

                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <footer className="footer " style={{backgroundColor:"#03bafe ", color:"white" , marginTop:"35vh"}}>
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12" style={{padding:"15px"}}>

            <p style={{color:"white", fontSize:"medium"}}>© All right reserved by vallet services 2022</p>

                    </div>

                </div>
            </div>
        </footer>


        </>
    );
}
