import Booking from "../../../components/booking/Booking";


export default function AdminBooking() {
   
    return (
        <>
       

           
          <Booking role={"superadmin"}/>
            

           

        </>
    );
}
