import {  TIMEIN  } from './../action';



const TimeIn = (state = { timein: {} }, action) => {
    switch (action.type) {
        case TIMEIN:
            return { ...state, timein: action.payload };

           
       
        default:
            return { ...state };
    }
};

export default TimeIn;
