import React, { useState, useEffect } from 'react';
import AdminHeader from "../../Admin/layout/AdminHeader"
import AdminFooter from "../../Admin/layout/AdminFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from "react-overlay-loader"
import Avator from "../../Admin/auth/dummy-profile.png"



export default function SendNotification() {
    const [GetService, setGetService] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);


    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');


    return (
        <>
            {loader == true ? <Loader fullPage loading /> : null}

            <AdminHeader />
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    {/* <h4 className="item_title">Push Notification</h4> */}
                                    {/* <button type="button" className="main-btn float-right">Add Pricing</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className='col-md-12 text-center'>
                                                {/* <i class="fas fa-bell fa-8x " style={{color:"#03bafe"}}></i> */}

                                                <span class="fa-stack fa-5x has-badge" data-count="2">
                                                    {/* <i class="fa-stack-2x" ></i> */}
                                                    <i class="fa fa-bell fa-stack-1x fa-inverse" style={{ color: "#03bafe" }}></i>
                                                </span>

                                            </div>
                                            <div className="col-md-12 item text-center " style={{ marginTop: "50px" }}>
                                                <textarea id="w3review" name="w3review" rows="12" cols="80"></textarea>
                                                <h5>Send notification to all users</h5>

                                            </div>
                                            <div className='col-md-12 text-center mt-2'>
                                                <button type="button" class="btn btn-secondary btn-lg mx-4" onClick={() => setModal(true)}>Send Individual</button>
                                                <button type="button" class="btn  btn-lg" style={{ color: "white", backgroundColor: "#03bafe" }}>Send All Users</button>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <AdminFooter/>


            <Modal className='text-center' isOpen={modal}  size="md" >
                <ModalHeader >
                    <modalTitle> Send Message</modalTitle>
                <i onClick={toggle} style={{ fontSize: "24px", marginLeft:"350px" }} className=" "></i>
                </ModalHeader>

                <ModalBody className='text-left'>
                    <div className='row'>

                        <div className='col-md-12'>
                            <img src={Avator} alt="Avatar" class="avatar mx-2" />
                            <img src={Avator} alt="Avatar" class="avatar mx-2" />
                            <img src={Avator} alt="Avatar" class="avatar mx-2" />
                            <img src={Avator} alt="Avatar" class="avatar mx-2" />
                            <img src={Avator} alt="Avatar" class="avatar mx-2" />

                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="search">
                                <input type="text" class="searchTerm" placeholder="What are you looking for?" />
                                <button type="submit" class="searchButton">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-4'>

                        <div className='col-md-12'>

                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>

                        </div>
                        <div className='col-md-12 mt-2'>
                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>





                        </div>
                        <div className='col-md-12 mt-2'>
                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>


                        </div>
                        <div className='col-md-12 mt-2'>
                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>


                        </div>
                        <div className='col-md-12 mt-2'>
                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>


                        </div>
                        <div className='col-md-12 mt-2'>
                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>


                        </div>
                        <div className='col-md-12 mt-2'>
                            <span><img src={Avator} alt="Avatar" class="avatar" /><i></i> Desgination</span>


                        </div>

                    </div>
                    <div className="row text-center justify-content-end">
                        <button type="button" onClick={() => toggle()} className="col-2 main-btn cancel mx-3">Close</button>
                        <button type="button" className="col-2 main-btn" >Send Message</button>
                    </div>

                </ModalBody>

            </Modal>



        </>
    );
}
