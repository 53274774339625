export default {

  // baseUrl:"https://projectwallet.pythonanywhere.com/",
  // baseUrl:"http://54.160.133.112:8005/"
  // baseUrl:"http://192.168.137.109:8000/",
  // https://projectwallet.pythonanywhere.com/,

  // yeh safdar wala tha
  //  baseUrl: "https://betavalet.ml/"

  //  new added
  // baseUrl: "https://apivallaycarparking.newhnh.ml/"
  // baseUrl: "http://192.168.0.122:8000/"
  baseUrl: "https://api.hnhsofttechsolutions.com/"

  // baseUrl: "https://api.tradehubintl.com/"

}