import React, { useEffect, useInsertionEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Admin/auth/newlogo.png"
import { Loader } from "react-overlay-loader";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2";
import baseUrl from "../../../baseurl/BaseUrl";
import Avator from "../../Admin/auth/avator.jpeg"
import { ToastContainer, toast } from 'react-toastify';
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed'
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';
// const socket = io("https://sockets.laxairportvaletservices.com/")
const socket = io("https://sockets.hnhsofttechsolutions.com/")




export default function ManagerHeader() {
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;
    console.log("pathname", pathname)
    const [active, setActive] = useState("");


    //Javascript split method to get the name of the path in array

    const [Sidebar, setSidebar] = useState("");
    const [profileDrop, setprofileDrop] = useState("");
    const [profileData, setprofileData] = useState([]);
    const [loader, setLoader] = useState(false);
    // forgot
    const [OldPassword, setOldPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmNewPassword, setConfirmNewPassword] = useState("");
    const [modaltitle, setmodaltitle] = useState("");
    const [profileimg, setprofileimg] = useState(null);

    const [modal, setModal] = useState(false);
    const toggle2 = () => setModal(!modal);

    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);




    const navigate = useNavigate()
    const [notif, setNotif] = useState([]);
    const [read, setRead] = useState(0);


    console.log("profileData", profileData)

    useEffect(() => {
        setActive(pathname)
        GetNotifyall()
        if (localStorage.getItem("ManagerToken") == null) {
            navigate("/ManagerLogin")
        }
        else {
            getProfile()

        }




    }, []);



    const notify = (comment, route) => {
        toast(comment, {
            autoClose: 5000,

            style: { backgroundColor: "#6BE521", color: "black" },

        }

        );

        navigate("/NewReserve")
    }

    useEffect(() => {
        socket.on('admin_manager', (data) => {

            notify("New Reservation Arrived!", "/NewReserve")



        });

        // socket.on('admin_managerontheway', (data) => {

        //     notify("Rider is On the Way!", "/ManagerTracking")



        // });
        socket.on('admin_managerpicked', (data) => {

            notify("Vallet Picked the Customer !", "/ManagerTracking")



        });
        // socket.on('admin_managerdroped', (data) => {

        //     notify("Rider Drop the Order!", "/ManagerTracking")



        // });
        socket.on('admin_managerparked', (data) => {

            notify("Vallet Parked the car !", "/ManagerTracking")



        });
        // socket.on('admin_managerrelease', (data) => {

        //     notify("Rider is Release !", "/ManagerTracking")



        // });

        socket.on('admin_managercomplete', (data) => {

            notify("Vallet Complete the ride!", "/ManagerTracking")



        });
        return () => {
            socket.off('admin_manager');
            socket.off('admin_managerontheway');
            socket.off('admin_managerpicked');
            socket.off('admin_managerontheway');
            socket.off('admin_managerdroped');
            socket.off('admin_managerparked');
            socket.off('admin_managerrelease');
            socket.off('admin_managercomplete');


        }
            ;



    }, [])





    const sidebarToggle = (e) => {
        if (e == Sidebar) {
            setSidebar("")
        } else {
            setSidebar(e)
        }
    }



    const profiledropdown = (e) => {
        if (e == profileDrop) {
            setprofileDrop("")
        } else {
            setprofileDrop(e)
        }
    }






    const Token = localStorage.getItem("ManagerToken")

    const getProfile = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("get Manager profile", result);
                    setprofileData(result.data)
                    setprofileimg(result.data.profile)
                    document.getElementById("profileid").src = result.data.profile



                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate('/ManagerLogin')
                    } else {
                        Swal.fire({
                            title: "Oops",
                            text: result.message,
                            icon: "error",
                            confirmButtonColor: "#03bafe",
                        });
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });
    }

    const logout = () => {
        localStorage.removeItem("ManagerToken");
        localStorage.removeItem("ManagerData");
        navigate("/ManagerLogin");
    }


    const handleInputChange = (e, func) => {
        func(e.target.value);
    }


    // Forget password api

    const forgot = () => {

        if (NewPassword == "" || ConfirmNewPassword == "" || OldPassword == "") {

            setError2(true)
            setMessage3("All keys are required ")

        }
        else {



            if (NewPassword == ConfirmNewPassword) {
                toggle2();
                setLoader(true);
                const form = new FormData()
                form.append("oldpassword", OldPassword);
                form.append("password", NewPassword);
                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                fetch(`${baseUrl.baseUrl}webapi/changepassword?role=subadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        // console.log(result);
                        if (result.status) {


                            setOldPassword("");
                            setNewPassword("");
                            setConfirmNewPassword("");
                            setMessage(result.message)
                            setError1(false)
                        }
                        else {
                            setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            } else {
                setError2(true)
                setMessage3("Password & Confirm Password does not match ")
            }
        }
    }



    // GET NOTIFICATION 


    const GetNotifyall = () => {

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/adminNotifications?role=subadmin&action=all`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("get messsage", result.data);
                    setNotif(result.data)
                    setRead(result.nonread)






                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/ManagerLogin')
                    }
                    setError2(true)
                    setMessage3(result.message)


                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)

            });
    }


    const GetNotifyread = () => {
        if (read == 0) {
            return null
        }

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/adminNotifications?role=subadmin&action=read`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("get messsage", result.data);
                    // setNotif(result.data)
                    setRead(0)






                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/')
                    }
                    setError2(true)
                    setMessage3(result.message)


                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)

            });
    }


    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            <header className="header clearfix" style={{ backgroundColor: "#03bafe" }}>
                <button onClick={() => sidebarToggle("smallScreen")} type="button" id="toggleMenu" className="toggle_menu">
                    <i className='uil uil-bars'></i>
                </button>
                <button onClick={() => sidebarToggle("largeScreen")} className="collapse_menu">
                    <i className="uil uil-bars collapse_menu--icon "></i>
                    <span className="collapse_menu--label"></span>
                </button>
                <div className="main_logo" id="logo">
                    <Link to="#"><a href="#"><img src={logo} width={"55px"} alt="" /></a></Link>
                    {/* <Link to="#"><a href="#"><img className="logo-inverse" src={logo} width={"190px"} alt="" /></a></Link> */}
                </div>

                {/* <div className="top-category">
                    <div className="ui compact menu cate-dpdwn">
                        <div className="ui simple dropdown item">
                            <a href="#" className="option_links p-0" title="categories"><i className="uil uil-apps"></i></a>
                            <div className="menu dropdown_category5">
                                <a href="#" className="item channel_item">Development</a>
                                <a href="#" className="item channel_item">Business</a>
                                <a href="#" className="item channel_item">Finance & Accounting</a>
                                <a href="#" className="item channel_item">IT & Software</a>
                                <a href="#" className="item channel_item">Office Productivity</a>
                                <a href="#" className="item channel_item">Personal Development</a>
                                <a href="#" className="item channel_item">Design</a>
                                <a href="#" className="item channel_item">Marketing</a>
                                <a href="#" className="item channel_item">Lifestyle</a>
                                <a href="#" className="item channel_item">Photography</a>
                                <a href="#" className="item channel_item">Health & Fitness</a>
                                <a href="#" className="item channel_item">Music</a>
                                <a href="#" className="item channel_item">Teaching & Academics</a>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="search120">
                    <div className="ui search">
                        <div className="ui left icon input swdh10">
                            <input className="prompt srch10" type="text"
                                placeholder="Search for Tuts Videos, Tutors, Tests and more.." />
                            <i className='uil uil-search-alt icon icon1'></i>
                        </div>
                    </div>
                </div> */}
                <div className="header_right">

                    <ul>
                        {/* <li onClick={() => profiledropdown("messages")} className={profileDrop == "messages" ? "ui dropdown active visible" : "ui dropdown"}>
                            <a href="#" className="option_links" title="Messages"><i className='uil uil-envelope-alt' style={{ color: "white" }}></i><span
                                className="noti_count">3</span></a>
                            <div className={profileDrop == "messages" ? "menu dropdown_ms left transition visible d-block" : "menu dropdown_ms"}>
                                <a href="#" className="channel_my item">
                                    <div className="profile_link">
                                        <img src="/assets/images/person_1.jpg" alt="" />
                                        <div className="pd_content">
                                            <h6>Zoena Singh</h6>
                                            <p>Hi! Sir, How are you. I ask you one thing please explain it this video price.</p>
                                            <span className="nm_time">2 min ago</span>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="channel_my item">
                                    <div className="profile_link">
                                        <img src="/assets/images/person_1.jpg" alt="" />
                                        <div className="pd_content">
                                            <h6>Joy Dua</h6>
                                            <p>Hello, I paid you video tutorial but did not play error 404.</p>
                                            <span className="nm_time">10 min ago</span>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="channel_my item">
                                    <div className="profile_link">
                                        <img src="/assets/images/person_1.jpg" alt="" />
                                        <div className="pd_content">
                                            <h6>Jass</h6>
                                            <p>Thanks Sir, Such a nice video.</p>
                                            <span className="nm_time">25 min ago</span>
                                        </div>
                                    </div>
                                </a>
                                <a className="vbm_btn" href="instructor_messages.html">View All <i
                                    className='uil uil-arrow-right'></i></a>
                            </div>
                        </li> */}
                        <li onClick={() => profiledropdown("notifications")} className={profileDrop == "notifications" ? "ui dropdown active visible" : "ui dropdown"} >
                            <a href="#" className="option_links" title="Notifications"  ><i onClick={() => GetNotifyread()} className='uil uil-bell' style={{ color: "white" }}></i><span
                                className="noti_count">{read}</span></a>
                            <div className={profileDrop == "notifications" ? "menu dropdown_mn left transition visible d-block" : "menu dropdown_mn"} style={{ height: "350px", overflow: "scroll" }}>
                                {notif.map((v, i) => {

                                    return <a href="#" className="channel_my item">
                                        <div className="profile_link">
                                            <img src={v.img} alt="" />
                                            <div className="pd_content">
                                                <h6>{v.fname + " " + v.lname}</h6>
                                                <p>{v.message}</p>
                                                {/* <span className="nm_time">2 min ago</span> */}
                                            </div>
                                        </div>
                                    </a>


                                })}

                                {/*                               
                                <a className="vbm_btn" href="instructor_notifications.html">View All <i
                                    className='uil uil-arrow-right'></i></a> */}
                            </div>
                        </li>
                        <li className="ui dropdown">
                            <a href="#" className="option_links"><i className="fas fa-user-friends" style={{ color: "white", fontSize: "18px" }}></i></a></li>





                        <li onClick={() => profiledropdown("profile")} className={profileDrop == "profile" ? "ui dropdown active visible" : "ui dropdown"}>
                            <a href="#" className="opts_account" title="Account Information">


                            </a>
                            <img className="rounded-circle" id="profileid" src={Avator} alt="" width="40px" height="40px" />
                            <div className={profileDrop == "profile" ? "menu dropdown_account left transition visible d-block" : "menu dropdown_account"}>
                                <div className="channel_my">
                                    <div className="profile_link">
                                        <img src={profileimg == null ? Avator : profileimg} alt="" />

                                        <div className="pd_content">
                                            <div className="rhte85">
                                                <h6 className="text-capitalize">{profileData.fname + " " + profileData.lname}</h6>
                                                <div className="mef78" title="Verify">
                                                    <i className='uil uil-check-circle'></i>
                                                </div>
                                            </div>
                                            <span>
                                                <a href="#"
                                                    className="__cf_email__  text-capitalize"
                                                ></a>
                                            </span>
                                        </div>
                                    </div>
                                    <a href="#" className="item channel_item " >View Profile</a>
                                </div>

                                <a href="#" onClick={() => toggle2()} className="item channel_item">Change Password</a>

                                <a onClick={logout} href="#" className="item channel_item">Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </header>


            <nav style={{ backgroundColor: "#03bafe" }} className={Sidebar == "largeScreen" ? "vertical_nav vertical_nav__minify" : (Sidebar == "smallScreen" ? "vertical_nav vertical_nav__opened" : "vertical_nav")}>
                <div className="left_section menu_left" id="js-menu">
                    <div className="sidebar-user">

                        <div className='row ' style={{ backgroundColor: "white" }} >
                            <div className='col safdar' >
                                <img className="rounded-circle" src={profileimg == null ? Avator : profileimg} width={"60px"} />

                            </div>
                            <div className='col ahmed' style={{ alignItems: "center" }}>
                                <h6 className="text-capitalize">{profileData.fname}</h6>
                                <p>Manager</p>
                            </div>


                        </div>
                        <ul>

                            <li className="menu--item">

                                <Link to="/Managerdashboard">   <a href="#" className={active == "/Managerdashboard" ? "menu--link  navActive" : "menu--link "} title="Dashboard">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fa fa-desktop menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Dashboard</span>
                                </a></Link>

                            </li>

                            <li onClick={() => profiledropdown("dropdown")} className={profileDrop == "dropdown" ? " menu--item menu--item__has_sub_menu menu--subitens__opened" : "menu--item menu--item__has_sub_menu"}>
                                <i className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="Reservations">
                                    <i className="uil uil-clipboard-alt menu--icon" />
                                    <span className="menu--label">Reservations</span>
                                </i>
                                <ul className="sub_menu">


                                    <li className="sub_menu--item">
                                        <Link to="/NewReserve">  <a href="#" className="sub_menu--link">
                                            New reservation
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/TodayReserve">  <a href="#" className="sub_menu--link">
                                            Todays Return
                                        </a></Link>
                                    </li>



                                    <li className="sub_menu--item">
                                        <Link to="/UpcomingReserve">  <a href="#" className="sub_menu--link">
                                            upcoming return
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/ActiveReserve">   <a href="#" className="sub_menu--link">
                                            Active Reservation
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/RepeatReserve">   <a href="#" className="sub_menu--link">
                                            Repeat Reservation
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/ManagerPickup">   <a href="#" className="sub_menu--link">
                                            Picked
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/ManagerParked">   <a href="#" className="sub_menu--link">
                                            Parked
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/ManagerComplete">   <a href="#" className="sub_menu--link">
                                            Completed
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/ReturnReserve">   <a href="#" className="sub_menu--link">
                                            Returns
                                        </a></Link>

                                    </li>


                                </ul>
                            </li>

                            <li className="menu--item">

                                <Link to="/UserManager">   <a href="#" className={active == "/UserManager" ? "menu--link  navActive" : "menu--link "} title="Customers">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="	fas fa-user-friends menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Customers</span>
                                </a></Link>

                            </li>
                            <li className="menu--item">
                                <Link to="/ManagerCarwash">   <a href="#" className={active == "/ManagerCarwash" ? "menu--link  navActive" : "menu--link "} title="Car Wash">
                                    <i className="fas fa-car menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Other Services </span>
                                </a></Link>
                            </li>

                            <li className="menu--item">
                                <Link to="/ManagerBooking">   <a href="#" className={active == "/ManagerBooking" ? "menu--link  navActive" : "menu--link "} title="ManagerBooking">
                                    <i className="fa fa-book menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Booking Form</span>
                                </a></Link>
                            </li>

                            <li onClick={() => profiledropdown("inventory")} className={profileDrop == "inventory" ? " menu--item menu--item__has_sub_menu menu--subitens__opened" : "menu--item menu--item__has_sub_menu"}>
                                <i className={active == "" ? "menu--link  navActive" : "menu--link "} title="Reservations">
                                    <i className="uil uil-clipboard-alt menu--icon" />
                                    <span className="menu--label">Inventory Section</span>
                                </i>
                                <ul className="sub_menu">
                                    <li className="sub_menu--item">
                                        <Link to="/ManagerCategory">  <a href="#" className="sub_menu--link">
                                        Category
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/ManagerServices">  <a href="#" className="sub_menu--link">
                                          Services
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/ManagerPromo">  <a href="#" className="sub_menu--link">
                                          Promo Code
                                        </a></Link>
                                    </li>
                                 

                                </ul>
                            </li>

                            <li className="menu--item">
                                <Link to="/EmployeeManager">   <a href="#" className={active == "/EmployeeManager" ? "menu--link  navActive" : "menu--link "} title="Employee">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Employee Management</span>
                                </a></Link>
                            </li>

                            <li className="menu--item">
                                <Link to="/ManagerProfile">  <a href="#" className={active == "/ManagerProfile" ? "menu--link  navActive" : "menu--link "} title="Teacher">
                                    <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className="menu--label">Setup Profile</span>
                                </a></Link>
                            </li>
                            
                            <li className="menu--item">
                                <Link to="/ManagerRider">   <a href="#" className={active == "/ManagerRider" ? "menu--link  navActive" : "menu--link "} title="Rider">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fa fa-motorcycle menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">User Accounts</span>
                                </a></Link>
                            </li>

                            
                            <li className="menu--item">
                                <Link to="/ManagerPayment">   <a href="#" className={active == "/ManagerPayment" ? "menu--link  navActive" : "menu--link "} title="Payment">
                                    <i className="fas fa-money-bill-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Payment</span>
                                </a></Link>
                            </li>


                            {/* <li className="menu--item">
                                <Link to="/ManagerCategory">   <a href="#" className={active == "/ManagerCategory" ? "menu--link  navActive" : "menu--link "} title="Category">
                                    <i className="uil uil-heart-alt menu--icon"></i>
                                    <i className="fa fa-tasks menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Category</span>
                                </a></Link>
                            </li> */}

                            {/* <li className="menu--item">
                                <Link to="/ManagerServices">   <a href="#" className={active == "/ManagerServices" ? "menu--link  navActive" : "menu--link "} title="Dashboard">
                                    <i className="fa fa-wrench menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Services</span>
                                </a></Link>
                            </li> */}

                            <li className="menu--item">

                                <Link to="/ManagerCardDetails">   <a href="#" className={active == "/ManagerCardDetails" ? "menu--link  navActive" : "menu--link "} title="CardDetails">
                                    {/* <i className='uil uil-kayak menu--icon'></i> */}
                                    <i className=" fa fa-credit-card  menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Card Details</span>
                                </a></Link>

                            </li>
                            {/* <li className="menu--item">
                                <Link to="/ManagerPromo">   <a href="#" className={active == "/ManagerPromo" ? "menu--link  navActive" : "menu--link "} title="Promo Code">
                                    <i className='uil uil-kayak menu--icon'></i>
                                    <i className=" fa fa-gift  menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Promo Code</span>
                                </a></Link>
                            </li> */}

                            {/* <li className="menu--item">

                                <Link to="/Pending">   <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Category">
                              
                                    <i className="fas fa-hourglass menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Pending Reservation</span>
                                </a></Link>

                            </li> */}

                            {/* <li className="menu--item">

                                <Link to="/AcceptReserve">   <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Category">
                                 
                                    <i className="far fa-check-circle menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Accept Reservation</span>
                                </a></Link>

                            </li> */}


                            <li className="menu--item">

                                <Link to="/ManagerTracking">   <a href="#" className={active == "/ManagerTracking" ? "menu--link  navActive" : "menu--link "} title="Category">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fa fa-map-marker menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Tracking</span>
                                </a></Link>

                            </li>
                            {/* <li className="menu--item">

                                <Link to="/ManagerSchedule">   <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="ManagerSchedule">
                                    <i className="uil uil-heart-alt menu--icon"></i>
                                    <i className="fa fa-calendar menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Schedule</span>
                                </a></Link>

                            </li> */}

                            {/* <li className="menu--item">

                                <Link to="/ManagerPickup">   <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="ManagerPickup">
                                   
                                    <i className="fas fa-car-side menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Pickup</span>
                                </a></Link>

                            </li> */}

                            {/* <li className="menu--item">

                                <Link to="/ManagerComplete">   <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="ManagerComplete">
                            
                                    <i className="fa fa-check-square menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Complete</span>
                                </a></Link>

                            </li> */}

                            <li className="menu--item">

                                <a onClick={logout} href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="Teacher">
                                    <i className="fas fa-sign-out-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Logout</span>
                                </a>

                            </li>


                        </ul>
                    </div>

                    {/* <div className="left_section pt-2">
                        <ul>
                            <li className="menu--item">

                                    <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "}  title="Profile">

                                        <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>
                                        <span className="menu--label">Profile</span>
                                    </a>

                            </li>
                            <li className="menu--item">
                                <a  href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="website">

                                    <i className="fas fa-globe menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Website</span>
                                </a>
                            </li>
                            <li className="menu--item">
                                <a  href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Logout">

                                    <i className="fas fa-sign-out-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Logout</span>
                                </a>
                            </li>

                        </ul>
                    </div> */}
                    <div className="left_footer">
                        {/* <div className="left_footer_content" style={{paddingTop:"20vh"}}>
                           <img src={logo} width={"100px"}/>
                        </div> */}
                    </div>
                </div>
            </nav>



            {/* <Modal className='text-center' isOpen={modal} toggle={toggle2} size="md" centered={true}> */}
            <Modal className='text-center' isOpen={modal} size="md" >
                {/* <ModalHeader toggle={toggle2}  >Change Password</ModalHeader> */}
                <ModalHeader >
                    <modalTitle> Change Password</modalTitle>
                    <i onClick={toggle2} style={{ fontSize: "24px", marginLeft: "310px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">Old Password*</label>
                                    <input type="password" value={OldPassword} onChange={(e) => handleInputChange(e, setOldPassword)} className="form-control" placeholder='Write Old Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">New Password*</label>
                                    <input type="password" value={NewPassword} onChange={(e) => handleInputChange(e, setNewPassword)} className="form-control" placeholder='Write New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">Confirm New Password*</label>
                                    <input type="password" value={ConfirmNewPassword} onChange={(e) => handleInputChange(e, setConfirmNewPassword)} className="form-control" placeholder='Write Confirm New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle2()} className="col-3 main-btn cancel">Close</button>
                            <button type="button" onClick={() => forgot()} className="col-3 main-btn" >Update</button>
                        </div>
                        {/* <button type="button" onClick={() => addCourses(Token)} className="login-btn btn-block">Add</button> */}
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}