import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader"
import logo from "../../Admin/auth/newlogo.png"
import { useNavigate,useLocation } from "react-router-dom";
import baseUrl from "../../../baseurl/BaseUrl";

 export default function ManagNewPassword() {
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmNewPassword, setConfirmNewPassword] = useState('');
  const [loader, setLoader] = useState(false);

    const location = useLocation();
    const navigate = useNavigate()

    console.log("location=======>",location.state.userId)




    const handleInputChange = (event, func) => {
      func(event.target.value);

    }

    // const Token = localStorage.getItem("AdminToken")

    const Confirmation = () => {

        //   console.log("window.location.origin",window.location.origin)
        if (NewPassword == "") {
            Swal.fire({
                title: "Oops",
                text: "NewPassword is required",
                icon: "error",
                confirmButtonColor: "#03bafe",
            });
        } else if (NewPassword != ConfirmNewPassword) {
            Swal.fire({
                title: "Oops",
                text: "Password does not match",
                icon: "error",
                confirmButtonColor: "#03bafe",
            });
        } else {
            setLoader(true);
            var formdata = new FormData();
            formdata.append("password", NewPassword);
            formdata.append("adminid", location.state.userId);

            var requestOptions = {
                method: 'POST',
                body: formdata,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/forgetConfirmation?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result)
                    if (result.status) {
                        navigate("/ManagerLogin");
                        Swal.fire({
                            title: "success",
                            text: result.message,
                            icon: "success",
                            confirmButtonColor: "#03bafe",
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Oops",
                            text: result.message,
                            icon: "error",
                            confirmButtonColor: "#03bafe",
                        });
                    }
                })
                .catch(error => {
                    setLoader(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                });
        }
    }

    return (<>
        {/* {loader == true ? <Loader fullPage loading /> : null} */}
        <div className="sign_in_up_bg">
            <div className="container">
                <div className="row justify-content-lg-center justify-content-md-center">

                    <div className="col-lg-6 col-md-8">
                        <div className="sign_form">

              <img src={logo} width={"80px"} height={"80px"} alt=""></img>

                            <form>
                                <div className="ui search focus mt-50">

                                    <label className="label25 text-left">New Password<span className="text-danger">*</span></label>
                                    <div className="ui left icon input swdh95 mb-2">
                                        <input value={NewPassword} onChange={(e) => handleInputChange(e, setNewPassword)} className="prompt srch_explore" type="password" name="password"  id="id_password" required maxLength={64} placeholder="New Password" />
                                        {/* <i className="uil uil-key-skeleton-alt icon icon2" /> */}
                                    </div>
                                    <label className="label25 text-left">Confirm New Password<span className="text-danger">*</span></label>
                                    <div className="ui left icon input swdh95 mb-2">
                                        <input value={ConfirmNewPassword} onChange={(e) => handleInputChange(e, setConfirmNewPassword)} className="prompt srch_explore" type="password" name="password"  id="id_password" required maxLength={64} placeholder="Confirm New Password" />
                                        {/* <i className="uil uil-key-skeleton-alt icon icon2" /> */}
                                    </div>

                                </div>
                                <button onClick={()=>Confirmation()} className="login-btn" type="button">Confirm</button>
                            </form>
                            {/* <Link href={"/superAdmin/auth/login"} passHref><p className="mb-0 mt-30 sgntrm145">Go Back <a href="#">Sign In</a></p></Link> */}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </>

    )
}
