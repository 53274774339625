import React,{useState,useEffect} from 'react';
import UserData from '../../../components/userdata/UserData';





export default function UserManager() {
  
 



    return (
        <>
      

           
          <UserData role={"subadmin"}/>
           

         
           

        </>
    );
}
