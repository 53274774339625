import React, { useState, useEffect } from 'react';
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import RiderHeader from "../../pages/Rider/layout/RiderHeader"
import RiderFooter from "../../pages/Rider/layout/RiderFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import "./complete.css"
import { Player } from 'video-react';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';
import TimeConverter from '../timeconverter/TimeConverter';


export default function Reservationstab(props) {
    console.log("superadmin role? complete", props.role, props.status)
    const Role = props.role
    const Status = props.status

    const [GetReservations, setGetReservations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [key, setKey] = useState('Pickup Images');

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);

    // Edit modal 
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);
    const [keyspot, setKeyspot] = useState('');


    // Edit


    const [id, setId] = useState("");
    const [Reservationdet, setReservationdet] = useState([]);
    const [videos, setVideos] = useState([]);
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);
    const [riders, setRiders] = useState([]);
    const [reservationid, setReservationid] = useState("");
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");







    console.log("videos=>", videos)


    useEffect(() => {
        GetServices()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")
    const RiderToken = localStorage.getItem("RiderToken")





    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=${Status}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else if (Role == "subadmin") {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=${Status}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + RiderToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=rider&status=${Status}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }

    }



    const ReservationDetails = (id) => {
        if (Role == "superadmin") {
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=superadmin&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations complete", result.data);
                        setReservationdet(result.data.carimages);
                        setVideos(result.data.carvideos)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else if (Role == "subadmin") {

            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=subadmin&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setReservationdet(result.data.carimages);
                        setVideos(result.data.carvideos)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

        else {


            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + RiderToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=rider&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setReservationdet(result.data.carimages);
                        setVideos(result.data.carvideos)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }


    }




    const GetRiders = async (e) => {
        console.log("e", e)


        if (Role == "superadmin") {
            setLoader(true)
            toggle1()
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/attendence?role=superadmin&action=today`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)
                    if (result.status && result.status != "False") {
                        setRiders(result.data);
                        // setRiderid(result.data.id)

                    }

                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/')
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {

            setLoader(true)

            toggle1()

            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/attendence?role=subadmin&action=today`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)

                    if (result.status && result.status != "False") {
                        setRiders(result.data);
                        // setRiderid(result.data.id)
                        console.log("get Rider", result.data);
                    }

                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/ManagerLogin')
                        } else {
                            setError2(true)
                            setMessage3(result.message);
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);

                    setError2(true)
                    setMessage3(error)
                });
        }

    }



    // Edit api
    const EditReservation = (a) => {

        if (a) {


            if (Role == "superadmin") {
                setLoader(true)

                const form = new FormData()
                form.append("reservationid", reservationid);
                form.append("riderid", id);
                form.append("action", "drop");


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/assignRider?role=superadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        setError1(false)
                        console.log(result);
                        if (result.status) {
                            GetServices()
                            setModal1(false)
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate('/')
                            } else {
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {

                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });

            }
            else {

                setLoader(true)

                const form = new FormData()
                form.append("reservationid", reservationid);
                form.append("riderid", id);
                form.append("action", "drop");


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/assignRider?role=subadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        setError1(false)
                        console.log(result);
                        if (result.status) {
                            GetServices()
                            setModal1(false)
                            setError(true)
                            setMessage(result.message)

                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate('/ManagerLogin')
                            } else {
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {

                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }
        }


    }



    const Reserve = (id) => {
        console.log("rider id", id)


        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to assign to this rider ?")

            .then((result) => {
                EditReservation(result.isConfirmed)

            })
    }

    const CopyLink = (id) => {

        //    console.log("id====>",id)

        const livelink = `${"https://valet-userside.vercel.app" + `/CustomerPayment?reservationid=${id}`}`
        navigator.clipboard.writeText(livelink);
        //    console.log("live link",livelink)
    }


    // EDIT KEYSPOT 

    const EditKeyspot = () => {




        if (Role == "superadmin") {
            setLoader(true)

            const form = new FormData()
            form.append("keyspot", keyspot);
            form.append("id", id);



            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/keyspotupdate?role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {


                    if (result.status) {
                        toggle2()
                        GetServices()
                        setError(true)
                        setMessage(result.message)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/')
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {

            setLoader(true)

            const form = new FormData()
            form.append("keyspot", keyspot);
            form.append("id", id);


            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/keyspotupdate?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {


                    if (result.status) {
                        toggle2()
                        GetServices()
                        setError(true)
                        setMessage(result.message)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/ManagerLogin')
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }



    }


    // FILTER DATA 

    const GetFilterdata = () => {

        if (Role == "superadmin") {

            if (startdate == "" || enddate == " ") {
                setError2(true)
                setMessage3("Please select startdate and enddate")
                setLoader(false)

            }

            else {
                setLoader(true);
                var requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + Token
                    }
                };
                fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=returnfilter&startdate=${enddate}&enddate=${startdate}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        if (result.status && result.status != "False") {
                            console.log("get admin services ", result.data);
                            setGetReservations(result.data);
                            setStartdate("")
                            setEnddate("")
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            } else {

                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });

            }
        }
        else {
            if (startdate == "" || enddate == " ") {
                setError2(true)
                setMessage3("Please select startdate and enddate")
                setLoader(false)

            }
            else {
                setLoader(true);
                var requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    }
                };

                fetch(`${baseUrl.baseUrl}webapi/additionalsheet?role=subadmin&startdate=${enddate}&enddate=${startdate}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        if (result.status) {
                            console.log("get manager services", result.data);
                            setGetReservations(result.data);
                            setStartdate("")
                            setEnddate("")
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            } else {

                                setModal(true)
                                setError2(true)
                                setMessage3(result.message)

                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });

            }

        }
    }



    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={EditReservation} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ?
                <AdminHeader /> :
                Role == "subadmin" ?
                    <ManagerHeader /> :
                    Role == "rider" ?
                        <RiderHeader /> : ""
            }
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Reservations</h4>

                                    {Status == "complete" ?
                                        <>
                                            <button type="button" onClick={() => GetServices()} className="main-btn float-right">Reset</button>
                                            <button type="button" onClick={() => GetFilterdata()} className="main-btn float-right">Search</button>
                                            <input type="date" value={startdate} onChange={(e) => setStartdate(e.target.value)} className="main-btn float-right" />
                                            <input type="date" value={enddate} onChange={(e) => setEnddate(e.target.value)} className="main-btn float-right" />
                                        </>

                                        : ""}
                                    {/* <button type="button" onClick={() => GetServices()} className="main-btn float-right">Reset</button>
                                    <button type="button" onClick={() => GetServices()} className="main-btn float-right">Search</button>
                                    <input type="date" value={startdate} onChange={(e) => setStartdate(e.target.value)} className="main-btn float-right" />
                                    <input type="date" value={enddate} onChange={(e) => setEnddate(e.target.value)} className="main-btn float-right" /> */}



                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Customer ', field: 'name', filtering: false },
                                                        { title: 'Service ', field: 'servicename', filtering: false },
                                                        { title: 'Reservation No ', field: 'slipno', filtering: false },
                                                        { title: 'Departure_Date', field: 'departure_airline' },
                                                        { title: 'Return_Date', field: 'return_airline' },
                                                        { title: 'Start', field: 'departure_airline_time', filtering: false },
                                                        { title: 'End', field: 'return_airline_time', filtering: false },

                                                        { title: 'Pick ', field: 'pickridername', render: rowData => rowData.pickridername == null ? "-" : rowData.pickridername, filtering: false },
                                                        { title: 'Drop ', field: 'dropridername', render: rowData => rowData.dropridername == null ? "-" : rowData.dropridername, filtering: false },
                                                        { title: 'Keyspot ', field: 'keySpot', render: rowData => rowData.keySpot == "" ? "-" : rowData.keySpot, filtering: false },
                                                        { title: ' Plate No ', field: 'licensePlate', filtering: false },
                                                        { title: 'FlightNumber ', field: 'flighNumber', filtering: false },

                                                        { title: '  Make ', field: 'vehicleMake', filtering: false },
                                                        { title: '  Color ', field: 'vehicleColor', filtering: false },

                                                        { title: 'Contact', field: 'phone', filtering: false },

                                                        { title: 'Total ', field: 'totalamout', render: rowData => "$" + rowData.totalamout.toFixed(2), filtering: false },
                                                        { title: 'Status', field: 'status', filtering: false },

                                                        {
                                                            title: 'Vallet Assign', render: (rowData) =>

                                                                <>
                                                                    {rowData.riderdrop == null ?

                                                                        <Button type='button' onClick={() => { GetRiders(rowData); setReservationid(rowData.id) }} style={{ backgroundColor: "#03bafe", color: "white" }}  >Assign </Button> :

                                                                        rowData.rider != null ?
                                                                            <button type='button' style={{ backgroundColor: "green", color: "white", width: "85px", height: "42px", borderRadius: "7px" }}  > Already Assign</button> :


                                                                            ""
                                                                    }


                                                                </>
                                                        }

                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        rowData => ({
                                                            hidden: rowData.status != "complete",
                                                            icon: RemoveRedEyeIcon,
                                                            tooltip: 'Pending Icon',
                                                            onClick: (event, rowData) => {
                                                                toggle()

                                                                ReservationDetails(rowData.id)

                                                            }
                                                        }),


                                                        rowData => ({

                                                            icon: Edit,
                                                            tooltip: 'Edit Icon',
                                                            onClick: (event, rowData) => {
                                                                setKeyspot(rowData.keySpot)
                                                                setId(rowData.id)
                                                                console.log("edit icon", rowData.keySpot)
                                                                toggle2()







                                                            }
                                                        })



                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize: 10,
                                                        filtering: true

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ?
                <AdminFooter /> :
                Role == "subadmin" ?

                    <ManagerFooter /> :
                    Role == "rider" ?
                        <RiderFooter /> : ""
            }



            {/* //Add MOdal  */}
            <Modal className='text-center' isOpen={modal} size="lg" >
                <ModalHeader >
                    <modalTitle>  Car Complete Details</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Pickup Images" title="Pickup Images">
                            <section className="gallery">

                                {Reservationdet.map((v, i) => {
                                    if (v.imgtype == "picked") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="galler" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Complete Images" title="Complete Images">
                            <section className="gallery">

                                {Reservationdet.map((v, i) => {
                                    if (v.imgtype == "complete") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="gallery" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Pickup Videos" title="Pickup Videos" >
                            {videos.map((v, i) => {
                                if (v.imgtype == "picked") {
                                    return <><h3>Car video at the time of pickup</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }


                            })}

                        </Tab>
                        <Tab eventKey="Complete Videos" title="Complete Videos" >
                            {videos.map((v, i) => {
                                if (v.imgtype == "complete") {
                                    return <><h3>Car video at the time of complete</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }



                            })}


                        </Tab>
                    </Tabs>

                </ModalBody>
                <ModalFooter>

                    <Button variant="primary" onClick={() => toggle()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>



            {/* rider MOdal   */}




            <Modal className='text-center' isOpen={modal1} size="lg" >
                <ModalHeader >
                    <modalTitle> Vallet Assign</modalTitle>
                    {/* <i onClick={toggle1} style={{ fontSize: "24px", marginLeft:"250px" }} className="fa fa-window-close "></i> */}
                </ModalHeader>
                <ModalBody className='text-left'>
                    <div className="la5lo1">
                        <div className="row">
                            <div className="col-md-12 item">
                                <MaterialTable
                                    title=""
                                    icons={{
                                        Check: Check,
                                        DetailPanel: ChevronRight,
                                        // Delete: DeleteOutline,
                                        Export: SaveAlt,
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        Search: Search,
                                        ThirdStateCheck: Remove,
                                        Add: Add,
                                        SortArrow: ArrowDownward,
                                        Clear: Clear,
                                        Edit: Edit,
                                        ViewColumn: ViewColumn,
                                        ResetSearch: Clear
                                    }}

                                    columns={[
                                        { title: 'First Name', field: 'firstname' },
                                        { title: 'Last Name', field: 'lastname' },
                                        { title: 'Email ', field: 'email' },
                                        { title: 'Time In ', render: rowData => TimeConverter(rowData.timein.split("T")[1]) },
                                        // { title: 'Time Out ',  render:rowData=>TimeConverter(rowData.timeout.split("T")[1])  },

                                        { title: 'Address', field: 'address' },
                                        { title: 'Contact', field: 'contact' },
                                        {
                                            title: 'Vallet Assign', render: (rowData) =>
                                                <>
                                                    {console.log("rowdata", rowData)}
                                                    <Button type='button' onClick={() => { setId(rowData.rider); Reserve() }} style={{ backgroundColor: "#03bafe", color: "white" }}  >Done</Button>
                                                </>
                                        },






                                    ]}
                                    data={riders}
                                    actions={[
                                        // {
                                        //     icon: RemoveRedEyeIcon,
                                        //     tooltip: 'view',
                                        //     onClick: (event, rowData) => {
                                        //         setModal1(false)
                                        //         toggle()
                                        //         console.log("rowdata=> 1", rowData.riderschedule)
                                        //         setRiderschedule(rowData.riderschedule)

                                        //     }
                                        // },

                                    ]}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#03bafe',
                                            color: '#FFF'
                                        },
                                        actionsColumnIndex: -1,
                                        // exportButton: true
                                        pageSize: 10,

                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>

                    <button type="button" onClick={() => toggle1()} className="col-2 main-btn ">Close</button>
                    {/* <button type="button"  className="col-2 main-btn" >Add</button> */}
                    {/* </div> */}

                </ModalFooter>

            </Modal>





            {/* Edit keyspot  */}
            <Modal className='text-center' isOpen={modal2} size="md" >
                <ModalHeader >
                    <modalTitle>  Edit Keyspot</modalTitle>
                    <i onClick={toggle2} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <form>
                        <div className="form-group">
                            <label className="label25 text-left">Keyspot*</label>
                            <input value={keyspot} onChange={(e) => setKeyspot(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Keyspot Name" />
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>


                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle2()} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => EditKeyspot()} className="col-2 main-btn" >Update</button>
                        </div>
                    </form>


                </ModalBody>

            </Modal>




        </>
    );
}

