import React, { useState, useEffect } from 'react';
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import ManagerHeader from "../../pages/Manager/layout/MangerHeader"
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneIcon from '@mui/icons-material/Done';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimeConverter from '../timeconverter/TimeConverter';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';
// import TimeConverter from '../timeconverter/TimeConverter';





export default function AcceptReservations(props) {
    const [GetReservations, setGetReservations] = useState([]);
    console.log("GetReservations==>", GetReservations)
    console.log("superadmin role?", props.role)
    const Role = props.role
    const [loader, setLoader] = useState(false);



    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    // Edit

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [reservationid, setReservationid] = useState("");
    const [riderschedule, setRiderschedule] = useState([]);

    const [riders, setRiders] = useState([]);
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);


    const [id, setId] = useState("")


    console.log("rider id check==>", id)







    useEffect(() => {
        GetServices()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")




    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=accept`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("Accept reservations", result.data);
                        setGetReservations(result.data);
                        setGetReservations(result.data);


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=accept`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("Accept reservations", result.data);
                        setGetReservations(result.data);
                        setGetReservations(result.data);


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }




    const GetRiders = async (e) => {
        console.log("e", e)


        if (Role == "superadmin") {
            setLoader(true)
            toggle1()
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/attendence?role=superadmin&action=today`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)
                    if (result.status && result.status != "False") {
                        setRiders(result.data);
                        // setRiderid(result.data.id)
                        console.log("get Rider", result.data);
                    }

                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/')
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {

            setLoader(true)

            toggle1()

            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/attendence?role=subadmin&action=today`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false)

                    if (result.status && result.status != "False") {
                        setRiders(result.data);
                        // setRiderid(result.data.id)
                        console.log("get Rider", result.data);
                    }

                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/ManagerLogin')
                        } else {
                            setError2(true)
                            setMessage3(result.message);
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);

                    setError2(true)
                    setMessage3(error)
                });
        }

    }

    // Edit api
    const EditReservation = (a) => {

        if (a) {


            if (Role == "superadmin") {
                setLoader(true)

                const form = new FormData()
                form.append("reservationid", reservationid);
                form.append("riderid", id);
                form.append("action", "pick");


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/assignRider?role=superadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        setError1(false)
                        console.log(result);
                        if (result.status) {
                            GetServices()
                            setModal1(false)
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate('/')
                            } else {
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {

                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });

            }
            else {

                setLoader(true)

                const form = new FormData()
                form.append("reservationid", reservationid);
                form.append("riderid", id);
                form.append("action", "pick");


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/assignRider?role=subadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        setError1(false)
                        console.log(result);
                        if (result.status) {
                            GetServices()
                            setModal1(false)
                            setError(true)
                            setMessage(result.message)

                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate('/ManagerLogin')
                            } else {
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {

                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }
        }


    }

    const Reserve = (id) => {
        console.log("rider id", id)


        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to assign to this vallet ?")

            .then((result) => {
                EditReservation(result.isConfirmed)

            })
    }


    const CopyLink = (id) => {

        //    console.log("id====>",id)

        const livelink = `${"https://valet-userside.vercel.app" + `/CustomerPayment?reservationid=${id}`}`
        navigator.clipboard.writeText(livelink);
        //    console.log("live link",livelink)
    }
    return (
        <>

            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={EditReservation} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ? <AdminHeader /> : <ManagerHeader />}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Accept Reservations</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Customer ', field: 'name', filtering: false },
                                                        { title: 'Service ', field: 'servicename', filtering: false },
                                                        { title: 'Departure_Date', field: 'departure_airline', },
                                                        { title: 'Return_Date', field: 'return_airline', },
                                                        { title: 'Start', field: 'departure_airline_time', filtering: false },
                                                        { title: 'End', field: 'return_airline_time', filtering: false },
                                                        { title: 'Pick ', field: 'pickridername', render: rowData => rowData.pickridername == null ? "-" : rowData.pickridername, filtering: false },
                                                        { title: 'Drop ', field: 'dropridername', render: rowData => rowData.dropridername == null ? "-" : rowData.dropridername, filtering: false },
                                                        { title: 'Keyspot ', field: 'keySpot', render: rowData => rowData.keySpot == "" ? "-" : rowData.keySpot, filtering: false },
                                                        { title: 'FlightNumber ', field: 'flighNumber', filtering: false },

                                                        { title: ' Plate No ', field: 'licensePlate', filtering: false },
                                                        { title: 'Total Amount', field: 'totalamout', render: rowData => "$" + rowData.totalamout.toFixed(2), filtering: false },
                                                        // { title: 'Payment Status', field: 'paystatus' },
                                                        {
                                                            title: 'Vallet Assign', render: (rowData) =>

                                                                <>

                                                                    {rowData.rider == null && rowData.paystatus == true ?

                                                                        <Button type='button' onClick={() => { GetRiders(rowData); setReservationid(rowData.id) }} style={{ backgroundColor: "#03bafe", color: "white" }}  >Assign </Button> :
                                                                        rowData.paytype == "cashcounter" && rowData.rider == null ?
                                                                            <Button type='button' onClick={() => { GetRiders(rowData); setReservationid(rowData.id) }} style={{ backgroundColor: "blue", color: "white" }}  > Assign</Button> :
                                                                            rowData.paytype == "cashcounter" && rowData.rider != null ?
                                                                                <Button type='button' style={{ backgroundColor: "green", color: "white" }}  > Cash</Button> :



                                                                                // rowData.rider == null && rowData.paystatus == false ?

                                                                                //     <Button type='button' style={{ backgroundColor: "#008aff", color: "white",width:"100px" }} onClick={()=>CopyLink(rowData.id)}  > Copy Link</Button> :
                                                                                rowData.rider != null && rowData.status == "accept" ?

                                                                                    <Button type='button' style={{ backgroundColor: "green", color: "white" }}  > Booked</Button> :


                                                                                    ""






                                                                    }
                                                                </>

                                                        },




                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        rowData => ({
                                                            hidden: rowData.rider,
                                                            icon: DoneIcon,
                                                            tooltip: ' Assign New Vallet ',
                                                            onClick: (event, rowData) => {
                                                                // toggle1()

                                                                console.log("rowdata=> 1", rowData.id)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: !rowData.rider,

                                                            icon: ClearIcon,
                                                            tooltip: 'Vallet Already assign',
                                                            onClick: (event, rowData) => {
                                                                console.log("rowdata=> 2", rowData)

                                                            }
                                                        })
                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        pageSize: 10,
                                                        filtering: true

                                                        // exportButton: true
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ? <AdminFooter /> : <ManagerFooter />}


            {/* //Add MOdal  */}



            <Modal className='text-center' isOpen={modal1} size="lg" >
                <ModalHeader >
                    <modaltitle> Vallet Assign</modaltitle>
                    {/* <i onClick={toggle1} style={{ fontSize: "24px", marginLeft:"250px" }} className="fa fa-window-close "></i> */}
                </ModalHeader>
                <ModalBody className='text-left'>
                    <div className="la5lo1">
                        <div className="row">
                            <div className="col-md-12 item">
                                <MaterialTable
                                    title=""
                                    icons={{
                                        Check: Check,
                                        DetailPanel: ChevronRight,
                                        // Delete: DeleteOutline,
                                        Export: SaveAlt,
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        Search: Search,
                                        ThirdStateCheck: Remove,
                                        Add: Add,
                                        SortArrow: ArrowDownward,
                                        Clear: Clear,
                                        Edit: Edit,
                                        ViewColumn: ViewColumn,
                                        ResetSearch: Clear
                                    }}

                                    columns={[
                                        { title: 'First Name', field: 'firstname' },
                                        { title: 'Last Name', field: 'lastname' },
                                        { title: 'Email ', field: 'email' },
                                        { title: 'Time In ', render: rowData => TimeConverter(rowData.timein.split("T")[1]) },
                                        // { title: 'Time Out ',  render:rowData=>TimeConverter(rowData.timeout.split("T")[1])  },

                                        { title: 'Address', field: 'address' },
                                        { title: 'Contact', field: 'contact' },

                                        {
                                            title: 'Vallet Assign', render: (rowData) =>
                                                <>
                                                    {console.log("rowdata", rowData)}
                                                    <Button type='button' onClick={() => { setId(rowData.rider); Reserve() }} style={{ backgroundColor: "#03bafe", color: "white" }}  >Done</Button>
                                                </>
                                        },






                                    ]}
                                    data={riders}
                                    actions={[
                                        // {
                                        //     icon: RemoveRedEyeIcon,
                                        //     tooltip: 'view',
                                        //     onClick: (event, rowData) => {
                                        //         setModal1(false)
                                        //         toggle()
                                        //         console.log("rowdata=> 1", rowData.riderschedule)
                                        //         setRiderschedule(rowData.riderschedule)

                                        //     }
                                        // },

                                    ]}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#03bafe',
                                            color: '#FFF'
                                        },
                                        actionsColumnIndex: -1,
                                        // exportButton: true

                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>

                    <button type="button" onClick={() => toggle1()} className="col-2 main-btn ">Close</button>
                    {/* <button type="button"  className="col-2 main-btn" >Add</button> */}
                    {/* </div> */}

                </ModalFooter>

            </Modal>



            {/* Schedule modal  */}


            <Modal className='text-center' isOpen={modal} size="lg" >
                <ModalHeader >
                    <modaltitle> Vallet Schedule</modaltitle>
                    {/* <i onClick={toggle1} style={{ fontSize: "24px", marginLeft:"250px" }} className="fa fa-window-close "></i> */}
                </ModalHeader>
                <ModalBody className='text-left'>
                    <div className="la5lo1">
                        <div className="row">
                            <div className="col-md-12 item">
                                <MaterialTable
                                    title=""
                                    icons={{
                                        Check: Check,
                                        DetailPanel: ChevronRight,
                                        // Delete: DeleteOutline,
                                        Export: SaveAlt,
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        Search: Search,
                                        ThirdStateCheck: Remove,
                                        Add: Add,
                                        SortArrow: ArrowDownward,
                                        Clear: Clear,
                                        Edit: Edit,
                                        ViewColumn: ViewColumn,
                                        ResetSearch: Clear
                                    }}

                                    columns={[
                                        { title: 'Day', field: 'day' },
                                        { title: 'Start time', field: 'starttime', render: rowData => TimeConverter(rowData.starttime) },
                                        { title: 'End time ', field: 'endtime', render: rowData => TimeConverter(rowData.endtime) },


                                    ]}
                                    data={riderschedule}

                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#03bafe',
                                            color: '#FFF'
                                        },
                                        actionsColumnIndex: -1,
                                        // exportButton: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>

                    <button type="button" onClick={() => toggle()} className="col-2 main-btn ">Close</button>
                    {/* <button type="button"  className="col-2 main-btn" >Add</button> */}
                    {/* </div> */}

                </ModalFooter>

            </Modal>
        </>
    );
}
