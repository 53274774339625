import React,{useState,useEffect} from 'react';
import AdminFooter from "../../pages/Admin/layout/AdminFooter";
import AdminHeader from "../../pages/Admin/layout/AdminHeader";
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import  ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import HailIcon from '@mui/icons-material/Hail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';





export default function Tracking(props) {
    const Role = props.role
    const [GetReservations, setGetReservations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [Description, setDescription] = useState("");
    const [Servicename, setServicename] = useState("");

    // Edit

      const [modal1, setModal1] = useState(false);
      const toggle1 = () => setModal1(!modal1);
    const [id, setId] = useState("");

     // SUCCESS STATE
     const [error, setError] = useState(false);
     const [message, setMessage] = useState("");
     const handleCloseB = () => setError(false);
 
 
     //   Failed STATES 
 
     const [error2, setError2] = useState(false);
     const [message3, setMessage3] = useState("");
     const handleCloseB2 = () => setError2(false);
 
 
 





    useEffect(() => {
        GetServices()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")


    const GetServices = () => {
        if(Role=="superadmin"){
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=tracking`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else{

            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=tracking`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        
        }
      




 
    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            
            <Failed status={error2} message={message3} func={handleCloseB2} />
        {loader == true ? <Loader fullPage loading /> : null}

        {Role=="superadmin" ?  <AdminHeader />:  <ManagerHeader /> }   
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Tracking Car</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Customer Name', field: 'customername' },
                                                        { title: 'Service Name', field: 'servicename' },
                                                        { title: 'License Plate ', field: 'licensePlate' },
                                                        { title: 'Start Date', field: 'startdate' },
                                                        { title: 'End Date', field: 'enddate' },
                                                        { title: 'Total Amount', field: 'totalamout' },
                                                        { title: 'Depature Date', field: 'departure_airline' },
                                                        { title: 'Return Date', field: 'return_airline' },
                                                        { title: 'Status', field: 'status' },

                                                       
                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        rowData => ({
                                                            hidden: rowData.status != "accept",
                                                            icon: AccessTimeIcon,
                                                            tooltip: 'Accept ',
                                                            onClick: (event, rowData) => {

                                                              

                                                                console.log("rowdata=> Accept", rowData)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: rowData.status != "ontheway",

                                                            icon: TwoWheelerIcon,
                                                            tooltip: 'ontheway',
                                                            onClick: (event, rowData) => {
                                                                

                                                                console.log("rowdata=> ontheway", rowData)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: rowData.status != "picked",

                                                            icon: HailIcon,
                                                            tooltip: 'picked',
                                                            onClick: (event, rowData) => {
                                                               
                                                                

                                                                console.log("rowdata=> picked", rowData)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: rowData.status != "droped",

                                                            icon: PinDropIcon,
                                                            tooltip: 'droped',
                                                            onClick: (event, rowData) => {
                                                               
                                                                console.log("rowdata=> droped", rowData)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: rowData.status != "parked",

                                                            icon: LocalParkingIcon,
                                                            tooltip: 'parked',
                                                            onClick: (event, rowData) => {
                                                               

                                                                console.log("rowdata=> parked", rowData)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: rowData.status != "release",

                                                            icon: LocalShippingIcon,
                                                            tooltip: 'release',
                                                            onClick: (event, rowData) => {
                                                               

                                                                console.log("rowdata=> release", rowData)

                                                            }
                                                        }),

                                                        rowData => ({
                                                            hidden: rowData.status != "complete",

                                                            icon: DoneOutlineIcon,
                                                            tooltip: 'complete',
                                                            onClick: (event, rowData) => {
                                                                console.log("rowdata=> complete", rowData)

                                                            }
                                                        })

                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize:10,

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role=="superadmin" ?  <AdminFooter />:  <ManagerFooter /> }   



           

        </>
    );
}
