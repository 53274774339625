import React,{useState,useEffect} from 'react';
import AcceptRes from "../../../components/accept/AcceptReservation"



export default function Accept() {

    return (
        <>
      

            <AcceptRes role={"subadmin"} />
           
           

        
           

        </>
    );
}
