import React,{useState,useEffect} from 'react';
import Complete from "../../../components/complete/Complete"





export default function RiderComplete() {
  
 



    return (
        <>
      

           
          <Complete role={"rider"}/>
           

         
           

        </>
    );
}
