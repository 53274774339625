import React, { useState, useEffect } from 'react';
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import RiderHeader from "../../pages/Rider/layout/RiderHeader"
import RiderFooter from "../../pages/Rider/layout/RiderFooter"

import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./complete.css"
import { Player } from 'video-react';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { DropzoneArea } from 'material-ui-dropzone'
import S3FileUpload, { deleteFile } from "react-s3"
import config from '../../s3bucket/s3_config';
window.Buffer = window.Buffer || require("buffer").Buffer;







export default function Complete(props) {
    console.log("superadmin role? complete", props.role)
    const Role = props.role
    const [GetReservations, setGetReservations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [key, setKey] = useState('Pickup Images');
    const [loading, setLoading] = React.useState(false);
    const [fileList, setFileList] = useState([]);
    const [check, setCheck] = useState(false);

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [Description, setDescription] = useState("");
    const [Servicename, setServicename] = useState("");
    const [id, setId] = useState("")
    const [action, setAction] = useState("")

    // Edit

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);

    const [Reservationdet, setReservationdet] = useState([]);
    const [videos, setVideos] = useState([]);
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);
    const [video, setvideo] = useState([]);





    console.log("videos=>", videos)


    useEffect(() => {
        GetServices()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")
    const RiderToken = localStorage.getItem("RiderToken")





    //     const GetServices = () => {
    //         if (Role == "superadmin") {
    //             // setLoader(true);
    //             var requestOptions = {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: "Bearer " + Token
    //                 }
    //             };
    //             fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=complete`, requestOptions)
    //                 .then(response => response.json())
    //                 .then(result => {
    //                     setLoader(false);
    //                     if (result.status) {
    //                         console.log("get reservations", result.data);
    //                         setGetReservations(result.data);
    //                     }
    //                     else {
    //                         if (result.message == "Unauthorized") {
    //                             navigate("/")
    //                         } else {
    //                             setError2(true)
    //                             setMessage3(result.message)
    //                         }
    //                     }
    //                 })
    //                 .catch(error => {
    //                     setLoader(false);
    //                     setError2(true)
    //                     setMessage3(error)
    //                 });
    //         }
    //         else if(Role == "subadmin") {

    //             // setLoader(true);
    //             var requestOptions = {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: "Bearer " + ManagerToken
    //                 }
    //             };
    //             fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=complete`, requestOptions)
    //                 .then(response => response.json())
    //                 .then(result => {
    //                     setLoader(false);
    //                     if (result.status) {
    //                         console.log("get reservations", result.data);
    //                         setGetReservations(result.data);
    //                     }
    //                     else {
    //                         if (result.message == "Unauthorized") {
    //                             navigate("/ManagerLogin")
    //                         } else {
    //                             setError2(true)
    //                             setMessage3(result.message)
    //                         }
    //                     }
    //                 })
    //                 .catch(error => {
    //                     setLoader(false);
    //                     setError2(true)
    //                     setMessage3(error)
    //                 });
    //         }
    //         else{
    //  // setLoader(true);
    //  var requestOptions = {
    //     method: 'GET',
    //     headers: {
    //         Authorization: "Bearer " + RiderToken
    //     }
    // };
    // fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=rider&status=complete`, requestOptions)
    //     .then(response => response.json())
    //     .then(result => {
    //         setLoader(false);
    //         if (result.status) {
    //             console.log("get reservations", result.data);
    //             setGetReservations(result.data);
    //         }
    //         else {
    //             if (result.message == "Unauthorized") {
    //                 navigate("/ManagerLogin")
    //             } else {
    //                 setError2(true)
    //                 setMessage3(result.message)
    //             }
    //         }
    //     })
    //     .catch(error => {
    //         setLoader(false);
    //         setError2(true)
    //         setMessage3(error)
    //     });

    //         }

    //     }





    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=superadmin&action=drop`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {

                        setGetReservations(result.data);


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else if (Role == "subadmin") {

            // setLoader(true);
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=subadmin&action=drop`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {

                        setGetReservations(result.data);


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + RiderToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=rider&action=drop`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {

                        setGetReservations(result.data);


                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/rider/login")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }

    }


    const ReservationDetails = (id) => {
        if (Role == "superadmin") {
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=superadmin&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations complete", result.data);
                        setReservationdet(result.data.carimages);
                        setVideos(result.data.carvideos)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else if (Role == "subadmin") {

            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=subadmin&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setReservationdet(result.data.carimages);
                        setVideos(result.data.carvideos)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

        else {


            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + RiderToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=rider&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setReservationdet(result.data.carimages);
                        setVideos(result.data.carvideos)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }


    }

    // S3 COADE 

    const uploadFile = (file) => {
        console.log("file====>", file)

        return new Promise((resolve, reject) => {
            let finalarry = new Array()
            for (var i = 0; i < file.length; i++) {


                console.log("uploading==>", file[i])




                S3FileUpload.uploadFile(file[i], config)
                    .then(data => {
                        console.log("done ==> ", data.location)
                        finalarry.push(data.location.toString())
                        news()
                    })
                    .catch(err => {
                        reject(err)
                        console.log("error ==> ", err)

                    })
            }

            const news = () => {
                if (file.length === finalarry.length) {
                    resolve(finalarry)

                }
            }

        })
        // console.log("uploading....",file[i])

    }


    // Edit api
    const EditReservation = () => {
        setLoading(true)


        // console.log("fileList", fileList)

        console.log("newlist", fileList)
        uploadFile([...fileList, ...video]).then((e) => {

            const form = new FormData()
            form.append("action", action);
            form.append("reservationid", id);
            console.log("finalarry", typeof (e), e)
            form.append("carimages", JSON.stringify(e));


            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + RiderToken
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=rider`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)

                    // setLoader(false);
                    console.log(result);
                    if (result.status) {
                        setModal1(false)
                        GetServices()

                        setError(true)
                        setMessage(result.message)
                        setError1(false)
                    }
                    else {

                        setError2(true)
                        setMessage3(result.message)
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        })



    }


    // Edit api
    const EditReservation2 = (a) => {

        if (a) {

            if (action == "picked" || action == "complete") {
                toggle1()
            }
            else {






                setLoader(true)


                const form = new FormData()
                form.append("action", action);
                form.append("reservationid", id);

                for (var i = 0; i < fileList.length; i++) {


                    console.log("obj==>", fileList[i])

                    form.append("carimages", fileList[i]);
                }


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=rider`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {


                            setModal1(false)
                            GetServices()
                            setError(true)
                            setMessage(result.message)
                            setError1(false)
                        }
                        else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    })
                    .catch(error => {

                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }


        }

    }


    const Reserve = () => {
        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to change rider status?")

            .then((result) => {


                EditReservation2(result.isConfirmed)

            })
    }


    // dropzone 2 start
    const onDropzoneAreaChange = (files) => {
        setFileList(files);
        //console.log("Files:", files);
    };

    // dropzone 2 start
    const onDropzoneAreaChange2 = (files) => {
        setvideo(files);
        //console.log("Files:", files);
    };



    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={EditReservation2} checker={check} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ?
                <AdminHeader /> :
                Role == "subadmin" ?
                    <ManagerHeader /> :
                    Role == "rider" ?
                        <RiderHeader /> : ""
            }
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Complete</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: ' Name', field: 'name' },
                                                        { title: 'Service Name', field: 'servicename' },
                                                        { title: 'License Plate ', field: 'licensePlate' },
                                                        { title: 'Start ', field: 'departure_airline_time' },
                                                        { title: 'End ', field: 'return_airline_time' },
                                                        { title: 'Depature ', field: 'departure_airline' },
                                                        { title: 'Return ', field: 'return_airline' },
                                                        { title: 'Total Amount', field: 'totalamout', render: rowData => "$" + rowData.totalamout.toFixed(2) },
                                                        { title: 'Status', field: 'status' },


                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        {
                                                            icon: RemoveRedEyeIcon,
                                                            tooltip: 'Pending Icon',
                                                            onClick: (event, rowData) => {
                                                                toggle()

                                                                ReservationDetails(rowData.id)


                                                                console.log("rowdata=> 1", rowData)


                                                            }
                                                        },


                                                        rowData => ({
                                                            hidden: rowData.status != "parked",

                                                            icon: LocalParkingIcon,
                                                            tooltip: 'parked',
                                                            onClick: (event, rowData) => {
                                                                console.log("rowdata=> parked", rowData)
                                                                setId(rowData.id)
                                                                setAction("complete")
                                                                Reserve()


                                                            }
                                                        }),

                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize: 10,

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ?
                <AdminFooter /> :
                Role == "subadmin" ?

                    <ManagerFooter /> :
                    Role == "rider" ?
                        <RiderFooter /> : ""
            }



            {/* //Add MOdal  */}
            <Modal className='text-center' isOpen={modal} size="lg" >
                <ModalHeader >
                    <modalTitle>  Car Complete Details</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Pickup Images" title="Pickup Images">
                            <section className="gallery">

                                {Reservationdet.map((v, i) => {
                                    if (v.imgtype == "picked") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="galler" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Complete Images" title="Complete Images">
                            <section className="gallery">

                                {Reservationdet.map((v, i) => {
                                    if (v.imgtype == "complete") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="gallery" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Pickup Videos" title="Pickup Videos" >
                            {videos.map((v, i) => {
                                if (v.imgtype == "picked") {
                                    return <><h3>Car video at the time of pickup</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }


                            })}

                        </Tab>
                        <Tab eventKey="Complete Videos" title="Complete Videos" >
                            {videos.map((v, i) => {
                                if (v.imgtype == "complete") {
                                    return <><h3>Car video at the time of complete</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }



                            })}


                        </Tab>
                    </Tabs>

                </ModalBody>
                <ModalFooter>

                    <Button variant="primary" onClick={() => toggle()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>




            <Modal className='text-center' isOpen={modal1} size="lg" >
                <ModalHeader >
                    <modalTitle>Upload Your Car Images and Video </modalTitle>
                    <i onClick={toggle1} style={{ fontSize: "24px", marginLeft: "750px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <div>



                        <DropzoneArea
                            acceptedFiles={['image/jpeg,image/png']}

                            filesLimit={7}
                            maxFileSize={5242880} //5 MB
                            showFileNames={true}
                            dropzoneText={"Please upload your car pictures"}
                            onChange={onDropzoneAreaChange}
                        />

                        <DropzoneArea
                            acceptedFiles={['video/*']}
                            filesLimit={1}
                            maxFileSize={20971520} //20 MB
                            showFileNames={true}
                            dropzoneText={"Please Upload your car video"}
                            onChange={onDropzoneAreaChange2}
                        />

                    </div>

                </ModalBody>
                <div className='row'>
                    <div className='col-md-12 mb-3 rounded'>
                        <LoadingButton
                            onClick={() => EditReservation()}
                            endIcon={<SendIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"

                            style={{ backgroundColor: "rgb(3, 186, 254)", color: "white" }}
                        >
                            Send
                        </LoadingButton>
                    </div>
                </div>


            </Modal>



        </>
    );
}
