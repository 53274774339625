
import React, { useEffect, useInsertionEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Admin/auth/newlogo.png"
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from "sweetalert2";
import baseUrl from "../../../baseurl/BaseUrl";
import Avator from "../../Admin/auth/avator.jpeg"
import { ToastContainer, toast } from 'react-toastify';
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed'
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';
// const socket = io("https://sockets.laxairportvaletservices.com/")
const socket = io("https://sockets.hnhsofttechsolutions.com/")






export default function Header() {
    const liStyle = { color: 'blue', fontSize: '23px' }

    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;
    console.log("pathname", pathname)
    const [active, setActive] = useState("");

    console.log("active", active)
    //Javascript split method to get the name of the path in array


    const [Sidebar, setSidebar] = useState("");
    const [profileDrop, setprofileDrop] = useState("");
    // const [profileDrop, setprofileDrop] = useState("");

    const [profileData, setprofileData] = useState([]);
    const [profileimg, setprofileimg] = useState(null);
    // console.log("profile img", profileimg)

    const [loader, setLoader] = useState(false);
    // forgot
    const [OldPassword, setOldPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmNewPassword, setConfirmNewPassword] = useState("");
    const [modaltitle, setmodaltitle] = useState("");
    const [modal, setModal] = useState(false);
    const [state, setState] = useState(true);
    const toggle2 = () => setModal(!modal);
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);

    const [action, setAction] = useState("");

    const [notif, setNotif] = useState([]);
    const [read, setRead] = useState(0);







    const navigate = useNavigate()

    // console.log("profileData", profileData)


    useEffect(() => {
        setActive(pathname)
        GetNotifyall()
        if (localStorage.getItem("AdminToken") == null) {
            navigate("/")
        }
        else {
            getProfile()


        }




    }, []);


    const notify = (comment, route) => {
        toast(comment, {
            autoClose: 5000,

            style: { backgroundColor: "#6BE521", color: "black" },

        }

        );

        navigate(route)
    }

    useEffect(() => {
        socket.on('admin_manager', (data) => {

            notify("New Reservation Arrived!", "/AcceptReservation")



        });

        // socket.on('admin_managerontheway', (data) => {

        //     notify("Rider is On the Way!", "/Tracking")



        // });
        socket.on('admin_managerpicked', (data) => {

            notify("Vallet Picked the Customer !", "/Tracking")



        });
        // socket.on('admin_managerdroped', (data) => {

        //     notify("Rider Drop the Order!", "/Tracking")



        // });
        socket.on('admin_managerparked', (data) => {

            notify("Vallet  parked the car !", "/Tracking")



        });
        // socket.on('admin_managerrelease', (data) => {

        //     notify("Rider is Release !", "/Tracking")



        // });

        socket.on('admin_managercomplete', (data) => {

            notify("Vallet Complete the ride!", "/Tracking")



        });
        return () => {
            socket.off('admin_manager');
            socket.off('admin_managerontheway');
            socket.off('admin_managerpicked');
            socket.off('admin_managerontheway');
            socket.off('admin_managerdroped');
            socket.off('admin_managerparked');
            socket.off('admin_managerrelease');
            socket.off('admin_managercomplete');


        }
            ;



    }, [])



    const sidebarToggle = (e) => {
        if (e == Sidebar) {
            setSidebar("")
        } else {
            setSidebar(e)
        }
    }



    const profiledropdown = (e) => {
        console.log("e sas kai", e)
        if (e == profileDrop) {
            setprofileDrop("")
        } else {
            setprofileDrop(e)
        }
    }

    const Token = localStorage.getItem("AdminToken")

    const getProfile = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=superadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("get messsage", result.message);
                    setprofileData(result.data)
                    setprofileimg(result.data.profile)

                    document.getElementById("profileid").src = result.data.profile



                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/')
                    }
                    setError2(true)
                    setMessage3(result.message)


                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)

            });
    }

    const logout = () => {
        localStorage.removeItem("AdminToken");
        localStorage.removeItem("AdminData");
        navigate("/");
    }



    const handleInputChange = (e, func) => {
        func(e.target.value);
    }
    // Forget password api

    const forgot = () => {

        if (NewPassword == "" || ConfirmNewPassword == "" || OldPassword == "") {

            setError2(true)
            setMessage3("All keys are required ")

        }
        else {



            if (NewPassword == ConfirmNewPassword) {
                toggle2();
                setLoader(true);
                const form = new FormData()
                form.append("oldpassword", OldPassword);
                form.append("password", NewPassword);
                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                fetch(`${baseUrl.baseUrl}webapi/changepassword?role=superadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        // console.log(result);
                        if (result.status) {


                            setOldPassword("");
                            setNewPassword("");
                            setConfirmNewPassword("");
                            setMessage(result.message)
                            setError1(false)
                        }
                        else {
                            setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });
            } else {
                setError2(true)
                setMessage3("Password & Confirm Password does not match ")

            }
        }
    }


    // GET NOTIFICATION 


    const GetNotifyall = () => {

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/adminNotifications?role=superadmin&action=all`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("get messsage", result.data);
                    setNotif(result.data)
                    setRead(result.nonread)






                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/')
                    }
                    setError2(true)
                    setMessage3(result.message)


                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)

            });
    }


    const GetNotifyread = () => {
        if (read == 0) {
            return null
        }

        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/adminNotifications?role=superadmin&action=read`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("get messsage", result.data);
                    // setNotif(result.data)
                    setRead(0)






                }
                else {

                    if (result.message == "Unauthorized") {
                        navigate('/')
                    }
                    setError2(true)
                    setMessage3(result.message)


                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)

            });
    }






    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {/* <button onClick={notify}>Notify!</button> */}
            {/* {loader == true ? <Loader fullPage loading /> : null} */}
            <header className="header clearfix" style={{ backgroundColor: "#03bafe" }}>
                <button onClick={() => sidebarToggle("smallScreen")} type="button" id="toggleMenu" className="toggle_menu">
                    <i className='uil uil-bars'></i>
                </button>
                <button onClick={() => sidebarToggle("largeScreen")} className="collapse_menu">
                    <i className="uil uil-bars collapse_menu--icon "></i>
                    <span className="collapse_menu--label"></span>
                </button>
                <div className="main_logo" id="logo">
                    <Link to="#"><a href="#"><img src={logo} width={"55px"} alt="" /></a></Link>
                    {/* <Link to="#"><a href="#"><img className="logo-inverse" src={logo} width={"190px"} alt="" /></a></Link> */}
                </div>

                {/* <div className="top-category">
                    <div className="ui compact menu cate-dpdwn">
                        <div className="ui simple dropdown item">
                            <a href="#" className="option_links p-0" title="categories"><i className="uil uil-apps"></i></a>
                            <div className="menu dropdown_category5">
                                <a href="#" className="item channel_item">Development</a>
                                <a href="#" className="item channel_item">Business</a>
                                <a href="#" className="item channel_item">Finance & Accounting</a>
                                <a href="#" className="item channel_item">IT & Software</a>
                                <a href="#" className="item channel_item">Office Productivity</a>
                                <a href="#" className="item channel_item">Personal Development</a>
                                <a href="#" className="item channel_item">Design</a>
                                <a href="#" className="item channel_item">Marketing</a>
                                <a href="#" className="item channel_item">Lifestyle</a>
                                <a href="#" className="item channel_item">Photography</a>
                                <a href="#" className="item channel_item">Health & Fitness</a>
                                <a href="#" className="item channel_item">Music</a>
                                <a href="#" className="item channel_item">Teaching & Academics</a>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="search120">
                    <div className="ui search">
                        <div className="ui left icon input swdh10">
                            <input className="prompt srch10" type="text"
                                placeholder="Search here.." />
                            <i className='uil uil-search-alt icon icon1'></i>
                        </div>
                    </div>
                </div> */}
                <div className="header_right">



                    <ul>
                        {/* <li onClick={() => profiledropdown("messages")} className={profileDrop == "messages" ? "ui dropdown active visible" : "ui dropdown"}>
                            <a href="#" className="option_links" title="Messages"><i className='uil uil-envelope-alt' style={{ color: "white" }}></i><span
                                className="noti_count">3</span></a>
                            <div className={profileDrop == "messages" ? "menu dropdown_ms left transition visible d-block" : "menu dropdown_ms"}>
                                <a href="#" className="channel_my item">
                                    <div className="profile_link">
                                        <img src="/assets/images/person_1.jpg" alt="" />
                                        <div className="pd_content">
                                            <h6>Zoena Singh</h6>
                                            <p>Hi! Sir, How are you. I ask you one thing please explain it this video price.</p>
                                            <span className="nm_time">2 min ago</span>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="channel_my item">
                                    <div className="profile_link">
                                        <img src="/assets/images/person_1.jpg" alt="" />
                                        <div className="pd_content">
                                            <h6>Joy Dua</h6>
                                            <p>Hello, I paid you video tutorial but did not play error 404.</p>
                                            <span className="nm_time">10 min ago</span>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="channel_my item">
                                    <div className="profile_link">
                                        <img src="/assets/images/person_1.jpg" alt="" />
                                        <div className="pd_content">
                                            <h6>Jass</h6>
                                            <p>Thanks Sir, Such a nice video.</p>
                                            <span className="nm_time">25 min ago</span>
                                        </div>
                                    </div>
                                </a>
                                <a className="vbm_btn" href="instructor_messages.html">View All <i
                                    className='uil uil-arrow-right'></i></a>
                            </div>
                        </li> */}
                        <li onClick={() => profiledropdown("notifications")} className={profileDrop == "notifications" ? "ui dropdown active visible" : "ui dropdown"} >
                            <a href="#" className="option_links" title="Notifications"  ><i onClick={() => GetNotifyread()} className='uil uil-bell' style={{ color: "white" }}></i><span
                                className="noti_count">{read}</span></a>
                            <div className={profileDrop == "notifications" ? "menu dropdown_mn left transition visible d-block" : "menu dropdown_mn"} style={{ height: "350px", overflow: "scroll" }}>
                                {notif.map((v, i) => {

                                    return <a href="#" className="channel_my item">
                                        <div className="profile_link">
                                            <img src={v.img} alt="" />
                                            <div className="pd_content">
                                                <h6>{v.fname + " " + v.lname}</h6>
                                                <p>{v.message}</p>
                                                {/* <span className="nm_time">2 min ago</span> */}
                                            </div>
                                        </div>
                                    </a>


                                })}

                                {/*                               
                                <a className="vbm_btn" href="instructor_notifications.html">View All <i
                                    className='uil uil-arrow-right'></i></a> */}
                            </div>
                        </li>
                        <li className="ui dropdown">
                            <a href="#" className="option_links"><i className="fas fa-user-friends" style={{ color: "white", fontSize: "18px" }}></i></a></li>



                        <li onClick={() => { profiledropdown("profile") }} className={profileDrop == "profile" ? "ui dropdown active visible" : "ui dropdown"}>

                            <a href="#" className="opts_account" title="Account Information">

                            </a>
                            <img className="rounded-circle" id="profileid" src={Avator} alt="" width="40px" height="40px" />
                            <div className={profileDrop == "profile" ? "menu dropdown_account left transition visible d-block" : "menu dropdown_account"}>
                                <div className="channel_my">
                                    <div className="profile_link">
                                        <img src={profileimg == null ? Avator : profileimg} alt="" />
                                        <div className="pd_content">
                                            <div className="rhte85">
                                                <h6 className="text-capitalize">{profileData.fname + " " + profileData.lname}</h6>
                                                <div className="mef78" title="Verify">
                                                    <i className='uil uil-check-circle'></i>
                                                </div>
                                            </div>
                                            <span>
                                                <a href="#"
                                                    className="__cf_email__  text-capitalize"
                                                ></a>
                                            </span>
                                        </div>
                                    </div>
                                    <Link to="/Profile"> <a href="#" className="item channel_item " >View Profile</a></Link>
                                </div>

                                <a href="#" onClick={() => toggle2()} className="item channel_item">Change Password</a>

                                <a onClick={logout} href="#" className="item channel_item">Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </header>


            <nav style={{ backgroundColor: "#03bafe" }} className={Sidebar == "largeScreen" ? "vertical_nav vertical_nav__minify" : (Sidebar == "smallScreen" ? "vertical_nav vertical_nav__opened" : "vertical_nav")}>
                <div className="left_section menu_left" id="js-menu" >

                    <div className="sidebar-user">

                        <div className='row ' style={{ backgroundColor: "white" }} >
                            <div className='col safdar' >
                                <img className="rounded-circle" src={profileimg == null ? Avator : profileimg} width={"60px"} />

                            </div>
                            <div className='col ahmed' style={{ alignItems: "center" }}>
                                <h6 className="text-capitalize">{profileData.fname}</h6>
                                <p>Admin</p>
                            </div>


                        </div>
                        {/* <div className="text-center mt-3" style={{ backgroundColor: "white", height: "80px" }}>
                            <img className="rounded-circle" src="assets/images/person_1.jpg" width={"60px"} />
                            <p>Admin</p>
                        </div> */}



                        <ul>










                            <li className="menu--item">
                                <Link to="/dashboard">   <a href="#" className={active == "/dashboard" ? "menu--link  navActive" : "menu--link "} title="Dashboard">
                                    {/* <i className='uil uil-home-alt menu--icon'></i> */}
                                    <i className="fa fa-desktop menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Dashboard</span>
                                </a></Link>
                            </li>


                            <li onClick={() => profiledropdown("dropdown")} className={profileDrop == "dropdown" ? " menu--item menu--item__has_sub_menu menu--subitens__opened" : "menu--item menu--item__has_sub_menu"}>
                                <i className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="Reservations">
                                    <i className="uil uil-clipboard-alt menu--icon" />
                                    <span className="menu--label">Reservations</span>
                                </i>
                                <ul className="sub_menu">


                                    <li className="sub_menu--item">
                                        <Link to="/AcceptReservation">  <a href="#" className="sub_menu--link">
                                            New reservation
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/TodayReturn">  <a href="#" className="sub_menu--link">
                                            Todays Return
                                        </a></Link>
                                    </li>



                                    <li className="sub_menu--item">
                                        <Link to="/UpcominReservation">  <a href="#" className="sub_menu--link">
                                            upcoming return
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/ActiveReservations">   <a href="#" className="sub_menu--link">
                                            Active Reservation
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/RepeatReservations">   <a href="#" className="sub_menu--link">
                                            Repeat Reservation
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/AdminParked">   <a href="#" className="sub_menu--link">
                                            Parked
                                        </a></Link>

                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/AdminPickup">   <a href="#" className="sub_menu--link">
                                            Picked
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/AdminComplete">   <a href="#" className="sub_menu--link">
                                            Completed
                                        </a></Link>

                                    </li>
                                    <li className="sub_menu--item">
                                        <Link to="/Return">   <a href="#" className="sub_menu--link">
                                            Returns
                                        </a></Link>

                                    </li>


                                </ul>
                            </li>

                            <li className="menu--item">

                                <Link to="/UserAdmin">   <a href="#" className={active == "/UserAdmin" ? "menu--link  navActive" : "menu--link "} title="Customers">

                                    <i className="	fas fa-user-friends menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Customers</span>
                                </a></Link>

                            </li>

                            <li className="menu--item">

                                <Link to="/AdminCarWash">  <a href="#" className={active == "/AdminCarWash" ? "menu--link  navActive" : "menu--link "} title="Other Services">

                                    <i className="	fas fa-car menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Other Services</span>
                                </a></Link>

                            </li>

                            <li className="menu--item">

                                <Link to="/AdminBooking"> <a href="#" className={active == "/AdminBooking" ? "menu--link  navActive" : "menu--link "} title="Booking Form">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fa fa-book menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Booking Form</span>
                                </a></Link>

                            </li>



                            <li onClick={() => profiledropdown("inventory")} className={profileDrop == "inventory" ? " menu--item menu--item__has_sub_menu menu--subitens__opened" : "menu--item menu--item__has_sub_menu"}>
                                <i className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="inventory">
                                    <i className="uil uil-clipboard-alt menu--icon" />
                                    <span className="menu--label">Inventory Section</span>
                                </i>
                                <ul className="sub_menu">
                                    <li className="sub_menu--item">
                                        <Link to="/categories">  <a href="#" className="sub_menu--link">
                                            Categories
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/Services">  <a href="#" className="sub_menu--link">
                                            Services
                                        </a></Link>
                                    </li>

                                    <li className="sub_menu--item">
                                        <Link to="/AdminPromo">  <a href="#" className="sub_menu--link">
                                            Promo Code
                                        </a></Link>
                                    </li>






                                </ul>
                            </li>

                            <li className="menu--item">
                                <Link to="/EmployeeAdmin">  <a href="#" className={active == "/EmployeeAdmin" ? "menu--link  navActive" : "menu--link "} title="EmployeeAdmin">
                                    <i className="fas fa-user  menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Employee Management</span>
                                </a></Link>

                            </li>


                            <li className="menu--item">

                                <Link to="/Profile">  <a href="#" className={active == "/Profile" ? "menu--link  navActive" : "menu--link "} title="Profile">
                                    <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className="menu--label">Setup Profile</span>
                                </a></Link>

                            </li>

                            <li className="menu--item">
                                <Link to="/RiderRegistration">  <a href="#" className={active == "/RiderRegistration" ? "menu--link  navActive" : "menu--link "} title="Vallet Management">
                                    <i className=" fa fa-motorcycle  menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">User Accounts</span>
                                </a></Link>
                            </li>

                            <li className="menu--item">
                                <Link to="/Pricing"><a href="#" className={active == "/Pricing" ? "menu--link  navActive" : "menu--link "} title="Pricing">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fas fa-dollar-sign menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Pricing & Taxes</span>
                                </a></Link>
                            </li>

                            <li className="menu--item">
                                <Link to="/PaymentAdmin"><a href="#" className={active == "/PaymentAdmin" ? "menu--link  navActive" : "menu--link "} title="Payment">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fas fa-money-bill-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Payment</span>
                                </a></Link>
                            </li>

                            {/* <li className="menu--item">
                                <Link to="/chartjs"> <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Dashboard">
                                
                                    <i className="fa fa-home menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Dashboard</span>
                                </a></Link>
                            </li> */}

                            {/* <li className="menu--item">
                                <Link to="/categories">   <a href="#" className={active == "/categories" ? "menu--link  navActive" : "menu--link "} title="Categories">
                                    <i className='uil uil-home-alt menu--icon'></i>
                                    <i className="fa fa-tasks menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Categories</span>
                                </a></Link>
                            </li> */}


                            {/* <li className="menu--item">

                                <Link to="/Services">  <a href="#" className={active == "/Services" ? "menu--link  navActive" : "menu--link "} title="Services">
                                    <i className='uil uil-home-alt menu--icon'></i>
                                    <i className="fa fa-wrench menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Services</span>
                                </a></Link>

                            </li> */}

                            <li className="menu--item">

                                <Link to="/Manager">   <a href="#" className={active == "/Manager" ? "menu--link  navActive" : "menu--link "} title="Manager">
                                    {/* <i className='uil uil-kayak menu--icon'></i> */}
                                    <i className=" fas fa-user-tie  menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Manager</span>
                                </a></Link>

                            </li>
                            {/* <li className="menu--item">

                                <Link to="/AdminPromo">   <a href="#" className={active == "/AdminPromo" ? "menu--link  navActive" : "menu--link "} title="Promo Code">
                                    <i className='uil uil-kayak menu--icon'></i>
                                    <i className=" fa fa-gift  menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Promo Code</span>
                                </a></Link>

                            </li> */}

                            <li className="menu--item">

                                <Link to="/AdminCardDetails">   <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive" : "menu--link "} title="CardDetails">
                                    {/* <i className='uil uil-kayak menu--icon'></i> */}
                                    <i className=" fa fa-credit-card menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Card Details</span>
                                </a></Link>

                            </li>

                          








                            {/* <Link to="/Trackcar">      <li className="menu--item">

                                <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Track Car">

                                    <i className="fa fa-map-marker menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Track Car</span>
                                </a>

                            </li></Link> */}

                          
                          







                            {/* <li className="menu--item">

                                <Link to="/PendingReservations"><a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Pending">

                                    <i className="fas fa-hourglass menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Pending Reservations</span>
                                </a></Link>

                            </li> */}
                            {/* 
                            <li className="menu--item">

                                <Link to="/AcceptReservation"><a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="accept">

                                    <i className="far fa-check-circle menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Accept Reservations</span>
                                </a></Link>

                            </li> */}

                            {/* <li className="menu--item">

                                <Link to="/AdminRejection"><a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Reject">

                                    <i className="fa fa-window-close menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Reject Reservation</span>
                                </a></Link>

                            </li> */}


                            {/* <li className="menu--item">

                                <Link to="/AdminPickup"><a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="accept">

                                    <i className="	fas fa-car-side menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Pickup</span>
                                </a></Link>

                            </li> */}

                            {/* <li className="menu--item">

                                <Link to="/AdminComplete"><a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="accept">

                                    <i className="fa fa-check-square menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Complete</span>
                                </a></Link>

                            </li> */}

                            <li className="menu--item">

                                <Link to="/Tracking"><a href="#" className={active == "/Tracking" ? "menu--link  navActive" : "menu--link "} title="accept">
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <i className="fa fa-map-marker menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Tracking</span>
                                </a></Link>

                            </li>


                            <li className="menu--item">

                                <a onClick={logout} href="#" className="menu--link " title="Logout">
                                    <i className="fas fa-sign-out-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    {/* <i className="uil uil-heart-alt menu--icon"></i> */}
                                    <span className=" menu--label">Logout</span>
                                </a>

                            </li>






                        </ul>
                    </div>

                    {/* <div className="left_section pt-2">
                        <ul>
                            <li className="menu--item">

                                    <a href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "}  title="Profile">

                                        <i className="fas fa-user menu--icon d-flex justify-content-center align-items-center"></i>
                                        <span className="menu--label">Profile</span>
                                    </a>

                            </li>
                            <li className="menu--item">
                                <a  href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="website">

                                    <i className="fas fa-globe menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Website</span>
                                </a>
                            </li>
                            <li className="menu--item">
                                <a  href="#" className={active == "/AdminCardDetails" ? "menu--link  navActive":"menu--link "} title="Logout">

                                    <i className="fas fa-sign-out-alt menu--icon d-flex justify-content-center align-items-center"></i>
                                    <span className="menu--label">Logout</span>
                                </a>
                            </li>

                        </ul>
                    </div> */}
                    <div className="left_footer">



                        <div className="left_footer_content" style={{ paddingTop: "20vh" }}>
                            {/* <img src={logo} width={"100px"} /> */}
                        </div>
                    </div>
                </div>
            </nav>



            <Modal className='text-center' isOpen={modal} toggle={toggle2} size="md" centered={true}>
                <ModalHeader  >Change Password
                    {/* <i toggle={toggle2}  style={{ fontSize: "24px", marginLeft: "400px" }} className=" "></i> */}

                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">Old Password*</label>
                                    <input type="password" value={OldPassword} onChange={(e) => handleInputChange(e, setOldPassword)} className="form-control" placeholder='Write Old Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">New Password*</label>
                                    <input type="password" value={NewPassword} onChange={(e) => handleInputChange(e, setNewPassword)} className="form-control" placeholder='Write New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row form-row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label25 text-left">Confirm New Password*</label>
                                    <input type="password" value={ConfirmNewPassword} onChange={(e) => handleInputChange(e, setConfirmNewPassword)} className="form-control" placeholder='Write Confirm New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle2()} className="col-3 main-btn cancel">Close</button>
                            <button type="button" onClick={() => forgot()} className="col-3 main-btn" >Update</button>
                        </div>
                        {/* <button type="button" onClick={() => addCourses(Token)} className="login-btn btn-block">Add</button> */}
                    </form>
                </ModalBody>
            </Modal>
        </>
    )
}