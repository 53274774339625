import React,{useState,useEffect} from 'react';
import EmployeeManagment from '../../../components/employee/EmployeeManagement';





export default function EmployeeAdmin() {
  
 



    return (
        <>
      

           
          <EmployeeManagment role={"superadmin"}/>
           

         
           

        </>
    );
}
