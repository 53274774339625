import React,{useState,useEffect} from 'react';
import Rejection from "../../../components/rejection/Rejection"





export default function AdminRejection() {
  
 



    return (
        <>
      

           
          <Rejection role={"superadmin"}/>
           

         
           

        </>
    );
}
