import React, { useEffect, useState } from "react";
import baseUrl from "../../../baseurl/BaseUrl";
// import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";
import ManagerHeader from "../layout/MangerHeader";

import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import logo from "../../Admin/auth/logo1.png"
import ManagerFooter from '../layout/ManagerFooter'
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed';


export default function Profile() {

    const navigate = useNavigate();
    const [GetProfile, setGetProfile] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [username, setUsername] = useState("");
    const [ProfileImage, setProfileImage] = useState(null);

    const [SelectProfileImage, setSelectProfileImage] = useState(null);
    const [UpdateProfileImage, setUpdateProfileImage] = useState("");
    // SUCCESS STATE
const [error, setError] = useState(false);
const [message, setMessage] = useState("");
const handleCloseB = () => setError(false);

// INFO STATES 
const [error1, setError1] = useState(false);
const [message1, setMessage1] = useState("");
const [message2, setMessage2] = useState("");
const handleCloseB1 = () => setError1(false);

//   Failed STATES 

const [error2, setError2] = useState(false);
const [message3, setMessage3] = useState("");
const handleCloseB2 = () => setError2(false);


console.log("UpdateProfileImage profile imge", UpdateProfileImage)

    useEffect(() => {
       getProfile()
    }, [])


    const handleInputChange = (event, func) => {
        func(event.target.value);
        setDisable(false);
        console.log('dis')


    }

    const handleInputFileChange = (event, func) => {

        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            setError2(true)
            setMessage3("plz select your image less then and equals to 1mb ")
            
        } else {
            setDisable(false);
            var url = reader.readAsDataURL(file);
            // console.log(url)
            reader.onloadend = function (e) {
                setSelectProfileImage(reader.result);
                setUpdateProfileImage(file);

                // console.log("data-======>",reader.result)
            };
            // console.log(selectProfileImage)
            func(event.target.files[0])
            // setdisable(false)
        }
    }
    const Token = localStorage.getItem("ManagerToken")

    const getProfile = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status ) {
                    console.log("get profile", result);
                    setFname(result.data.fname)
                    setLname(result.data.lname)
                    setUsername(result.data.username)
                    setEmail(result.data.email)
                    setAddress(result.data.address)
                    setContact(result.data.contact)
                    setProfileImage(result.data.profile);

                }
                else {
                    if(result.message == "Unauthorized"){
                        navigate('/ManagerLogin')
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }



    const updateProfile = () => {
        setLoader(true);

        if(fname == "" || lname == "" || address == "" || username == "" || contact == "" || email == "" || UpdateProfileImage == null ){



            setLoader(false)
            setError2(true)
            setMessage3("All keys are required ")
        }
        else{




    const formData = new FormData();

        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("email", email);
        formData.append("username", username);
        formData.append("contact", contact);
        formData.append("address", address);
        formData.append("img", UpdateProfileImage);


        var requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: "Bearer " + Token
            },
            body: formData,
        };
        fetch(`${baseUrl.baseUrl}webapi/profile?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                console.log("profile update=====>", result);
                if (result.status) {
                    getProfile()
                    setError(true)
                    setMessage(result.message)
                } else {
                    setError2(true)
                    setMessage3(result.message)
                }
            }, (error) => {
                setLoader(false);
                // console.log(error);
                setError2(true)
                setMessage3(error)
            });
        }
    }


    return (
        <>
         <Error status={error} message={message} func={handleCloseB} />
         <Info status={error1} message={message1} msg={message2} func={handleCloseB1} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
<ManagerHeader/>
            {loader == true ? <Loader fullPage loading /> : null}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                    <div className="row">
                        <div className="card" style={{ width: '100%' }}>
                            <div className="card-body">
                                <h5 className="card-title">Edit Profile</h5>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <input
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="icon-button-file"
                                            type="file"
                                            onChange={(e) => handleInputFileChange(e, setProfileImage)}
                                        />
                                        <label htmlFor="icon-button-file">
                                            <IconButton
                                                color="#414141"
                                                aria-label="upload picture"
                                                component="span"
                                                className="upload_photo_main"
                                            >
                                                <img style={{ minWidth: "100px", maxWidth: "100px", borderRadius: "20px" }} src={SelectProfileImage ? SelectProfileImage :  ProfileImage}  className="upload_photo_main" alt=""/>
                                            </IconButton>
                                        </label>
                                    </div>
                                </div>
                                <div className="row form-row">
                                    <div className="col-md-6 mt-3 form-group">
                                        <label className="label25 text-left">First Name*</label>
                                        <input  value={fname} onChange={(e) => handleInputChange(e, setFname)} className="form-control text-capitalize" type="text" placeholder="Enter Your First Name" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label className="label25 text-left">Last Name*</label>
                                        <input value={lname} onChange={(e) => handleInputChange(e, setLname)}  className="form-control text-capitalize"  type="text" placeholder="Enter Your Last Name" />
                                    </div>
                                </div>
                                <div className="row form-row">
                                
                                    <div className="col-md-6 mt-3">
                                        <label className="label25 text-left">Email*</label>
                                        <input disabled value={email} onChange={(e) => handleInputChange(e, setEmail)}   className="form-control" type="text" placeholder="Enter Your Email" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label className="label25 text-left">Address*</label>
                                        <input value={address}   onChange={(e) => handleInputChange(e, setAddress)}  className="form-control" type="text" placeholder="Enter Your Address" />
                                    </div>

                                </div>

                                <div className="row form-row">
                                <div className="col-md-6 mt-3">
                                        <label className="label25 text-left">Contact*</label>
                                        <input value={contact}  onChange={(e) => handleInputChange(e, setContact)}  className="form-control text-capitalize" type="text" placeholder="Enter Your Contact" />
                                    </div>
                                   
                                </div>

                                <div className="row ">
                                    <div className="col-md-12 text-right mt-3">
                                        <button onClick={() => updateProfile()} className="main-btn" type="button" >Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                       < ManagerFooter/>
        </>

    )
}