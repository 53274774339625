import React, { useState, useEffect } from 'react';
import Pendings from "../../../components/pending/PendingReservation"





export default function Pending() {





    return (
        <>



            <Pendings role={"subadmin"} />





        </>
    );
}
