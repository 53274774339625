import React, { useState, useEffect } from 'react';

import "./modal.css"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Message } from '@material-ui/icons';






export default function Info({ status, message, msg , func, del, reserve ,checker}) {
    // console.log("status", status, message,func ,msg,  del, reserve,checker)
    console.log("checker",checker)

   
 
 


    return (
        <>




            <Modal className='text-center' isOpen={status}  size="md" centered={true}>
                <ModalHeader closeButton >
                    <modalTitle className=""></modalTitle>

                    <span className="card__success"><i className="fa fa-info-circle" /></span>
                </ModalHeader>
                <ModalBody className='text-left'>
                    
                    <h2 style={{marginTop:"45px"}} class="text-center">{message}</h2>
                    <h4 style={{marginTop:"10px"}} class="text-center">{msg}</h4>


                </ModalBody>
                <ModalFooter>
                   
                  {checker == true ?   <button type="button" onClick={reserve}   className=" main-btn ">Yes </button>: 
                    <button type="button" onClick={del} className=" main-btn ">Yes  </button>   }
                    <button type="button" onClick={func}  className=" main-btn">cancel</button>


              

                   

                </ModalFooter>
            </Modal>



        </>
    );
}
