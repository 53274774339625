import React, { useState, useEffect } from 'react';
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DropzoneArea } from 'material-ui-dropzone'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import "./pickup.css"
import { Player } from 'video-react';
import "../complete/complete.css"
import TimeConverter from '../timeconverter/TimeConverter';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';






export default function Pickup(props) {
    console.log("superadmin role? picked", props.role)
    const Role = props.role
    const [GetReservations, setGetReservations] = useState([]);
    const [id, setId] = useState("");

    const [Reservationdet, setReservationdet] = useState([]);
    console.log("Reservationdet==>", Reservationdet)

    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);

    const navigate = useNavigate();
    const [key, setKey] = useState('Pickup Images');


    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [Description, setDescription] = useState("");
    const [Servicename, setServicename] = useState("");
    const [fileList, setFileList] = useState([]);

    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);

    // Edit modal 
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);
    const [keyspot, setKeyspot] = useState('');








    useEffect(() => {
        GetServices()
        // ReservationDetails()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")


    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=picked`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=picked`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                        // setId(result.data.id)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }




    const ReservationDetails = (id) => {
        if (Role == "superadmin") {
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=superadmin&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setReservationdet(result.data);

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {

            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/rervationDetails?role=subadmin&reservationid=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setReservationdet(result.data.carimages);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }

    // EDIT KEYSPOT 

    const EditKeyspot = () => {




        if (Role == "superadmin") {
            setLoader(true)

            const form = new FormData()
            form.append("keyspot", keyspot);
            form.append("id", id);



            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/keyspotupdate?role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {


                    if (result.status) {
                        toggle2()
                        GetServices()
                        setError(true)
                        setMessage(result.message)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/')
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {

            setLoader(true)

            const form = new FormData()
            form.append("keyspot", keyspot);
            form.append("id", id);


            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/keyspotupdate?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {


                    if (result.status) {
                        toggle2()
                        GetServices()
                        setError(true)
                        setMessage(result.message)

                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate('/ManagerLogin')
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }



    }


    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ? <AdminHeader /> : <ManagerHeader />}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Pickup</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Customer ', field: 'name', filtering: false },
                                                        { title: 'Service ', field: 'servicename', filtering: false },
                                                        { title: 'Reservation No ', field: 'slipno', filtering: false },
                                                        { title: 'Departure_Date', field: 'departure_airline' },
                                                        { title: 'Return_Date', field: 'return_airline' },
                                                        { title: 'Start', field: 'departure_airline_time', filtering: false },
                                                        { title: 'End', field: 'return_airline_time', filtering: false },

                                                        { title: 'Pick ', field: 'pickridername', render: rowData => rowData.pickridername == null ? "-" : rowData.pickridername, filtering: false },
                                                        { title: 'Drop ', field: 'dropridername', render: rowData => rowData.dropridername == null ? "-" : rowData.dropridername, filtering: false },
                                                        { title: 'Keyspot ', field: 'keySpot', render: rowData => rowData.keySpot == "" ? "-" : rowData.keySpot, filtering: false },
                                                        { title: ' Plate No ', field: 'licensePlate', filtering: false },
                                                        { title: 'FlightNumber ', field: 'flighNumber', filtering: false },

                                                        { title: '  Make ', field: 'vehicleMake', filtering: false },
                                                        { title: '  Color ', field: 'vehicleColor', filtering: false },

                                                        { title: 'Contact', field: 'phone', filtering: false },

                                                        { title: 'Total ', field: 'totalamout', render: rowData => "$" + rowData.totalamout.toFixed(2), filtering: false },
                                                        { title: 'Status', field: 'status', filtering: false },

                                                        // {
                                                        //     title: 'Ride Assign', render: (rowData) =>

                                                        //         <>
                                                        //             {rowData.riderdrop == null ?

                                                        //                 <Button type='button' onClick={() => { GetRiders(rowData); setReservationid(rowData.id) }} style={{ backgroundColor: "#03bafe", color: "white" }}  >Assign </Button> :

                                                        //                 rowData.rider != null ?
                                                        //                     <button type='button' style={{ backgroundColor: "green", color: "white", width: "85px", height: "42px", borderRadius: "7px" }}  > Already Assign</button> :



                                                        //                     ""






                                                        //             }


                                                        //         </>
                                                        // }


                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        // {
                                                        //     icon: RemoveRedEyeIcon,
                                                        //     tooltip: 'Pending Icon',
                                                        //     onClick: (event, rowData) => {
                                                        //         toggle()
                                                        //         console.log("rowdata=> 1", rowData)
                                                        //         // setId(rowData.id)
                                                        //         ReservationDetails(rowData.id)


                                                        //     }
                                                        // },
                                                        rowData => ({

                                                            icon: Edit,
                                                            tooltip: 'Edit Icon',
                                                            onClick: (event, rowData) => {
                                                                setKeyspot(rowData.keySpot)
                                                                setId(rowData.id)
                                                                console.log("edit icon", rowData.keySpot)
                                                                toggle2()



                                                            }
                                                        })

                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize: 10,
                                                        filtering: true

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ? <AdminFooter /> : <ManagerFooter />}


            {/* //Add MOdal  */}
            <Modal className='text-center' isOpen={modal} size="lg" >
                <ModalHeader  >
                    <modalTitle>  Car Pickup Details</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Pickup Images" title="Pickup Images">
                            <section className="gallery">

                                {Reservationdet?.carimages?.map((v, i) => {
                                    if (v.imgtype == "picked") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="galler" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Pickup Video" title="Pickup Video">


                            {Reservationdet?.carvideos?.map((v, i) => {

                                if (v.imgtype == "picked") {
                                    return <><h3>Car video at the time of pickup</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }




                            })}




                        </Tab>

                    </Tabs>

                </ModalBody>
                <ModalFooter>

                    <Button variant="primary" onClick={() => toggle()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


            {/* Edit keyspot  */}
            <Modal className='text-center' isOpen={modal2} size="md" >
                <ModalHeader >
                    <modalTitle>  Edit Keyspot</modalTitle>
                    <i onClick={toggle2} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <form>
                        <div className="form-group">
                            <label className="label25 text-left">Keyspot*</label>
                            <input value={keyspot} onChange={(e) => setKeyspot(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Keyspot Name" />
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>


                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle2()} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => EditKeyspot()} className="col-2 main-btn" >Update</button>
                        </div>
                    </form>


                </ModalBody>

            </Modal>




        </>
    );
}
