import logo from './logo.svg';
import 'react-overlay-loader/styles.css';
import './App.css';
import 'video-react/dist/video-react.css';
import { lazy, Suspense } from "react"
import { Loader } from "react-overlay-loader";

// import "node_modules/video-react/dist/video-react.css"
import {
  Router,
  Routes,
  Route,
  Link,
  BrowserRouter
} from 'react-router-dom';
// import Login from './pages/Admin/auth/Login';
import RiderLogin from './pages/Rider/auth/Login';
// import ForgetPassword from './pages/Admin/auth/ForgetPassword';
import RiderForgetPassword from './pages/Rider/auth/ForgetPassword';
import RiderProfile from './pages/Rider/profile/Profile';
import Schedule from "./pages/Admin/schedule/Schedule"
import ManagerLogin from "./pages/Manager/auth/ManagerLogin"
import ManagerProfile from "./pages/Manager/profile/ManagerProfile"
import ManagerSchedule from './pages/Manager/Schedule/ManagerSchedule';
import ManagForgetPass from './pages/Manager/auth/ManagForgetPass';
import ManagNewPassword from './pages/Manager/auth/ManagNewPassword';
import ManagVerificationCode from './pages/Manager/auth/ManagVerifcationCode';
import SendNotification from './pages/Admin/sendnotification/SendNotification';
import RiderReservation from './pages/Rider/reservation/RiderReservation';
import { ToastContainer } from 'react-toastify';
import Tracking from "./pages/Admin/tracking/Tracking"
import ManagerCategory from './pages/Manager/category/ManagerCategory';
import ManagerServices from './pages/Manager/services/ManagerServices';
import Pending from './pages/Manager/reservation/Pending';
import AcceptReserve from './pages/Manager/reservation/Accept';
import ManagerTracking from "./pages/Manager/Tracking/Trackingcar"
import ManagerComplete from './pages/Manager/complete/ManagerComplete';
import ManagerPickup from './pages/Manager/pickup/ManagerPickup';
import RiderComplete from './pages/Rider/complete/RiderComplete';
import ManagerRider from "../src/pages/Manager/riderregistration/RiderRegistrattion";
import UserAdmin from './pages/Admin/user/UserAdmin';
import UserManager from "./pages/Manager/user/UserManager"
import AdminRejection from './pages/Admin/rejection/AdminRejection';
import ManagerRejection from './pages/Manager/rejection/ManagerRejection';
// import RiderSchedule from './pages/Rider/schedule/Schedule';
import Info from "./components/modal/Info";
import EmployeeAdmin from './pages/Admin/employee/EmployeeAdmin';
import EmployeeManager from './pages/Manager/employee/EmployeeManager';
import AllReservation from './components/tables/MultiTable';
import AdminBooking from './pages/Admin/adminbooking/AdminBooking';
import ManagerBooking from './pages/Manager/booking/ManagerBooking';
// import Trackcar from './pages/Admin/trackcar/Trackcar';

// import Chartjs from './pages/Admin/adminDashboard/Chartjs';

// import Managerchart from './pages/Manager/ManagerDashboard/Chartjs';

const Chartjs = lazy(() => import('./pages/Admin/adminDashboard/Chartjs'))

const Managerchart = lazy(() => import('./pages/Manager/ManagerDashboard/Chartjs'))

// import AdminPickup from "./pages/Admin/pickup/AdminPickup";

const AdminPickup = lazy(() => import('./pages/Admin/pickup/AdminPickup'))
const ForgetPassword = lazy(() => import('./pages/Admin/auth/ForgetPassword'))
// import Pricing from "./pages/Admin/pricing/Pricing"
const Pricing = lazy(() => import('./pages/Admin/pricing/Pricing'))
// import NewPassword from './pages/Admin/auth/NewPassword';
const NewPassword = lazy(() => import('./pages/Admin/auth/NewPassword'))

// import VerificationCode from './pages/Admin/auth/VerificationCode';
const VerificationCode = lazy(() => import('./pages/Admin/auth/VerificationCode'))

// import RiderServices from './pages/Rider/servicemanagment/Services';
// import Profile from './pages/Admin/profile/Profile';
const Profile = lazy(() => import('./pages/Admin/profile/Profile'))
// import AdminComplete from './pages/Admin/complete/AdminComplete';
const AdminComplete = lazy(() => import('./pages/Admin/complete/AdminComplete'))
const Login = lazy(() => import('./pages/Admin/auth/Login'))
const Manager = lazy(() => import('./pages/Admin/manager/Manager'));
// import EmployeeManagment from './pages/Admin/employeemanagment/EmployeeManagment';
const EmployeeManagment = lazy(() => import('./pages/Admin/employeemanagment/EmployeeManagment'))

const PendingReservations = lazy(() => import('./pages/Admin/pendingreservation/PendingReservations'))

// import RiderRegistration from './pages/Admin/riderRegistration/RiderRegistration';
const RiderRegistration = lazy(() => import('./pages/Admin/riderRegistration/RiderRegistration'))

// import Trackcar from './pages/Admin/trackcar/Trackcar';
const Trackcar = lazy(() => import('./pages/Admin/trackcar/Trackcar'))

// import Practice from './pages/Admin/trackcar/practice';
const Practice = lazy(() => import('./pages/Admin/trackcar/practice'))

// import Categories from './pages/Admin/categories/Categories';
// import PendingReservations from './pages/Admin/pendingreservation/PendingReservations';
const Categories = lazy(() => import('./pages/Admin/categories/Categories'))

// import AcceptReservation from './pages/Admin/acceptreservation/AcceptReservation';
const AcceptReservation = lazy(() => import('./pages/Admin/acceptreservation/AcceptReservation'))
const NewReservations = lazy(() => import('./pages/Admin/newreservation/NewReservations'))
const Return = lazy(() => import('./pages/Admin/return/Return'))
const UpcominReservation = lazy(() => import('./pages/Admin/upcommingreservations/UpcomingReservations'))
const TodayReturn = lazy(() => import('./pages/Admin/todayreturn/TodayReturn'))
const ActiveReservations = lazy(() => import('./pages/Admin/activereservations/ActiveReservations'))
const RepeatReservations = lazy(() => import('./pages/Admin/repeatreservations/RepeatReservations'))

const Services = lazy(() => import('./pages/Admin/servicemanagment/Services'));

const ActiveReserve = lazy(() => import('./pages/Manager/activereservation/Active'))
const ReturnReserve = lazy(() => import('./pages/Manager/return/ReturnReserve'))
const NewReserve = lazy(() => import('./pages/Manager/newreservation/newReserve'))
const UpcomingReserve = lazy(() => import('./pages/Manager/upcomingreturn/UpcomingReserve'))
const RepeatReserve = lazy(() => import('./pages/Manager/repeatreservation/RepeatReserve'))
const TodayReserve = lazy(() => import('./pages/Manager/todayreturn/TodayReserve'))
const SpecificData = lazy(() => import('./components/specificdata/SpecificData'))
const AdminCardDetails = lazy(() => import('./pages/Admin/card/AdminCardDetails'))
const ManagerCardDetails = lazy(() => import('./pages/Manager/card/ManagerCardDetails'))
const RiderBooking = lazy(() => import('./pages/Rider/booking/RiderBooking'))
const PaymentAdmin = lazy(() => import('./pages/Admin/pay/Paymentadmin'))
const ManagerPayment = lazy(() => import('./pages/Manager/payment/ManagerPayment'))
const AdminCarwash = lazy(() => import('./pages/Admin/carwash/AdminCarwash'))
const ManagerCarwash = lazy(() => import('./pages/Manager/carwash/ManagerCarwash'))
const AdminParked = lazy(() => import('./pages/Admin/parked/AdminParked'))
const ManagerParked = lazy(() => import('./pages/Manager/parked/ManagerParked'))
const AdminPromo = lazy(() => import('./pages/Admin/adminpromo/AdminPromo'))
const ManagerPromo = lazy(() => import('./pages/Manager/managerpromo/ManagerPromo'))








function App() {
  return (
    <Suspense fallback={<Loader fullPage loading />}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/rider/login" element={<RiderLogin />} />
          <Route path="ForgetPassword" element={<ForgetPassword />} />
          <Route path="rider/ForgetPassword" element={<RiderForgetPassword />} />
          <Route path="NewPassword" element={<NewPassword />} />
          <Route path="VerificationCode" element={<VerificationCode />} />
          <Route path="Services" element={<Services />} />
          {/* <Route path="rider/Services" element={<RiderServices />} /> */}
          <Route path="categories" element={<Categories />} />
          <Route path="Manager" element={<Manager />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="rider/Profile" element={<RiderProfile />} />
          <Route path="Schedule" element={<Schedule />} />
          <Route path="Pricing" element={<Pricing />} />
          <Route path="ManagerLogin" element={<ManagerLogin />} />
          <Route path="ManagerProfile" element={<ManagerProfile />} />
          <Route path="ManagerSchedule" element={<ManagerSchedule />} />
          <Route path="ManagForgetPass" element={<ManagForgetPass />} />
          <Route path="ManagNewPassword" element={<ManagNewPassword />} />
          <Route path="ManagVerificationCode" element={<ManagVerificationCode />} />
          <Route path="SendNotification" element={<SendNotification />} />
          <Route path="EmployeeManagment" element={<EmployeeManagment />} />
          <Route path="RiderRegistration" element={<RiderRegistration />} />
          <Route path="Trackcar" element={<Trackcar />} />
          <Route path="Practice" element={<Practice />} />
          <Route path="PendingReservations" element={<PendingReservations />} />
          <Route path="AcceptReservation" element={<AcceptReservation />} />
          <Route path="RiderReservation" element={<RiderReservation />} />
          <Route path="Tracking" element={<Tracking />} />
          <Route path="ManagerCategory" element={<ManagerCategory />} />
          <Route path="ManagerServices" element={<ManagerServices />} />
          <Route path="Pending" element={<Pending />} />
          <Route path="AcceptReserve" element={<AcceptReserve />} />
          <Route path="ManagerTracking" element={<ManagerTracking />} />
          <Route path="AdminPickup" element={<AdminPickup />} />
          <Route path="AdminComplete" element={<AdminComplete />} />
          <Route path="ManagerComplete" element={<ManagerComplete />} />
          <Route path="ManagerPickup" element={<ManagerPickup />} />
          <Route path="RiderComplete" element={<RiderComplete />} />
          <Route path="ManagerRider" element={<ManagerRider />} />
          <Route path="UserAdmin" element={<UserAdmin />} />
          <Route path="UserManager" element={<UserManager />} />
          <Route path="AdminRejection" element={<AdminRejection />} />
          <Route path="ManagerRejection" element={<ManagerRejection />} />
          {/* <Route path="RiderSchedule" element={<RiderSchedule />} /> */}
          <Route path="Info" element={<Info />} />
          <Route path="EmployeeAdmin" element={<EmployeeAdmin />} />
          <Route path="EmployeeManager" element={<EmployeeManager />} />
          <Route path="AllReservation" element={<AllReservation />} />
          <Route path="/dashboard" element={<Chartjs />} />
          <Route path="/Managerdashboard" element={<Managerchart />} />
          <Route path="/AdminBooking" element={<AdminBooking />} />
          <Route path="/ManagerBooking" element={<ManagerBooking />} />
          <Route path="/NewReservations" element={<NewReservations />} />
          <Route path="/Return" element={<Return />} />
          <Route path="/UpcominReservation" element={<UpcominReservation />} />
          <Route path="/TodayReturn" element={<TodayReturn />} />
          <Route path="/ActiveReservations" element={<ActiveReservations />} />
          <Route path="/ActiveReserve" element={<ActiveReserve />} />
          <Route path="/ReturnReserve" element={<ReturnReserve />} />
          <Route path="/NewReserve" element={<NewReserve />} />
          <Route path="/UpcomingReserve" element={<UpcomingReserve />} />
          <Route path="/RepeatReserve" element={<RepeatReserve />} />
          <Route path="/TodayReserve" element={<TodayReserve />} />
          <Route path="/RepeatReservations" element={<RepeatReservations />} />
          <Route path="/SpecificData" element={<SpecificData />} />
          <Route path="/AdminCardDetails" element={<AdminCardDetails />} />
          <Route path="/ManagerCardDetails" element={<ManagerCardDetails />} />
          <Route path="/RiderBooking" element={<RiderBooking />} />
          <Route path="/PaymentAdmin" element={<PaymentAdmin />} />
          <Route path="/ManagerPayment" element={<ManagerPayment />} />
          <Route path="/AdminCarwash" element={<AdminCarwash />} />
          <Route path="/ManagerCarwash" element={<ManagerCarwash />} />
          <Route path="/AdminParked" element={<AdminParked />} />
          <Route path="/ManagerParked" element={<ManagerParked />} />
          <Route path="/ManagerPromo" element={<ManagerPromo />} />
          <Route path="/AdminPromo" element={<AdminPromo />} />


        </Routes>
      </BrowserRouter>
    </Suspense>

  );
}

export default App;
