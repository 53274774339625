import React,{useState,useEffect} from 'react';
import Pickup from "../../../components/pickup/Pickup"





export default function ManagerPickup() {
  
 



    return (
        <>
      

           
          <Pickup role={"subadmin"}/>
           

         
           

        </>
    );
}
