import React, { useState, useEffect } from 'react';
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import RiderHeader from "../../pages/Rider/layout/RiderHeader"
import RiderFooter from "../../pages/Rider/layout/RiderFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Loader } from "react-overlay-loader"
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Player } from 'video-react';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';
import TimeConverter from '../timeconverter/TimeConverter';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';






export default function EmployeeManagment(props) {

    const Role = props.role
    const [GetReservations, setGetReservations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [key, setKey] = useState('Pickup Images');

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [Description, setDescription] = useState("");
    const [Servicename, setServicename] = useState("");

    // Edit

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [id, setId] = useState("");
    const [Reservationdet, setReservationdet] = useState([]);
    const [videos, setVideos] = useState([]);
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);
    const [check, setCheck] = useState(false);









    useEffect(() => {
        GetServices()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")
    const RiderToken = localStorage.getItem("RiderToken")



    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/attendence?role=superadmin&action=all`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get employee", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/attendence?role=subadmin&action=all`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get reservations", result.data);
                        setGetReservations(result.data);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }


    }

    // rESERVATIION API 
    const handleChangeStatus = (a) => {
        if (Role == "superadmin") {
            if (a) {
                var requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                };
                fetch(`${baseUrl.baseUrl}webapi/riderActive_Deactivate?role=superadmin&id=${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {
                            setCheck(false)
                            setError(true)
                            setMessage(result.message)
                            setError1(false)
                            GetServices()

                        }
                        else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }

        }
        else {

            if (a) {
                var requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                };
                fetch(`${baseUrl.baseUrl}webapi/riderActive_Deactivate?role=subadmin&id=${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {
                            setCheck(false)
                            setError(true)
                            setMessage(result.message)
                            setError1(false)
                            GetServices()

                        }
                        else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }
        }



    }

    const Reserve = () => {

        setError1(true)
        setCheck(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to change Employee status?")
            .then((result) => {

                handleChangeStatus(result.isConfirmed)



            })
    }







    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} reserve={handleChangeStatus} checker={check} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            {Role == "superadmin" ?
                <AdminHeader /> :
                Role == "subadmin" ?
                    <ManagerHeader /> :
                    Role == "rider" ?
                        <RiderHeader /> : ""
            }
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Employee Management</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        {
                                                            title: 'Image',
                                                            field: 'image',
                                                            render: rowData => (
                                                                <img
                                                                    style={{ height: 36, borderRadius: '50%' }}
                                                                    src={rowData.image}
                                                                />
                                                            ),
                                                        },
                                                        { title: 'First Name', field: 'firstname' },
                                                        { title: 'Last Name', field: 'lastname' },
                                                        {
                                                            title: 'Date',
                                                            field: 'timein',

                                                            render: rowData => (
                                                                rowData.timein.split("T")[0]


                                                            )
                                                        },

                                                        {
                                                            title: 'Time In ',
                                                            field: 'timein',
                                                            render: rowData => (
                                                                TimeConverter(rowData.timein.split("T")[1])


                                                            )
                                                        },
                                                        {
                                                            title: 'Time Out',
                                                            field: 'timeout',
                                                            render: rowData => (
                                                                rowData.timeout != null ? TimeConverter(rowData.timeout.split("T")[1]) : "-"





                                                            )

                                                        },
                                                        //     { title: 'Manage', field: 'lastname',
                                                        // render:(rowData)=><>
                                                        //  <Link to="/"   > Blocked</Link>
                                                        // </>

                                                        // },




                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        rowData => ({
                                                            hidden: rowData.status != "Active",

                                                            icon: DoneIcon,
                                                            tooltip: 'Pending Icon',
                                                            onClick: (event, rowData) => {
                                                                console.log("rowdata id", rowData)
                                                                setId(rowData.rider)

                                                                Reserve()


                                                            }
                                                        }),
                                                        rowData => ({
                                                            hidden: rowData.status != "Disable",

                                                            icon: CloseIcon,
                                                            tooltip: 'Change Status',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.rider)
                                                                Reserve()
                                                            }
                                                        }),


                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize: 10,


                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role == "superadmin" ?
                <AdminFooter /> :
                Role == "subadmin" ?

                    <ManagerFooter /> :
                    Role == "rider" ?
                        <RiderFooter /> : ""
            }



            {/* //Add MOdal  */}
            <Modal className='text-center' isOpen={modal} size="lg" >
                <ModalHeader >
                    <modalTitle>  Car Complete Details</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Pickup Images" title="Pickup Images">
                            <section className="gallery">

                                {Reservationdet.map((v, i) => {
                                    if (v.imgtype == "picked") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="galler" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Complete Images" title="Complete Images">
                            <section className="gallery">

                                {Reservationdet.map((v, i) => {
                                    if (v.imgtype == "complete") {
                                        return <div className="gallery__item">
                                            <input type="radio" id={v.id} defaultChecked name="gallery" className="gallery__selector" />
                                            <img className="gallery__img" src={v.img} alt="" width={500} />
                                            <label htmlFor={v.id} className="gallery__thumb"><img src={v.img} alt="" width={500} /></label>
                                        </div>

                                    }


                                })}


                            </section>

                        </Tab>
                        <Tab eventKey="Pickup Videos" title="Pickup Videos" >
                            {videos.map((v, i) => {
                                if (v.imgtype == "picked") {
                                    return <><h3>Car video at the time of pickup</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }


                            })}

                        </Tab>
                        <Tab eventKey="Complete Videos" title="Complete Videos" >
                            {videos.map((v, i) => {
                                if (v.imgtype == "picked") {
                                    return <><h3>Car video at the time of complete</h3>   <Player>
                                        <source src={v.img} />
                                    </Player></>
                                }



                            })}


                        </Tab>
                    </Tabs>

                </ModalBody>
                <ModalFooter>

                    <Button variant="primary" onClick={() => toggle()}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>






        </>
    );
}
