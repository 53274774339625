const S3_BUCKET = 'parkingdatabucket2';
const REGION = 'us-east-1';

//  const Config = {
//     bucketName: S3_BUCKET,
//     dirName: 'videos', /* optional */
//     region: REGION,
//     accessKeyId: 'AKIAQEWRBWEFY3UG4LZG',
//     secretAccessKey: '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',

// }


const Config = {
    bucketName: S3_BUCKET,
    dirName: 'videos', /* optional */
    region: REGION,
    accessKeyId: 'AKIAT32JUTBQUXDKEODC',
    secretAccessKey: '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',

}
export default Config