import React, { useState, useEffect } from 'react';
import RiderHeader from "../layout/RiderHeader"
import RiderFooter from "../layout/RiderFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from "material-table";
import baseUrl from "../../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneIcon from '@mui/icons-material/Done';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import HailIcon from '@mui/icons-material/Hail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PinDropIcon from '@mui/icons-material/PinDrop';
// import React, { Component } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
// import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone'
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed';
import S3FileUpload, { deleteFile } from "react-s3"
import config from '../../../s3bucket/s3_config';
window.Buffer = window.Buffer || require("buffer").Buffer;




export default function RiderReservation() {
    const [GetReservations, setGetReservations] = useState([]);
    console.log("GetReservations==>", GetReservations.rider)
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [video, setvideo] = useState([]);


    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [reserveid, setReserveid] = useState("");
    const [Riderstatus, setRiderstatus] = useState("");

    // Edit

    const [modal1, setModal1] = useState(false);
    const [id, setId] = useState("")
    const [action, setAction] = useState("")
    const [reservationid, setReservationid] = useState("");
    const [riderid, setRiderid] = useState("");
    // hey spot 
    const [keyspot, setKeyspot] = useState('');
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);





    const toggle1 = () => {

        setReserveid(id)
        setRiderstatus(action)

        setModal1(!modal1)

    };

    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);

    const [check, setCheck] = useState(false);







    useEffect(() => {
        GetRiderReservation()

    }, []);
    const Token = localStorage.getItem("RiderToken")

    const GetRiderReservation = () => {
        setLoader(true);
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + Token
            }
        };
        fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=rider&action=picked`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                    console.log("Rider reservations", result.data);
                    setGetReservations(result.data);


                }
                else {
                    if (result.message == "Unauthorized") {
                        navigate("/")
                    } else {
                        setError2(true)
                        setMessage3(result.message)
                    }
                }
            })
            .catch(error => {
                setLoader(false);
                setError2(true)
                setMessage3(error)
            });
    }


    // S3 COADE 

    const uploadFile = (file) => {
        console.log("file====>", file)

        return new Promise((resolve, reject) => {
            let finalarry = new Array()
            for (var i = 0; i < file.length; i++) {


                console.log("uploading==>", file[i])




                S3FileUpload.uploadFile(file[i], config)
                    .then(data => {
                        console.log("done ==> ", data.location)
                        finalarry.push(data.location.toString())
                        news()
                    })
                    .catch(err => {
                        reject(err)
                        console.log("error ==> ", err)

                    })
            }

            const news = () => {
                if (file.length === finalarry.length) {
                    resolve(finalarry)

                }
            }

        })
        // console.log("uploading....",file[i])

    }

    // Edit api
    const EditReservation = () => {
        setLoading(true)


        // console.log("fileList", fileList)

        console.log("newlist", fileList)
        uploadFile([...fileList, ...video]).then((e) => {

            const form = new FormData()
            form.append("action", action);
            form.append("reservationid", id);
            console.log("finalarry", typeof (e), e)
            form.append("carimages", JSON.stringify(e));


            var requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=rider`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)

                    // setLoader(false);
                    console.log(result);
                    if (result.status) {
                        setModal1(false)
                        GetRiderReservation()

                        setError(true)
                        setMessage(result.message)
                        setError1(false)
                    }
                    else {

                        setError2(true)
                        setMessage3(result.message)
                    }
                })
                .catch(error => {

                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        })



    }



    // Edit api
    const EditReservation2 = (a) => {

        if (a) {

            if (action == "picked" || action == "complete") {
                toggle1()
            }
            else {






                setLoader(true)


                const form = new FormData()
                form.append("action", action);
                form.append("reservationid", id);
                form.append("keyspot", keyspot);


                for (var i = 0; i < fileList.length; i++) {


                    console.log("obj==>", fileList[i])

                    form.append("carimages", fileList[i]);
                }


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/riderReservation?role=rider`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {


                            setModal1(false)
                            GetRiderReservation()
                            setError(true)
                            setMessage(result.message)
                            setError1(false)
                        }
                        else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    })
                    .catch(error => {

                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }


        }

    }


    const Reserve = (check) => {
        setModal2(false)
        console.log("check", check)
        // toggle2()
        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to change vallet status?")

            .then((result) => {


                EditReservation2(result.isConfirmed)

            })
    }

    // dropzone 2 start
    const onDropzoneAreaChange = (files) => {
        setFileList(files);
        //console.log("Files:", files);
    };

    // dropzone 2 start
    const onDropzoneAreaChange2 = (files) => {
        setvideo(files);
        //console.log("Files:", files);
    };







    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={EditReservation2} checker={check} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}

            <RiderHeader />
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title"> Reservations</h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: ' Name', field: 'name' },
                                                        { title: 'Service Name', field: 'servicename' },
                                                        { title: 'License Plate ', field: 'licensePlate' },
                                                        { title: 'Depature ', field: 'departure_airline' },
                                                        { title: 'Return ', field: 'return_airline' },
                                                        { title: 'Start ', field: 'departure_airline_time' },
                                                        { title: 'End ', field: 'return_airline_time' },
                                                        { title: 'Total Amount', field: 'totalamout', render: rowData => "$" + rowData.totalamout.toFixed(2) },
                                                        { title: 'Status', field: 'status' },
                                                        //         { title: 'Ride Assign',render: (rowData) => 
                                                        //         <>
                                                        //          {rowData.rider==null?  <Button type='button' style={{backgroundColor:"#03bafe" ,color:"white" }}  >Assign </Button>: <Button type='button'  style={{backgroundColor:"green" ,color:"white" }}  > Booked</Button>}
                                                        //         </>

                                                        //  },

                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                        rowData => ({
                                                            hidden: rowData.status != "accept",
                                                            icon: AccessTimeIcon,
                                                            tooltip: 'Accept ',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)
                                                                setAction("picked")
                                                                Reserve()

                                                                console.log("rowdata=> Accept", rowData)

                                                            }
                                                        }),

                                                        // rowData => ({
                                                        //     hidden: rowData.status != "ontheway",

                                                        //     icon: TwoWheelerIcon,
                                                        //     tooltip: 'ontheway',
                                                        //     onClick: (event, rowData) => {
                                                        //         setId(rowData.id)
                                                        //         setAction("picked")
                                                        //         Reserve()

                                                        //         console.log("rowdata=> ontheway", rowData)

                                                        //     }
                                                        // }),

                                                        rowData => ({
                                                            hidden: rowData.status != "picked",

                                                            icon: HailIcon,
                                                            tooltip: 'picked',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)
                                                                setAction("parked")
                                                                toggle2()
                                                                // Reserve("true")



                                                                console.log("rowdata=> picked", rowData)

                                                            }
                                                        }),

                                                        // rowData => ({
                                                        //     hidden: rowData.status != "droped",

                                                        //     icon: PinDropIcon,
                                                        //     tooltip: 'droped',
                                                        //     onClick: (event, rowData) => {
                                                        //         setId(rowData.id)
                                                        //         setAction("parked")
                                                        //         Reserve()


                                                        //         console.log("rowdata=> droped", rowData)

                                                        //     }
                                                        // }),

                                                        rowData => ({
                                                            hidden: rowData.status != "parked",

                                                            icon: LocalParkingIcon,
                                                            tooltip: 'parked',
                                                            onClick: (event, rowData) => {
                                                                console.log("rowdata=> parked", rowData)
                                                                // setId(rowData.id)
                                                                // setAction("complete")
                                                                // Reserve()


                                                            }
                                                        }),

                                                        // rowData => ({
                                                        //     hidden: rowData.status != "release",

                                                        //     icon: LocalShippingIcon,
                                                        //     tooltip: 'release',
                                                        //     onClick: (event, rowData) => {
                                                        //         setId(rowData.id)
                                                        //         setAction("complete")
                                                        //         Reserve()


                                                        //         console.log("rowdata=> release", rowData)

                                                        //     }
                                                        // }),

                                                        rowData => ({
                                                            hidden: rowData.status != "complete",

                                                            icon: DoneOutlineIcon,
                                                            tooltip: 'complete',
                                                            onClick: (event, rowData) => {

                                                                console.log("rowdata=> complete", rowData)

                                                            }
                                                        })

                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        pageSize: 10,

                                                        // exportButton: true
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RiderFooter />

            {/* //Add MOdal  */}



            <Modal className='text-center' isOpen={modal1} size="lg" >
                <ModalHeader >
                    <modalTitle>Upload Your Car Images and Video </modalTitle>
                    <i onClick={toggle1} style={{ fontSize: "24px", marginLeft: "750px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <div>



                        <DropzoneArea
                            acceptedFiles={['image/jpeg,image/png']}

                            filesLimit={7}
                            maxFileSize={5242880} //5 MB
                            showFileNames={true}
                            dropzoneText={"Please upload your car pictures"}
                            onChange={onDropzoneAreaChange}
                        />

                        <DropzoneArea
                            acceptedFiles={['video/*']}
                            filesLimit={1}
                            maxFileSize={20971520} //20 MB
                            showFileNames={true}
                            dropzoneText={"Please Upload your car video"}
                            onChange={onDropzoneAreaChange2}
                        />

                    </div>

                </ModalBody>

                <div className='row'>
                    <div className='col-md-12 mb-3 rounded'>



                        <LoadingButton
                            onClick={() => EditReservation()}
                            endIcon={<SendIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            disabled={fileList.length === 0 && video.length === 0}
                            style={{ backgroundColor: "rgb(3, 186, 254)", color: "white" }}
                        >
                            Send
                        </LoadingButton>
                    </div>
                </div>
            </Modal>





            {/* Edit keyspot  */}
            <Modal className='text-center' isOpen={modal2} size="md" >
                <ModalHeader >
                    <modalTitle>  Add Keyspot</modalTitle>
                    <i onClick={toggle2} style={{ fontSize: "24px", marginLeft: "300px" }} className=" "></i>
                </ModalHeader>
                <ModalBody >
                    <form>
                        <div className="form-group">
                            <label className="label25 text-left">Keyspot*</label>
                            <input value={keyspot} onChange={(e) => setKeyspot(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Keyspot Name" />
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>


                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle2()} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => Reserve()} className="col-2 main-btn" >ok</button>
                        </div>
                    </form>


                </ModalBody>

            </Modal>

        </>
    );
}
