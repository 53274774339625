
export default function Footer() {

    return (
        <footer className="footer " style={{ backgroundColor: "#03bafe ", color: "white", marginTop: "60px" }}>
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12" style={{ padding: "15px" }}>

                        <p style={{ color: "white", fontSize: "large" }}>© All right reserved by vallet services 2023</p>

                    </div>

                </div>
            </div>
        </footer>
    )
}