import React, { useState, useEffect } from 'react';
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import ManagerHeader from "../../pages/Manager/layout/MangerHeader"
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Loader } from "react-overlay-loader"
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';





export default function MultiTable(props) {
    const [complete, setComplete] = useState([]);
    const [parked, setParked] = useState([]);
    const [picked, setPicked] = useState([]);
    const [todayreturn, setTodayreturn] = useState([]);
    const [upcoming, setUpcoming] = useState([]);
    const [returns, setReturns] = useState([]);
    const [carwash, setCarwash] = useState([]);
    const [parking, setParking] = useState([]);
    const [latestRes, setLatestRes] = useState([]);




    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    let location = useLocation();

    console.log("props role", props.role, carwash)

    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")
    console.log("ManagerToken", ManagerToken)
    console.log("Token", Token)



    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [Description, setDescription] = useState("");
    const [Servicename, setServicename] = useState("");
    const Role = props.role

    // Edit

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [id, setId] = useState("");
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);









    useEffect(() => {
        GetReservations()
        GetServices()


    }, []);



    const GetServices = () => {
        if (Role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=accept`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("Accept reservations", result.data);
                        setLatestRes(result.data);



                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=accept`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("Accept reservations", result.data);
                        setLatestRes(result.data);



                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }



    const GetReservations = () => {

        if (props.role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/dashboardReservation?role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get admin services ", result.data);
                        setComplete(result.data.complete);
                        setParked(result.data.parked);
                        setPicked(result.data.picked);
                        setCarwash(result.data.carwash);
                        setTodayreturn(result.data.todayreturn);
                        setUpcoming(result.data.upcommingreturn);



                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setModal(true)
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };

            fetch(`${baseUrl.baseUrl}webapi/dashboardReservation?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get manager services", result.data);
                        setComplete(result.data.complete);
                        setParked(result.data.parked);
                        setPicked(result.data.picked);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {

                            setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });

        }

    }




    return (
        <>

            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}
            {/* <AdminHeader />  */}

            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 col-lg-8">
                        <div className="section3125">
                            {/* <h4 className="item_title">Categories</h4> */}
                            {/* <button type="button"  className="main-btn float-right">Add Categories</button> */}
                            <div className="la5lo1">
                                <div className="row mt-3">

                                    <div className="col-md-12 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/AcceptReservation">New Reservation</Link></h3> :
                                            <h3> <Link to="/NewReserve">New Reservation</Link></h3>}
                                        {/* <h3> <Link to ="/Return">Parked</Link></h3> */}

                                        <MaterialTable

                                            title=" "
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },



                                            ]}
                                            data={latestRes}

                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true,
                                                // filtering: true
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/AdminPickup">Picked</Link></h3> :
                                            <h3> <Link to="/ManagerPickup">Picked</Link></h3>}

                                        <MaterialTable
                                            title=" "
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}


                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },



                                            ]}
                                            data={picked}
                                            // actions={[
                                            //     {
                                            //         icon: DeleteIcon,
                                            //         tooltip: 'Delete User',
                                            //         onClick: (event, rowData) => {


                                            //         }
                                            //     },
                                            //     {
                                            //         icon: Edit,
                                            //         tooltip: 'Edit User',
                                            //         onClick: (event, rowData) => {
                                            //             console.log("rowdata=>", rowData)


                                            //         }
                                            //     }
                                            // ]}
                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/AdminParked">Parked</Link></h3> :
                                            <h3> <Link to="/ReturnReserve">Parked</Link></h3>}
                                        {/* <h3> <Link to ="/Return">Parked</Link></h3> */}

                                        <MaterialTable

                                            title=" "
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },



                                            ]}
                                            data={parked}

                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/AdminComplete">Complete</Link></h3> :
                                            <h3> <Link to="/ManagerComplete">Complete</Link></h3>}
                                        {/* <h3> <Link to ="/AdminComplete">Complete</Link></h3> */}

                                        <MaterialTable
                                            title=""
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },



                                            ]}
                                            data={complete}

                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/TodayReturn">TodayReturn</Link></h3> :
                                            <h3> <Link to="/TodayReserve">TodayReturn</Link></h3>}
                                        {/* <h3> <Link to ="/AdminComplete">Complete</Link></h3> */}

                                        <MaterialTable
                                            title=""
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },



                                            ]}
                                            data={todayreturn}

                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>


                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/UpcominReservation">Upcoming Return</Link></h3> :
                                            <h3> <Link to="/UpcomingReserve">Upcoming Return</Link></h3>}
                                        <MaterialTable
                                            title=""
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },



                                            ]}
                                            data={upcoming}
                                            // actions={[
                                            //     {
                                            //         icon: DeleteIcon,
                                            //         tooltip: 'Delete User',
                                            //         onClick: (event, rowData) => {
                                            //             // setId(rowData.id)

                                            //         }
                                            //     },
                                            //     {
                                            //         icon: Edit,
                                            //         tooltip: 'Edit User',
                                            //         onClick: (event, rowData) => {
                                            //             console.log("rowdata=>", rowData)
                                            //             // toggle1()

                                            //         }
                                            //     }
                                            // ]}
                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/Return"> Return</Link></h3> :
                                            <h3> <Link to="/ReturnReserve"> Return</Link></h3>}

                                        <MaterialTable
                                            title=""
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },

                                            ]}
                                            data={complete}
                                            // actions={[
                                            //     {
                                            //         icon: DeleteIcon,
                                            //         tooltip: 'Delete User',
                                            //         onClick: (event, rowData) => {
                                            //             setId(rowData.id)

                                            //         }
                                            //     },
                                            //     {
                                            //         icon: Edit,
                                            //         tooltip: 'Edit User',
                                            //         onClick: (event, rowData) => {
                                            //             console.log("rowdata=>", rowData)
                                            //             toggle1()

                                            //         }
                                            //     }
                                            // ]}
                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/AdminCarWash">Car Wash </Link></h3> :
                                            <h3> <Link to="/ManagerCarwash">Car Wash </Link></h3>}
                                        <MaterialTable
                                            title=" "
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'firstname' },
                                                { title: 'Service', field: 'servicename' },
                                                { title: 'plate No', field: 'licensePlate' },
                                                { title: 'Return Date', field: 'returndate' },
                                                { title: 'Return Time', field: 'returntime' },



                                            ]}
                                            data={carwash}
                                            // actions={[
                                            //     {
                                            //         icon: DeleteIcon,
                                            //         tooltip: 'Delete User',
                                            //         onClick: (event, rowData) => {
                                            //             // setId(rowData.id)

                                            //         }
                                            //     },
                                            //     {
                                            //         icon: Edit,
                                            //         tooltip: 'Edit User',
                                            //         onClick: (event, rowData) => {
                                            //             console.log("rowdata=>", rowData)
                                            //             // toggle1()

                                            //         }
                                            //     }
                                            // ]}
                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 item">
                                        {props.role == "superadmin" ?
                                            <h3> <Link to="/AdminComplete">Parking Facility </Link></h3> :
                                            <h3> <Link to="/ManagerComplete"> Parking Facility</Link></h3>}

                                        <MaterialTable
                                            title=""
                                            icons={{
                                                Check: Check,
                                                DetailPanel: ChevronRight,
                                                // Delete: DeleteOutline,
                                                Export: SaveAlt,
                                                Filter: FilterList,
                                                FirstPage: FirstPage,
                                                LastPage: LastPage,
                                                NextPage: ChevronRight,
                                                PreviousPage: ChevronLeft,
                                                Search: Search,
                                                ThirdStateCheck: Remove,
                                                Add: Add,
                                                SortArrow: ArrowDownward,
                                                Clear: Clear,
                                                Edit: Edit,
                                                ViewColumn: ViewColumn,
                                                ResetSearch: Clear
                                            }}

                                            columns={[
                                                { title: ' Name', field: 'name' },
                                                { title: ' Email', field: 'email' },
                                                { title: ' Departure ', field: 'departure_airline' },
                                                { title: ' Return ', field: 'return_airline' },

                                            ]}
                                            data={complete}
                                            // actions={[
                                            //     {
                                            //         icon: DeleteIcon,
                                            //         tooltip: 'Delete User',
                                            //         onClick: (event, rowData) => {
                                            //             setId(rowData.id)

                                            //         }
                                            //     },
                                            //     {
                                            //         icon: Edit,
                                            //         tooltip: 'Edit User',
                                            //         onClick: (event, rowData) => {
                                            //             console.log("rowdata=>", rowData)
                                            //             toggle1()

                                            //         }
                                            //     }
                                            // ]}
                                            options={{
                                                headerStyle: {
                                                    backgroundColor: '#03bafe',
                                                    color: '#FFF'
                                                },
                                                actionsColumnIndex: -1,
                                                exportButton: true
                                            }}
                                        />
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <AdminFooter />  */}





        </>
    );
}
