import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AdminFooter from "../../pages/Admin/layout/AdminFooter";
import AdminHeader from "../../pages/Admin/layout/AdminHeader";
import ManagerHeader from '../../pages/Manager/layout/MangerHeader';
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { ArrowDownward } from "@material-ui/icons";
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from "@material-ui/icons/Clear";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Button, ImageList, ImageListItem, MenuItem, Select, TextField } from '@mui/material';
import MaterialTable from "material-table";
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import baseUrl from "../../baseurl/BaseUrl";
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './App.css'
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';



export default function Services(props) {
    console.log("props service", props)
    const Role = props.role
    const Status = props.status

    const [GetService, setGetService] = useState([]);
    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);

    const navigate = useNavigate();

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modaltitle, setmodaltitle] = useState('');
    const [Description, setDescription] = useState("");
    const [Servicename, setServicename] = useState("");
    const [price, setServiceprice] = useState("");
    const [perday, setPerday] = useState("");

    const dateObj = new Date()
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate
    if (month < 10) {

        if (day < 10) {

            newdate = year + "-" + 0 + month + "-" + 0 + day;

        } else {
            newdate = year + "-" + 0 + month + "-" + day;
        }

    } else {
        if (day < 10) {
            newdate = year + "-" + month + "-" + 0 + day;

        } else {
            newdate = year + "-" + month + "-" + day;
        }
    }
    console.log(month)
    const [availability_start, setAvailabliltyStart] = useState(newdate);
    const [availability_end, setAvailabliltyEnd] = useState(newdate);
    const [cid, setCid] = useState("");
    const [images, setimages] = useState([]);
    const [categories, setCategories] = useState([]);

    console.log("availability_start", availability_start)
    console.log("availability_end", availability_end)

    // Edit

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [id, setId] = useState("");

    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);



    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    useEffect(() => {
        GetServices()
    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")


    const GetServices = () => {
        if (Role == "adminservice") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("get services", result);
                        setGetService(result.services);
                        setCategories(result.category);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("get services", result);
                        setGetService(result.services);
                        setCategories(result.category);
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }

    const handleInputChange = (e, func) => {
        if (e.target.value == "") {
            setDisable(true);
        } else {
            setDisable(false);
        }
        func(e.target.value)
    }

    const addPackageModal = () => {
        setServicename("")
        setDescription("")
        setmodaltitle("Add Services");
        setModal(true);
    }

    const AddServices = () => {
        if (Role == "superadmin") {
            setLoader(true)
            if (Servicename == "" || price == "" || availability_start == "" || availability_end == "" || cid == "" || images == "") {

                setLoader(false)
                setError2(true)
                setMessage3("All keys are required ")

            }
            else {
                // toggle()
                const form = new FormData()
                form.append("servicename", Servicename)
                form.append("Description", convertedContent)
                form.append("cid", cid)
                form.append("images", images[0])
                form.append("price", price)



                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=superadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            toggle()
                            GetServices()
                            setError(true)
                            setMessage(result.message)
                            setServicename("")
                            setConvertedContent(null)
                            setServiceprice("")
                            setPerday("")
                            setCid("")
                            setimages("")

                        }

                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            } else {
                                setModal(true)
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }


                    })
                    .catch(error => {
                        setModal(true)
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }

        }
        else {
            setLoader(true)
            if (Servicename == "" || price == "" || availability_start == "" || availability_end == "" || cid == "" || images == "") {
                setLoader(false)
                setError2(true)
                setMessage3("All keys are required ")
            }
            else {
                // toggle()
                const form = new FormData()
                form.append("servicename", Servicename)
                form.append("Description", convertedContent)
                form.append("cid", cid)
                form.append("images", images[0])
                // form.append("availability_start", availability_start)
                // form.append("availability_end", availability_end)
                form.append("price", price)
                // form.append("availability", perday)


                var requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=subadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            setServicename("")
                            setConvertedContent(null)
                            setServiceprice("")
                            setPerday("")
                            setCid("")
                            setimages("")
                            GetServices()
                            toggle()
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            } else {
                                setModal(true)
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        setModal(true)
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }
        }

    }

    // Edit api
    const EditServices = () => {
        if (Role == "superadmin") {
            setLoader(true)
            convertContentToHTML()
            if (Servicename == "" || price == "" || availability_start == "" || availability_end == "" || cid == "" || images == "") {
                setLoader(false)
                setError2(true)
                setMessage3("All keys are required ")
            }
            else {
                const form = new FormData()
                form.append("serviceid", id);
                form.append("servicename", Servicename);
                form.append("Description", convertedContent);
                form.append("cid", cid);

                form.append("price", price);


                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=superadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            GetServices()
                            toggle1()
                            setError(true)
                            setMessage(result.message)
                        }

                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            }
                            else {
                                toggle1()
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }

                    })
                    .catch(error => {
                        toggle1()
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });

            }
        }
        else {

            setLoader(true)
            convertContentToHTML()
            if (Servicename == "" || price == "" || availability_start == "" || availability_end == "" || cid == "" || images == "") {
                setLoader(false)
                setError2(true)
                setMessage3("All keys are required ")
            }
            else {
                const form = new FormData()
                form.append("serviceid", id);
                form.append("servicename", Servicename);
                form.append("Description", convertedContent);
                form.append("cid", cid);
                // form.append("availability_start", availability_start);
                // form.append("availability_end", availability_end);
                form.append("price", price);
                // form.append("availability", perday)

                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=subadmin`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            GetServices()
                            toggle1()
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            }
                            else {
                                toggle1()
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        toggle1()
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });

            }
        }

    }
    const ConfirmDelete = (a) => {


        if (a) {
            if (Role == "superadmin") {
                setLoader(true)

                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    // body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=superadmin&serviceid=${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            GetServices()
                            setError1(false)
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            }
                            else {

                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }
            else {
                setLoader(true)

                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },

                };

                fetch(`${baseUrl.baseUrl}webapi/servicecreation?role=subadmin&serviceid=${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status && result.status != "False") {
                            GetServices()
                            setError1(false)
                            setError(true)
                            setMessage(result.message)
                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            }
                            else {

                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }

        }
    }

    // Delete Api

    const DeleteService = () => {
        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to delete this service?")
            .then((result) => {
                ConfirmDelete(result.isConfirmed)

            })
    }

    const handleDeleteImage = (id) => {
        if (Role == "superadmin") {
            const form = new FormData()
            form.append("role", 'superadmin');
            form.append("id", id);
            var requestOptions = {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + Token
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/serviceimage?role=superadmin&id=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status && result.status != "False") {
                        setError(true)
                        setMessage(result.message)
                        setError1(false)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        }
                        else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
        else {
            const form = new FormData()
            form.append("role", 'subadmin');
            form.append("id", id);
            var requestOptions = {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                },
                body: form,
            };
            // setLoader(true);
            fetch(`${baseUrl.baseUrl}webapi/serviceimage?role=subadmin&id=${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    console.log(result);
                    if (result.status && result.status != "False") {
                        setError(true)
                        setMessage(result.message)
                        setError1(false)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                            console.log("galat token")
                        }
                        else {

                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }

    }


    return (
        <>
            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={ConfirmDelete} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}
            {props.role == "superadmin" ? <AdminHeader /> : <ManagerHeader />}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Services Managment</h4>
                                    <button type="button" onClick={() => addPackageModal()} className="main-btn float-right">Add Services</button>
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Service Name', field: 'servicename' },
                                                        // { title: 'Description', field: 'Description', render: item => <> {item.Description.slice(0, 10)}......</> },
                                                        { title: 'Category Name', field: 'category_name', render: item => <> {item.category_name}</> },
                                                        { title: 'Price', field: 'price', render: item => <> {"$" + item.price}</> },
                                                        // { title: 'Avalibality', field: 'availability' },

                                                    ]}
                                                    data={GetService}
                                                    actions={[
                                                        {
                                                            icon: DeleteIcon,
                                                            tooltip: 'Delete User',
                                                            onClick: (event, rowData) => {
                                                                setId(rowData.id)
                                                                DeleteService()
                                                            }
                                                        },
                                                        {
                                                            icon: Edit,
                                                            tooltip: 'Edit User',
                                                            onClick: (event, rowData) => {
                                                                console.log("rowdata=>", rowData)
                                                                var dom = document.createElement('div');
                                                                dom.innerHTML = rowData.Description;
                                                                toggle1()
                                                                setServicename(rowData.servicename)
                                                                // setConvertedContent(rowData.Description.slice(1, -1))
                                                                // setEditorState(rowData.Description.slice(1, -1))
                                                                var myeditor1 = EditorState.createWithContent(
                                                                    ContentState.createFromBlockArray(convertFromHTML(rowData.Description))
                                                                );

                                                                setEditorState(myeditor1)
                                                                setCid(rowData.categoryid)
                                                                setServiceprice(rowData.price)
                                                                setimages(rowData.serviceimages)
                                                                setAvailabliltyStart(rowData.availability_start)
                                                                setAvailabliltyEnd(rowData.availability_end)
                                                                setId(rowData.id)
                                                                setPerday(rowData.availability)
                                                            }
                                                        }
                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        // maxBodyHeight: 400,
                                                        actionsColumnIndex: -1,
                                                        exportButton: true,
                                                        pageSize: 10,

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.role == "superadmin" ? <AdminFooter /> : <ManagerFooter />}


            {/* //Add MOdal  */}

            <Modal className='text-center' isOpen={modal} size="md" >
                <ModalHeader >
                    <modalTitle> {modaltitle}</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "310px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <input type="text" value={Servicename} onChange={(e) => { setServicename(e.target.value) }} className="form-control" placeholder='Service Name' />



                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">


                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        editorStyle={{
                                            border: "1px solid #F0F0F0",
                                            padding: 15,
                                            minHeight: 350,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <input type="text" value={price} onChange={(e) => { setServiceprice(e.target.value) }} className="form-control" placeholder='Price' />



                                </div>
                            </div>
                            {/* <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <input type="text" value={perday} onChange={(e) => { setPerday(e.target.value) }} className="form-control" placeholder='Per Day Avalibality' />

                                 

                                </div>
                            </div> */}
                            {/* <div className="col-md-12 col-sm-6" >
                                <div className="form-group">
                                <label style={{ marginBottom: "0px" }}>Select Start Date*</label>


                                    <input type="date" value={availability_start} onChange={(e) => { setAvailabliltyStart(e.target.value) }} className="form-control" placeholder='Availablity Start Date' />
                                </div>
                            </div> */}


                            {/* <div className="col-md-12 col-sm-6">
                                <div className="form-group">

                                <label style={{ marginBottom: "0px" }}>Select End Date*</label>

                                    <input type="date" value={availability_end} onChange={(e) => { setAvailabliltyEnd(e.target.value) }} className="form-control" placeholder='Availablity End Date' />
                                </div>
                            </div> */}




                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <label style={{ marginBottom: "0px" }}>Select Category*</label>
                                    {/* <InputLabel id="demo-simple-select-label">Select Category</InputLabel> */}


                                    <select value={cid}
                                        onChange={(e) => { console.log("e=>", setCid(e.target.value)) }}
                                        class="form-select" style={{ width: "100%", padding: "1.5rem" }}>


                                        <option selected>Select Category...</option>
                                        {categories.length > 0 && categories.map((item, index) => {
                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                        })}

                                    </select>

                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <Button variant="contained" component="label">
                                        Add attachment
                                        <input hidden accept="image/*" multiple type="file"

                                            onChange={(e) => {
                                                let files = e.target.files;
                                                console.log(files);
                                                let reader = new FileReader();
                                                reader.onload = r => {
                                                    console.log(r.target.result);
                                                    // setimages([...images, reader.result])
                                                };
                                                reader.readAsDataURL(files[0]);
                                                setimages([...images, files[0]])
                                            }
                                            }
                                        />
                                    </Button>
                                </div>
                            </div>
                            <ImageList sx={images.length < 3 ? { width: 500, } : images.length < 3 ? { width: 500, height: 160 } : { width: 500, height: 450 }} cols={3} rowHeight={150}>
                                {images.length > 0 && images.map((item) => (
                                    <ImageListItem key={item}>
                                        <DeleteIcon style={{ backgroundColor: "#fff", position: 'absolute', top: 5, right: 5 }}
                                            onClick={() => {
                                                if (item.id) {
                                                    setimages(images.filter(i => i.id !== item.id))
                                                    handleDeleteImage(item.id)
                                                } else {
                                                    setimages(images.filter(i => i.id !== item.id))
                                                }
                                            }}
                                            color='red' />
                                        <img
                                            src={item.id ? item.profile : URL.createObjectURL(item)}
                                            srcSet={`${item}`}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </div>
                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle()} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => AddServices()} className="col-2 main-btn">Add</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            {/* Edit Modal  */}

            <Modal className='text-center' isOpen={modal1} size="md" >
                <ModalHeader >
                    <modalTitle>Edit Services</modalTitle>
                    {/* <i onClick={toggle1} style={{ fontSize: "24px", marginLeft:"310px" }} className=" "></i> */}
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <input type="text" value={Servicename} onChange={(e) => { setServicename(e.target.value) }} className="form-control" placeholder='Service Name' />


                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">

                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={handleEditorChange}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                    />

                                </div>
                            </div>
                            {/* <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                  
                                    <input type="text" value={perday} onChange={(e) => { setPerday(e.target.value) }} className="form-control" placeholder='Per Day Avalibality' />

                                   
                                </div>
                            </div> */}
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <input type="text" value={price} onChange={(e) => { setServiceprice(e.target.value) }} className="form-control" placeholder='Price' />


                                </div>
                            </div>
                            {/* <div className="col-md-12 col-sm-6" style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <input type="date" value={availability_start} onChange={(e) => { setAvailabliltyStart(e.target.value) }} className="form-control" placeholder='Availablity Start Date' />

                                
                                <input type="date" value={availability_end} onChange={(e) => { setAvailabliltyEnd(e.target.value) }} className="form-control" placeholder='Availablity End Date' />
                        
                            </div> */}
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <label style={{ marginBottom: "0px" }}>Select Category*</label>

                                    {/* <InputLabel id="demo-simple-select-label">Select Category</InputLabel> */}

                                    <select value={cid}
                                        onChange={(e) => { console.log("e=>", setCid(e.target.value)) }}
                                        class="form-select" style={{ width: "100%", padding: "1.5rem" }}>


                                        <option selected>Select Category...</option>
                                        {categories.length > 0 && categories.map((item, index) => {
                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                        })}

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <Button variant="contained" component="label">
                                        Add attachment
                                        <input hidden accept="image/*" multiple type="file"
                                            onChange={(e) => {
                                                if (Role == "superadmin") {
                                                    let files = e.target.files;
                                                    console.log(files);
                                                    const formData = new FormData()
                                                    formData.append("serviceid", id)
                                                    formData.append("img", files[0])
                                                    var requestOptions = {
                                                        method: 'PUT',
                                                        headers: {
                                                            Authorization: "Bearer " + Token
                                                        },
                                                        body: formData,
                                                    };
                                                    fetch(`${baseUrl.baseUrl}webapi/serviceimage?role=superadmin`, requestOptions)
                                                        .then(response => response.json())
                                                        .then(result => {
                                                            console.log(result);

                                                        })
                                                        .catch(error => {
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Oops...',
                                                                text: error,
                                                                confirmButtonColor: "#03bafe",
                                                            })
                                                        });
                                                    let reader = new FileReader();
                                                    reader.onload = r => {
                                                        console.log(r.target.result);
                                                        // setimages([...images, reader.result])
                                                    };
                                                    reader.readAsDataURL(files[0]);
                                                    setimages([...images, files[0]])
                                                } else {
                                                    let files = e.target.files;
                                                    console.log("else check manager",files);
                                                    const formData = new FormData()
                                                    formData.append("serviceid", id)
                                                    formData.append("img", files[0])
                                                    var requestOptions = {
                                                        method: 'PUT',
                                                        headers: {
                                                            Authorization: "Bearer " + ManagerToken
                                                        },
                                                        body: formData,
                                                    };
                                                    fetch(`${baseUrl.baseUrl}webapi/serviceimage?role=subadmin`, requestOptions)
                                                        .then(response => response.json())
                                                        .then(result => {
                                                            console.log(result);

                                                        })
                                                        .catch(error => {
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Oops...',
                                                                text: error,
                                                                confirmButtonColor: "#03bafe",
                                                            })
                                                        });
                                                    let reader = new FileReader();
                                                    reader.onload = r => {
                                                        console.log(r.target.result);
                                                        // setimages([...images, reader.result])
                                                    };
                                                    reader.readAsDataURL(files[0]);
                                                    setimages([...images, files[0]])

                                                }

                                            }}
                                        />
                                    </Button>
                                </div>
                            </div>
                            <ImageList sx={images.length < 3 ? { width: 500, height: 160 } : { width: 500, height: 450 }} cols={3} rowHeight={150}>
                                {images.length > 0 && images.map((item) => (
                                    <ImageListItem key={item}>
                                        <DeleteIcon style={{ backgroundColor: "#fff", position: 'absolute', top: 5, right: 5 }}
                                            onClick={() => {
                                                if (item.id) {
                                                    setimages(images.filter(i => i.id !== item.id))
                                                    handleDeleteImage(item.id)
                                                } else {
                                                    setimages(images.filter(i => i.id !== item.id))
                                                }
                                            }}
                                            color='red' />
                                        <img
                                            src={item.id ? item.profile : URL.createObjectURL(item)}
                                            srcSet={`${item}`}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </div>
                        <div className="row text-center justify-content-end">
                            <button type="button" onClick={() => toggle1()} className="col-2 main-btn cancel mx-3">Close</button>
                            <button type="button" onClick={() => EditServices()} className="col-2 main-btn">Update</button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

        </>
    );
}
