import React,{useState,useEffect} from 'react';
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import ManagerHeader from "../../pages/Manager/layout/MangerHeader"
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MaterialTable from "material-table";
import baseUrl from "../../baseurl/BaseUrl";
import Swal from "sweetalert2";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneIcon from '@mui/icons-material/Done';
import { Loader } from "react-overlay-loader"
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimeConverter from '../timeconverter/TimeConverter';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';





export default function Rejection(props) {
    const [GetReservations, setGetReservations] = useState([]);
    console.log("GetReservations==>",GetReservations.rider)

    const Role = props.role
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () =>{

        setModal(!modal);
    }
    
    const [Description, setDescription] = useState("");
    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);






   

  
   

  




    useEffect(() => {
        GetData()

    }, []);
    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")

    


    const GetData = () => {
        if(Role=="superadmin"){
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=superadmin&status=reject`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("user Data", result.data);
                        setGetReservations(result.data);
                        setDescription(result.data.rejectreason)
                       
    
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
              
        }
        else{

            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/adminReservation?role=subadmin&status=reject`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("userDATA MANAGER", result);
                        setGetReservations(result.data);
                      
    
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });
        }
      
    }



    return (
        <>
         <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1}  />
            <Failed status={error2} message={message3} func={handleCloseB2} />
        {loader == true ? <Loader fullPage loading /> : null}

       {Role=="superadmin"?  <AdminHeader />:  <ManagerHeader />}    
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Rejection </h4>
                                    {/* <button type="button" onClick={()=>addPackageModal()} className="main-btn float-right">Add Categories</button> */}
                                    <div className="la5lo1">
                                        <div className="row">
                                            <div className="col-md-12 item">
                                                <MaterialTable
                                                    title=""
                                                    icons={{
                                                        Check: Check,
                                                        DetailPanel: ChevronRight,
                                                        // Delete: DeleteOutline,
                                                        Export: SaveAlt,
                                                        Filter: FilterList,
                                                        FirstPage: FirstPage,
                                                        LastPage: LastPage,
                                                        NextPage: ChevronRight,
                                                        PreviousPage: ChevronLeft,
                                                        Search: Search,
                                                        ThirdStateCheck: Remove,
                                                        Add: Add,
                                                        SortArrow: ArrowDownward,
                                                        Clear: Clear,
                                                        Edit: Edit,
                                                        ViewColumn: ViewColumn,
                                                        ResetSearch: Clear
                                                    }}

                                                    columns={[
                                                        { title: 'Customer ', field: 'customername' },
                                                        { title: 'Service', field: 'servicename' },
                                                        { title: 'Departure Date ', field: 'departure_airline' },
                                                        { title: 'Return Date', field: 'return_airline' },
                                                        { title: 'Start', field: 'starttime' ,render:rowData=>TimeConverter(rowData.starttime)},
                                                        { title: 'End', field: 'endtime' ,render:rowData=>TimeConverter(rowData.endtime) },
                                                        { title: 'Amount', field: 'totalamout',render:rowData=>"$"+rowData.totalamout.toFixed(2) },

                                                        
                                                        
                                                    
                                                 

                                                       

                                                       
                                                    ]}
                                                    data={GetReservations}
                                                    actions={[
                                                      rowData=> ( {
                                                          
                                                            icon: RemoveRedEyeIcon ,
                                                            tooltip: ' view Rejection Reason ',
                                                            onClick: (event, rowData) => {
                                                                setDescription(rowData.rejectreason)
                                                                toggle()
                                                              
                                                                console.log("rowdata=> 1",rowData)
                                                               
                                                            }
                                                        }),

                                   
                                                    ]}
                                                    options={{
                                                        headerStyle: {
                                                            backgroundColor: '#03bafe',
                                                            color: '#FFF'
                                                        },
                                                        actionsColumnIndex: -1,
                                                        // exportButton: true
                                                        pageSize:10,

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Role=="superadmin"?  <AdminFooter />:  <ManagerFooter />}    


     


            <Modal className='text-center' isOpen={modal} size="md" centered={true}>
                <ModalHeader >
                    <modalTitle className="">Rejection Reason</modalTitle>
                   
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row form-row">
                            <div className="col-md-12 ">
                                {/* <p for="w3review"> Reason</p> */}
                                <textarea value={Description}  className='form-control' id="w3review" name="w3review" rows="6" cols="50"></textarea>
                            </div>


                        </div>
                        <div className="row text-center justify-content-end mt-2">
                       

                            <button type="button" onClick={() => toggle()} className="col-2 main-btn ">Close</button>

                          
                            {/* <button type="button" onClick={() => Reserve(ids,actions)} className="col-2 main-btn" >Add</button> */}
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            
           

           

        </>
    );
}
