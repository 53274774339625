import React,{useState,useEffect} from 'react';
import Reservationstab from '../../../components/multireservations/Reservationstab';





export default function ManagerComplete() {
  
 



    return (
        <>
      

           
          <Reservationstab role={"subadmin"} status={"complete"}/>
           

         
           

        </>
    );
}
