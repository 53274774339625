import React, { useState, useEffect } from 'react';
import AdminFooter from "../../pages/Admin/layout/AdminFooter"
import AdminHeader from "../../pages/Admin/layout/AdminHeader"
import ManagerHeader from "../../pages/Manager/layout/MangerHeader"
import ManagerFooter from "../../pages/Manager/layout/ManagerFooter"
import RiderFooter from "../../pages/Rider/layout/RiderFooter"
import RiderHeader from "../../pages/Rider/layout/RiderHeader"
import { Button, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Checkbox, InputLabel, ListItemText, MenuItem, OutlinedInput, TextField } from '@mui/material';

import { Loader } from "react-overlay-loader"
import baseUrl from "../../baseurl/BaseUrl";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Error from '../modal/Modal';
import Info from '../modal/Info';
import Failed from '../modal/Failed';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import MaterialTable from "material-table";
import { ArrowDownward, Email } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import Select from 'react-select';
// import { colourOptions } from './data';
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import StripeCheckout from 'react-stripe-checkout';



export default function Booking(props) {
    const [category, setCategory] = useState([]);
    const [categorydata, setCategorydata] = useState("");
    const [Additional, setAdditional] = useState([]);

    console.log("categorydata", categorydata)
    const [services, setServices] = useState([]);

    const [loader, setLoader] = useState(false);
    const [Disable, setDisable] = useState(true);
    const [id, setId] = useState("");
    const [newid, setNewid] = useState("");

    const [code, setCode] = useState("+1");

    const [shiftForPost, setShiftForPost] = useState([]);
    console.log("shiftForPost", shiftForPost)



    let location = useLocation();

    const Role = props.role
    console.log("Role===>", Role)

    const Token = localStorage.getItem("AdminToken")
    const ManagerToken = localStorage.getItem("ManagerToken")
    const RiderToken = localStorage.getItem("RiderToken")

    // disble past date 
    const dateObj = new Date()
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate
    if (month < 10) {

        if (day < 10) {

            newdate = year + "-" + 0 + month + "-" + 0 + day;

        } else {
            newdate = year + "-" + 0 + month + "-" + day;
        }

    } else {
        if (day < 10) {
            newdate = year + "-" + month + "-" + 0 + day;

        } else {
            newdate = year + "-" + month + "-" + day;
        }
    }



    const navigate = useNavigate();




    // SUCCESS STATE
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const handleCloseB = () => setError(false);

    // INFO STATES 
    const [error1, setError1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [message2, setMessage2] = useState("");
    const handleCloseB1 = () => setError1(false);

    //   Failed STATES 

    const [error2, setError2] = useState(false);
    const [message3, setMessage3] = useState("");
    const handleCloseB2 = () => setError2(false);

    // Add MOdal
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [cardinfo, setCardInfo] = useState([]);
    console.log("cardinfo===>", cardinfo)


    const [active, setActive] = useState(false);
    const [active2, setActive2] = useState(false);

    console.log("active cardinfo===>", active, active2)



    // SELECT STATES 

    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);
    const [cusemail, setCusemail] = useState([]);
    const [selectemail, setSelectemail] = useState("");
    const [finalemail, setFinalemail] = useState([]);

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [alternatephone, setAlternatephone] = useState("");
    const [email, setEmail] = useState("");
    const [flightno, setFlightno] = useState("");
    const [licenseplate, setLicenseplate] = useState("");
    const [phone, setPhone] = useState("");
    const [vehiclecolor, setVehiclecolor] = useState("");
    const [vehiclemake, setVehiclemake] = useState("");
    const [vehiclemodel, setVehiclemodel] = useState("");
    const [evamount, setEvamount] = useState(0);
    const [gasamount, setGasamount] = useState(0);
    const [paymentid, setPaymentid] = useState("");
    const [addiev, setAddiev] = useState("");
    const [addigas, setAddigas] = useState("");
    const [parking, setParking] = useState("")
    const [cardcharge, setCardcharge] = useState("")
    const [transectionfee, setTransectionfee] = useState("")
    console.log("evamount", evamount, parking, cardcharge, transectionfee)

    const [totalamount, setTotalamount] = useState({ "totalamout": 0 });

    console.log("totalamount", totalamount)

    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [active1, setActive1] = useState(false);
    console.log("active1", active1)




    console.log("selected", finalemail, fname)





    const validationSchema = yup.object({
        fnames: yup.string().required('First name is required'),
        lnames: yup.string().required('Last name is required'),
        email: yup.string().required('Email is required'),
        flightno: yup.string().required('flight number is required'),

        vehicleColor: yup.string().required("Vehicle color is required"),
        vehicleModel: yup.string().required("Vehicle model is required"),
        vehicleMake: yup.string().required("Vehicle make is required"),
        licensePlate: yup.string().required("License plate is required"),
        departure_airline_date: yup.string().required("Departure airline date is required"),
        departure_airline_time: yup.string().required("Departure airline time is required"),
        return_airline_time: yup.string().required("Return airline time is required"),
        return_airline_date: yup.string().required("Return airline date is required"),
        phonenumber: yup.string().required("Phone number is required"),
        // alphonenumber: yup.string().required("Alternate phone number is required"),
        // start_time: yup.string().required("Start time is required"),
        // end_time: yup.string().required("End time is required"),
        // Availability_Start_Date: yup.string().required("Availability Start date is required"),
        // Availability_End_Date: yup.string().required("Availability End date is required"),






    });


    const formik = useFormik({
        initialValues: {
            fnames: fname,
            lnames: lname,
            email: email,
            flightno: flightno,
            vehicleColor: vehiclecolor,
            vehicleModel: vehiclemodel,
            vehicleMake: vehiclemake,
            licensePlate: licenseplate,
            departure_airline_date: "",
            departure_airline_time: "",
            return_airline_date: "",
            return_airline_time: "",
            phonenumber: phone,
            alphonenumber: alternatephone,
            // start_time: "",
            // end_time: "",
            // Availability_Start_Date: "",
            // Availability_End_Date: ""



        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // AddServices()
            console.log("values", values)



        },
    })

    console.log("values==> error", formik.values)




    useEffect(() => {
        GetServices()
        GetEmails()


    }, []);



    const GetServices = () => {

        if (props.role == "superadmin") {
            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    // Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/servicecreation`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status && result.status != "False") {
                        console.log("get admin category ", result.category);
                        console.log("get admin services ", result.services);

                        setCategory(result.category);
                        setServices(result.services)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            // setModal(true)
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else if (props.role == "subadmin") {
            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    // Authorization: "Bearer " + ManagerToken
                }
            };

            fetch(`${baseUrl.baseUrl}webapi/servicecreation`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get manager services", result.services);
                        console.log("get manager category", result.category);

                        setCategory(result.category);
                        setServices(result.services)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {

                            // setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });

        }
        else {


            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    // Authorization: "Bearer " + ManagerToken
                }
            };

            fetch(`${baseUrl.baseUrl}webapi/servicecreation`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("get manager services", result.services);
                        console.log("get manager category", result.category);

                        setCategory(result.category);
                        setServices(result.services)
                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/rider/login")
                        } else {

                            // setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });


        }

    }



    const AddServices = () => {
        if (Role == "superadmin") {
            if (active == false && active2 == false && paymentid == "") {
                console.log("active2===>", active2, paymentid)

                setError(true)
                setMessage("please select your payment method")
                setError(true)
                // setMessage("All fields are required")
                // setLoader(false)

            }
            else {

                setLoader(true)


                const form = new FormData()
                form.append("startdate", formik.values.departure_airline_date)
                form.append("enddate", formik.values.return_airline_date)
                form.append("starttime", formik.values.departure_airline_time + ":00")
                form.append("endtime", formik.values.return_airline_time + ":00")
                form.append("name", formik.values.fnames)
                form.append("countrycode", code)
                form.append("phone", formik.values.phonenumber)

                if (formik.values.alphonenumber != "") {

                    form.append("alternatephone", formik.values.alphonenumber)
                }
                form.append("vehicleColor", formik.values.vehicleColor)
                form.append("vehicleMake", formik.values.vehicleMake)
                form.append("vehicleModel", formik.values.vehicleModel)
                form.append("licensePlate", formik.values.licensePlate)
                form.append("departure_airline", formik.values.departure_airline_date)
                form.append("return_airline", formik.values.return_airline_date)
                // form.append("customer", formik.values.Servicename)
                form.append("service", id)
                form.append("email", formik.values.email)
                form.append("flighNumber", formik.values.flightno)

                form.append("lastname", formik.values.lnames)
                form.append("departure_airline_time", formik.values.departure_airline_time + ":00")
                form.append("return_airline_time", formik.values.return_airline_time + ":00")
                form.append("cardinfo", newid)
                form.append("additional", JSON.stringify(shiftForPost))
                form.append("paywithcash", active)
                form.append("gas", parseFloat(gasamount) + addigas)
                form.append("evcharge", parseFloat(evamount) + addiev)
                form.append("instantpay", paymentid)
                form.append("parking", parking);
                form.append("transaction_fee", transectionfee);
                form.append("credit_card_fee", cardcharge);

                var requestOptions = {
                    method: 'POST',

                    headers: {
                        Authorization: "Bearer " + Token
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/adminBooking?role=superadmin `, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        setLoader(false);
                        if (result.status) {
                            console.log(result)

                            // navigate("/AcceptReservation")
                            setError(true)
                            setMessage(result.message)
                            setTimeout(() => {

                                navigate("/AcceptReservation")
                            }, 2000);


                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            } else {
                                // setModal(true)
                                setError2(true)
                                setMessage3(result.message)

                            }
                        }
                    })
                    .catch(error => {
                        // setModal(true)
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });
            }
        }
        else if (Role == "subadmin") {
            if (active == false && active2 == false && paymentid == "") {

                setError(true)
                setMessage("please select your payment method")


            }
            else {


                setLoader(true)
                // toggle()
                const form = new FormData()
                form.append("startdate", formik.values.departure_airline_date)
                form.append("enddate", formik.values.return_airline_date)
                form.append("starttime", formik.values.departure_airline_time + ":00")
                form.append("endtime", formik.values.return_airline_time + ":00")
                form.append("name", formik.values.fnames)
                form.append("countrycode", code)
                form.append("phone", formik.values.phonenumber)
                if (formik.values.alphonenumber != "") {

                    form.append("alternatephone", formik.values.alphonenumber)
                }
                form.append("vehicleColor", formik.values.vehicleColor)
                form.append("vehicleMake", formik.values.vehicleMake)
                form.append("vehicleModel", formik.values.vehicleModel)
                form.append("licensePlate", formik.values.licensePlate)
                form.append("departure_airline", formik.values.departure_airline_date)
                form.append("return_airline", formik.values.return_airline_date)
                // form.append("customer", formik.values.Servicename)
                form.append("service", id)
                form.append("email", formik.values.email)
                form.append("flighNumber", formik.values.flightno)

                form.append("lastname", formik.values.lnames)
                form.append("departure_airline_time", formik.values.departure_airline_time + ":00")
                form.append("return_airline_time", formik.values.return_airline_time + ":00")
                form.append("cardinfo", newid)
                form.append("additional", JSON.stringify(shiftForPost))
                form.append("paywithcash", active)
                form.append("gas", parseFloat(gasamount) + addigas)
                form.append("evcharge", parseFloat(evamount) + addiev)
                form.append("instantpay", paymentid)
                form.append("parking", parking);
                form.append("transaction_fee", transectionfee);
                form.append("credit_card_fee", cardcharge);

                var requestOptions = {
                    method: 'POST',

                    headers: {
                        Authorization: "Bearer " + ManagerToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/adminBooking?role=subadmin `, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        console.log(result);
                        if (result.status) {
                            // GetServices()
                            // toggle()
                            setError(true)
                            setMessage(result.message)
                            setTimeout(() => {

                                navigate("/NewReserve")
                            }, 2000);

                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            } else {
                                // setModal(true)
                                setError2(true)
                                setMessage3(result.message)

                            }
                        }
                    })
                    .catch(error => {
                        // setModal(true)
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });
            }
        }
        else {

            if (active == false && active2 == false && paymentid == "") {

                setError(true)
                setMessage("please select your payment method")

            }
            else {

                setLoader(true)

                const form = new FormData()
                form.append("startdate", formik.values.departure_airline_date)
                form.append("enddate", formik.values.return_airline_date)
                form.append("starttime", formik.values.departure_airline_time + ":00")
                form.append("endtime", formik.values.return_airline_time + ":00")
                form.append("name", formik.values.fnames)
                form.append("countrycode", code)
                form.append("phone", formik.values.phonenumber)
                if (formik.values.alphonenumber != "") {

                    form.append("alternatephone", formik.values.alphonenumber)
                }
                form.append("vehicleColor", formik.values.vehicleColor)
                form.append("vehicleMake", formik.values.vehicleMake)
                form.append("vehicleModel", formik.values.vehicleModel)
                form.append("licensePlate", formik.values.licensePlate)
                form.append("departure_airline", formik.values.departure_airline_date)
                form.append("return_airline", formik.values.return_airline_date)
                // form.append("customer", formik.values.Servicename)
                form.append("service", id)
                form.append("email", formik.values.email)
                form.append("flighNumber", formik.values.flightno)

                form.append("lastname", formik.values.lnames)
                form.append("departure_airline_time", formik.values.departure_airline_time + ":00")
                form.append("return_airline_time", formik.values.return_airline_time + ":00")
                form.append("additional", JSON.stringify(shiftForPost))
                form.append("cardinfo", newid)
                form.append("paywithcash", active)
                form.append("gas", parseFloat(gasamount) + addigas)
                form.append("evcharge", parseFloat(evamount) + addiev)
                form.append("instantpay", paymentid)
                form.append("parking", parking);
                form.append("transaction_fee", transectionfee);
                form.append("credit_card_fee", cardcharge);

                var requestOptions = {
                    method: 'POST',

                    headers: {
                        Authorization: "Bearer " + RiderToken
                    },
                    body: form,
                };
                // setLoader(true);
                fetch(`${baseUrl.baseUrl}webapi/adminBooking?role=rider `, requestOptions)
                    .then(response => response.json())
                    .then(result => {

                        setLoader(false);
                        if (result.status) {
                            console.log(result)
                            setError(true)
                            setMessage(result.message)
                            setTimeout(() => {
                                navigate("/RiderReservation")
                            }, 2000);

                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/rider/login")
                            } else {
                                // setModal(true)
                                setError2(true)
                                setMessage3(result.message)

                            }
                        }
                    })
                    .catch(error => {
                        // setModal(true)
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });

            }

        }




    }




    // const GetcardInfo = () => {
    //     console.log("getrider top", props.role)



    //     if (Role == "superadmin") {
    //         console.log("getrider superadmin")
    //         // setLoader(true);
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: "Bearer " + Token
    //             }
    //         };
    //         fetch(`${baseUrl.baseUrl}webapi/cardinfo?role=superadmin&search=all`, requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 setLoader(false);
    //                 if (result.status && result.status != "False") {
    //                     setCardInfo(result.data)

    //                     console.log("card info==>", result.data)



    //                 }
    //                 else {
    //                     if (result.message == "Unauthorized") {
    //                         navigate("/")
    //                     } else {
    //                         // setModal(true)
    //                         setError2(true)
    //                         setMessage3(result.message)
    //                     }
    //                 }
    //             })
    //             .catch(error => {
    //                 setLoader(false);
    //                 setError2(true)
    //                 setMessage3(error)
    //             });

    //     }
    //     else if (Role == "subadmin") {
    //         console.log("getrider subadmin")

    //         setLoader(true);
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: "Bearer " + ManagerToken
    //             }
    //         };

    //         fetch(`${baseUrl.baseUrl}webapi/cardinfo?role=subadmin&search=all`, requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 setLoader(false);
    //                 if (result.status) {
    //                     setCardInfo(result.data)



    //                 }
    //                 else {
    //                     if (result.message == "Unauthorized") {
    //                         navigate("/ManagerLogin")
    //                     } else {

    //                         // setModal(true)
    //                         setError2(true)
    //                         setMessage3(result.message)

    //                     }
    //                 }
    //             })
    //             .catch(error => {
    //                 setLoader(false);
    //                 setError2(true)
    //                 setMessage3(error)

    //             });

    //     }

    //     else {
    //         console.log("getrider rider")

    //         setLoader(true);
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: "Bearer " + RiderToken
    //             }
    //         };

    //         fetch(`${baseUrl.baseUrl}webapi/cardinfo?role=rider&search=all`, requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 setLoader(false);
    //                 if (result.status) {
    //                     setCardInfo(result.data)



    //                 }
    //                 else {
    //                     if (result.message == "Unauthorized") {
    //                         navigate("/rider/login")
    //                     } else {

    //                         // setModal(true)
    //                         setError2(true)
    //                         setMessage3(result.message)

    //                     }
    //                 }
    //             })
    //             .catch(error => {
    //                 setLoader(false);
    //                 setError2(true)
    //                 setMessage3(error)

    //             });


    //     }


    // }


    const Data = (a) => {
        console.log("data===")

        if (a) {
            setError1(false)
            setModal(false)


        }



    }

    const Reserve = (id) => {
        console.log("rider id", id)


        setNewid(id)
        setError1(true)
        setMessage1("Are You Sure?")
        setMessage2("You want to Select this ?")

            .then((result) => {
                console.log("thennnnn")
                Data(result.isConfirmed)

            })
    }


    // const NewONe = () => {

    //     toggle()
    //     GetcardInfo()
    // }


    const Remove = (index, value) => {
        if (value == "gas service fee") {
            setGasamount(0)
            setAddigas("")
        } else if (value == "evcharge") {
            setEvamount(0)
            setAddiev("")
        }

        const a = shiftForPost.filter((v, i) => i !== index)



        setShiftForPost(a)
    }




    const GetEmails = (emaildata = "") => {
        if (Role == "superadmin") {

            // setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + Token
                }
            };
            fetch(`${baseUrl.baseUrl}webapi/previousDetails?query=${emaildata}&role=superadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        console.log("check data", result)
                        // setCardInfo(result.cards)

                        if (emaildata == "") {


                            setCusemail(result.data)
                            // setCardInfo(result.cards)

                        }
                        else {
                            if (result.data != null) {


                                formik.values.fnames = result.data.name
                                formik.values.lnames = result.data.lastname
                                formik.values.email = result.data.email
                                formik.values.flightno = result.data.flighNumber
                                formik.values.phonenumber = result.data.phone
                                formik.values.alphonenumber = result.data.alternatephone
                                formik.values.licensePlate = result.data.licensePlate
                                formik.values.vehicleColor = result.data.vehicleColor
                                formik.values.vehicleMake = result.data.vehicleMake
                                formik.values.vehicleModel = result.data.vehicleModel

                                setCardInfo(result.cards)
                                setFinalemail(result.data)
                                setFname(result.data.name)
                                setLname(result.data.lastname)
                                setEmail(result.data.email)
                                setFlightno(result.data.flighNumber)
                                setPhone(result.data.phone)
                                setAlternatephone(result.data.alternatephone)
                                setLicenseplate(result.data.licensePlate)
                                setVehiclecolor(result.data.vehicleColor)
                                setVehiclemake(result.data.vehicleMake)
                                setVehiclemodel(result.data.vehicleModel)


                            }
                            else if (result.data == null) {
                                setCardInfo(result.cards)

                                formik.values.fnames = ""
                                formik.values.lnames = ""
                                formik.values.email = ""
                                formik.values.flightno = ""

                                formik.values.phonenumber = ""
                                formik.values.alphonenumber = ""
                                formik.values.licensePlate = ""
                                formik.values.vehicleColor = ""
                                formik.values.vehicleMake = ""
                                formik.values.vehicleModel = ""



                                setFinalemail("")
                                setFname("")
                                setLname("")
                                setEmail("")
                                setPhone("")
                                setAlternatephone("")
                                setLicenseplate("")
                                setVehiclecolor("")
                                setVehiclemake("")
                                setVehiclemodel("")
                                setFlightno("")
                                // setCardInfo([])



                            }


                        }






                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/")
                        } else {
                            // setModal(true)
                            setError2(true)
                            setMessage3(result.message)
                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)
                });

        }
        else if (Role == "subadmin") {


            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + ManagerToken
                }
            };

            fetch(`${baseUrl.baseUrl}webapi/previousDetails?query=${emaildata}&role=subadmin`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {

                        if (emaildata == "") {

                            setCusemail(result.data)
                        }
                        else {
                            if (result.data != null) {

                                formik.values.fnames = result.data.name
                                formik.values.lnames = result.data.lastname
                                formik.values.email = result.data.email
                                formik.values.flightno = result.data.flighNumber

                                formik.values.phonenumber = result.data.phone
                                formik.values.alphonenumber = result.data.alternatephone
                                formik.values.licensePlate = result.data.licensePlate
                                formik.values.vehicleColor = result.data.vehicleColor
                                formik.values.vehicleMake = result.data.vehicleMake
                                formik.values.vehicleModel = result.data.vehicleModel


                                setCardInfo(result.cards)

                                setFinalemail(result.data)
                                setFname(result.data.name)
                                setLname(result.data.lastname)
                                setEmail(result.data.email)
                                setFlightno(result.data.flighNumber)

                                setPhone(result.data.phone)
                                setAlternatephone(result.data.alternatephone)
                                setLicenseplate(result.data.licensePlate)
                                setVehiclecolor(result.data.vehicleColor)
                                setVehiclemake(result.data.vehicleMake)
                                setVehiclemodel(result.data.vehicleModel)


                            }
                            else if (result.data == null) {
                                setCardInfo(result.cards)

                                formik.values.fnames = ""
                                formik.values.lnames = ""
                                formik.values.email = ""
                                formik.values.flightno = ""

                                formik.values.phonenumber = ""
                                formik.values.alphonenumber = ""
                                formik.values.licensePlate = ""
                                formik.values.vehicleColor = ""
                                formik.values.vehicleMake = ""
                                formik.values.vehicleModel = ""


                                // setCardInfo([])

                                setFinalemail("")
                                setFname("")
                                setLname("")
                                setEmail("")
                                setPhone("")
                                setAlternatephone("")
                                setLicenseplate("")
                                setVehiclecolor("")
                                setVehiclemake("")
                                setVehiclemodel("")


                            }


                        }



                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/ManagerLogin")
                        } else {

                            // setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });

        }

        else {
            console.log("getrider rider")

            setLoader(true);
            var requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + RiderToken
                }
            };

            fetch(`${baseUrl.baseUrl}webapi/previousDetails?query=${emaildata}&role=rider`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoader(false);
                    if (result.status) {
                        if (emaildata == "") {


                            setCusemail(result.data)
                        }
                        else {
                            if (result.data != null) {


                                formik.values.fnames = result.data.name
                                formik.values.lnames = result.data.lastname
                                formik.values.email = result.data.email
                                formik.values.flightno = result.data.flighNumber

                                formik.values.phonenumber = result.data.phone
                                formik.values.alphonenumber = result.data.alternatephone
                                formik.values.licensePlate = result.data.licensePlate
                                formik.values.vehicleColor = result.data.vehicleColor
                                formik.values.vehicleMake = result.data.vehicleMake
                                formik.values.vehicleModel = result.data.vehicleModel


                                setCardInfo(result.cards)

                                setFinalemail(result.data)
                                setFname(result.data.name)
                                setLname(result.data.lastname)
                                setEmail(result.data.email)
                                setFlightno(result.data.flighNumber)

                                setPhone(result.data.phone)
                                setAlternatephone(result.data.alternatephone)
                                setLicenseplate(result.data.licensePlate)
                                setVehiclecolor(result.data.vehicleColor)
                                setVehiclemake(result.data.vehicleMake)
                                setVehiclemodel(result.data.vehicleModel)


                            }
                            else if (result.data == null) {
                                setCardInfo(result.cards)
                                formik.values.fnames = ""
                                formik.values.lnames = ""
                                formik.values.flightno = ""

                                formik.values.email = ""
                                formik.values.phonenumber = ""
                                formik.values.alphonenumber = ""
                                formik.values.licensePlate = ""
                                formik.values.vehicleColor = ""
                                formik.values.vehicleMake = ""
                                formik.values.vehicleModel = ""


                                // setCardInfo([])
                                setFinalemail("")
                                setFname("")
                                setLname("")
                                setEmail("")
                                setPhone("")
                                setAlternatephone("")
                                setLicenseplate("")
                                setVehiclecolor("")
                                setVehiclemake("")
                                setVehiclemodel("")


                            }


                        }





                    }
                    else {
                        if (result.message == "Unauthorized") {
                            navigate("/rider/login")
                        } else {

                            // setModal(true)
                            setError2(true)
                            setMessage3(result.message)

                        }
                    }
                })
                .catch(error => {
                    setLoader(false);
                    setError2(true)
                    setMessage3(error)

                });


        }

    }

    const addMoney = (e) => {
        if (e == "evcharge") {
            const DataFilter = services.filter((v, i) => e === v.servicename);
            setAddiev(DataFilter[0].price)


        } else if (e == "gas service fee") {
            const DataFilter = services.filter((v, i) => e === v.servicename);
            console.log("gas fee", DataFilter[0].price)
            setAddigas(DataFilter[0].price)



        }






    }



    const onChange = (newValue) => {
        GetEmails(newValue.value)


    }


    // GET TOTAL AMOUNT 


    const GetTotalamount = () => {
        // toggle1()


        setLoader(true);


        if (Role == "superadmin") {

            if (id == "" || formik.values.departure_airline_date == "" || formik.values.return_airline_date == "" || formik.values.departure_airline_time == "" || formik.values.return_airline_time == "") {


                setError(true)
                setMessage("All fields are required")
                setLoader(false)

            }
            else {


                setLoader(true);
                var requestOptions = {
                    method: 'GET',
                    // headers: {
                    //     Authorization: "Bearer " + Token
                    // }
                };
                fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${id}&startdate=${formik.values.departure_airline_date}&enddate=${formik.values.return_airline_date}&starttime=${formik.values.departure_airline_time + ":00"}&endtime=${formik.values.return_airline_time + ":00"}&additional=${JSON.stringify(shiftForPost)}&gas=${parseFloat(gasamount) + addigas}&evcharge=${parseFloat(evamount) + addiev}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        if (result.status) {

                            setTotalamount(result.data)
                            setActive1(false)
                            console.log("total amount", result.data)
                            setParking(result.data.parking)
                            setCardcharge(result.data.credit_card_fee)
                            setTransectionfee(result.data.transaction_fee)


                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/")
                            } else {
                                // setModal(true)
                                setError2(true)
                                setMessage3(result.message)
                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)
                    });
            }

        }
        else if (Role == "subadmin") {
            if (id == "" || formik.values.departure_airline_date == "" || formik.values.return_airline_date == "" || formik.values.departure_airline_time == "" || formik.values.return_airline_time == "") {


                setError(true)
                setMessage("All fields are required")
                setLoader(false)

            }
            else {


                setLoader(true);
                var requestOptions = {
                    method: 'GET',
                    // headers: {
                    //     Authorization: "Bearer " + ManagerToken
                    // }
                };

                fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${id}&startdate=${formik.values.departure_airline_date}&enddate=${formik.values.return_airline_date}&starttime=${formik.values.departure_airline_time + ":00"}&endtime=${formik.values.return_airline_time + ":00"}&additional=${JSON.stringify(shiftForPost)}&gas=${parseFloat(gasamount) + addigas}&evcharge=${parseFloat(evamount) + addiev}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        if (result.status) {
                            setTotalamount(result.data)
                            setParking(result.data.parking)
                            setCardcharge(result.data.credit_card_fee)
                            setTransectionfee(result.data.transaction_fee)
                            setActive1(false)






                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/ManagerLogin")
                            } else {

                                // setModal(true)
                                setError2(true)
                                setMessage3(result.message)

                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });

            }
        }

        else {
            if (id == "" || formik.values.departure_airline_date == "" || formik.values.return_airline_date == "" || formik.values.departure_airline_time == "" || formik.values.return_airline_time == "") {


                setError(true)
                setMessage("All fields are required")
                setLoader(false)

            }
            else {

                setLoader(true);
                var requestOptions = {
                    method: 'GET',
                    // headers: {
                    //     Authorization: "Bearer " + RiderToken
                    // }
                };

                fetch(`${baseUrl.baseUrl}webapi/amountCalculation?id=${id}&startdate=${formik.values.departure_airline_date}&enddate=${formik.values.return_airline_date}&starttime=${formik.values.departure_airline_time + ":00"}&endtime=${formik.values.return_airline_time + ":00"}&additional=${JSON.stringify(shiftForPost)}&gas=${parseFloat(gasamount) + addigas}&evcharge=${parseFloat(evamount) + addiev}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setLoader(false);
                        if (result.status) {
                            setTotalamount(result.data)
                            setParking(result.data.parking)
                            setCardcharge(result.data.credit_card_fee)
                            setTransectionfee(result.data.transaction_fee)
                            setActive1(false)






                        }
                        else {
                            if (result.message == "Unauthorized") {
                                navigate("/rider/login")
                            } else {

                                // setModal(true)
                                setError2(true)
                                setMessage3(result.message)

                            }
                        }
                    })
                    .catch(error => {
                        setLoader(false);
                        setError2(true)
                        setMessage3(error)

                    });


            }
        }

    }







    // payment 
    const onToken = (token) => {
        console.log("ontoken", token)
        setPaymentid(token.id)
        // AddServices(token.id, )
    }

    const paymentStripe = () => {
        let a = document.getElementById('paymentmethod')
        a.children[0].click()
    }





    return (
        <>

            <Error status={error} message={message} func={handleCloseB} />
            <Info status={error1} message={message1} msg={message2} func={handleCloseB1} del={Data} />
            <Failed status={error2} message={message3} func={handleCloseB2} />
            {loader == true ? <Loader fullPage loading /> : null}
            {props.role == "superadmin" ? <AdminHeader /> :
                props.role == "subadmin" ? <ManagerHeader /> : <RiderHeader />}
            <div className='wrapper'>
                <div className='sa4d25'>
                    <div
                        className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12 col-lg-8">
                                <div className="section3125">
                                    <h4 className="item_title">Booking</h4>
                                    <div className="la5lo1">
                                        <section >
                                            <div className="text-center">
                                                <h2 className="text-center" style={{ backgroundColor: "rgba(3, 156, 213)", color: "white" }}>Reserved Your Parking Now</h2>
                                            </div>
                                            <div className="container mt-4">
                                                <img src="/assets/images/Group30.png" width="90%" alt="" />
                                                <form onSubmit={formik.handleSubmit}>

                                                    <div className="row d-flex mb-5 mt-3 contact-info" >
                                                        <div className="col-md-6">
                                                            <span>Select Customer Email</span>


                                                            <Select
                                                                // value={selectemail}
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                // defaultValue={colourOptions[0]}
                                                                isLoading={isLoading}
                                                                isClearable={isClearable}
                                                                name="color"
                                                                onChange={onChange}
                                                                // onChange={(e)=>setSelectemail(e.target.value)}
                                                                options={cusemail}
                                                            />


                                                        </div>

                                                    </div>

                                                    <div className="row d-flex mb-5 mt-3 contact-info">
                                                        <div className="col-md-6">
                                                            <span>Select Service Type</span>

                                                            <select className="form-control" value={categorydata} onChange={e => setCategorydata(e.target.value)}>

                                                                <option >Options ...</option>
                                                                {category.map((v, i) => {

                                                                    if (v.name != "Additional services") {

                                                                        return <option value={v.name}>{v.name}</option>
                                                                    }

                                                                })}


                                                            </select>


                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>Select Service</span>

                                                            <select className='form-control' aria-label="Default select example" onChange={(e) => setId(e.target.value)}>
                                                                <option >Select Your Service  ...</option>

                                                                {services.map((v, i) => {
                                                                    // setId(v.id)
                                                                    if (categorydata == v.category_name) {
                                                                        return <option value={v.id}>{v.servicename}</option>
                                                                    }

                                                                })}


                                                            </select>



                                                        </div>

                                                    </div>


                                                    <div className="row d-flex mb-5 mt-3 contact-info">
                                                        <div className="col-md-6">
                                                            <span>First Name</span>

                                                            <input

                                                                type="text"
                                                                id='fnames'
                                                                name="fnames"
                                                                className="form-control rounded input-lg"
                                                                value={formik.values.fnames ? formik.values.fnames : fname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.fnames && formik.errors.fnames ?
                                                                <div className="text-danger"> {formik.errors.fnames}</div> : null}

                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>Last Name</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=" "
                                                                name="lnames"
                                                                id='lnames'
                                                                value={formik.values.lnames ? formik.values.lnames : lname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.lnames && formik.errors.lnames ?
                                                                <div className="text-danger"> {formik.errors.lnames}</div> : null}

                                                        </div>

                                                    </div>

                                                    <div className="row d-flex mb-5 mt-3 contact-info">
                                                        <div className="col-md-6">
                                                            <span>Phone Number</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""
                                                                name="phonenumber"
                                                                id='phonenumber'
                                                                value={formik.values.phonenumber ? formik.values.phonenumber : phone}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.phonenumber && formik.errors.phonenumber ?
                                                                <div className="text-danger"> {formik.errors.phonenumber}</div> : null}



                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>Alternate Number</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=" "
                                                                name="alphonenumber"
                                                                id='alphonenumber'
                                                                value={formik.values.alphonenumber ? formik.values.alphonenumber : alternatephone}
                                                                onChange={formik.handleChange}
                                                            // onBlur={formik.handleBlur}
                                                            />

                                                            {/* {formik.touched.alphonenumber && formik.errors.alphonenumber ?
                                                                <div className="text-danger"> {formik.errors.alphonenumber}</div> : null} */}
                                                        </div>

                                                    </div>
                                                    <div className="row d-flex mb-5 contact-info">
                                                        <div className="col-md-6">
                                                            <span>Vehicle color</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""

                                                                name="vehicleColor"
                                                                id='vehicleColor'
                                                                value={formik.values.vehicleColor ? formik.values.vehicleColor : vehiclecolor}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.vehicleColor && formik.errors.vehicleColor ?
                                                                <div className="text-danger"> {formik.errors.vehicleColor}</div> : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>Vehicle Make</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""

                                                                name="vehicleMake"
                                                                id='vehicleMake'
                                                                value={formik.values.vehicleMake ? formik.values.vehicleMake : vehiclemake}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.vehicleMake && formik.errors.vehicleMake ?
                                                                <div className="text-danger"> {formik.errors.vehicleMake}</div> : null}
                                                        </div>

                                                    </div>

                                                    <div className="row d-flex mb-5 contact-info">
                                                        <div className="col-md-6">
                                                            <span>Vehicle Model</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""


                                                                name="vehicleModel"
                                                                id='vehicleModel'
                                                                value={formik.values.vehicleModel ? formik.values.vehicleModel : vehiclemodel}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.vehicleModel && formik.errors.vehicleModel ?
                                                                <div className="text-danger"> {formik.errors.vehicleModel}</div> : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>License plate</span>

                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""


                                                                name="licensePlate"
                                                                id='licensePlate'
                                                                value={formik.values.licensePlate ? formik.values.licensePlate : licenseplate}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.licensePlate && formik.errors.licensePlate ?
                                                                <div className="text-danger"> {formik.errors.licensePlate}</div> : null}
                                                        </div>

                                                    </div>
                                                    {/* <div className="row d-flex mb-5 contact-info">

                                                        <div className="col-md-6">
                                                            <span>Availability Start Date</span>

                                                            <input type="date"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""

                                                                name="Availability_Start_Date"
                                                                id='Availability_Start_Date'
                                                                value={formik.values.Availability_Start_Date}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.Availability_Start_Date && formik.errors.Availability_Start_Date ?
                                                                <div className="text-danger"> {formik.errors.Availability_Start_Date}</div> : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>Availability End Date</span>

                                                            <input type="date"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""

                                                                name="Availability_End_Date"
                                                                id='Availability_End_Date'
                                                                value={formik.values.Availability_End_Date}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.Availability_End_Date && formik.errors.Availability_End_Date ?
                                                                <div className="text-danger"> {formik.errors.Availability_End_Date}</div> : null}
                                                        </div>

                                                    </div> */}

                                                    {/* <div className="row d-flex mb-5 contact-info">

                                                        <div className="col-md-6">
                                                            <span> Start Time</span>

                                                            <input type="time"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""

                                                                name="start_time"
                                                                id='start_time'
                                                                value={formik.values.start_time}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.start_time && formik.errors.start_time ?
                                                                <div className="text-danger"> {formik.errors.start_time}</div> : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span> End Time</span>

                                                            <input type="time"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""

                                                                name="end_time"
                                                                id='end_time'
                                                                value={formik.values.end_time}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.end_time && formik.errors.end_time ?
                                                                <div className="text-danger"> {formik.errors.end_time}</div> : null}
                                                        </div>

                                                    </div> */}

                                                    <div className="row d-flex mb-5 contact-info">
                                                        <div className="col-md-6">
                                                            <span>Departing  Date</span>

                                                            <input type="date"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""
                                                                min={newdate}

                                                                name="departure_airline_date"
                                                                id='departure_airline_date'
                                                                value={formik.values.departure_airline_date}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.departure_airline_date && formik.errors.departure_airline_date ?
                                                                <div className="text-danger"> {formik.errors.departure_airline_date}</div> : null}
                                                        </div>

                                                        <div className="col-md-6">
                                                            <span>Return  Date</span>

                                                            <input type="date"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""
                                                                min={newdate}

                                                                name="return_airline_date"
                                                                id='return_airline_date'
                                                                value={formik.values.return_airline_date}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.return_airline_date && formik.errors.return_airline_date ?
                                                                <div className="text-danger"> {formik.errors.return_airline_date}</div> : null}
                                                        </div>


                                                    </div>


                                                    <div className="row d-flex mb-5 contact-info">
                                                        <div className="col-md-6">
                                                            <span>Departing Time</span>
                                                            <input type="time"
                                                                className="form-control rounded input-lg"
                                                                placeholder="Departing Time"

                                                                name="departure_airline_time"
                                                                id='departure_airline_time'
                                                                value={formik.values.departure_airline_time}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.departure_airline_time && formik.errors.departure_airline_time ?
                                                                <div className="text-danger"> {formik.errors.departure_airline_time}</div> : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span>Return Time</span>
                                                            <input type="time"
                                                                className="form-control rounded input-lg"
                                                                placeholder="Return Time"
                                                                name="return_airline_time"
                                                                id='return_airline_time'
                                                                value={formik.values.return_airline_time}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.return_airline_time && formik.errors.return_airline_time ?
                                                                <div className="text-danger"> {formik.errors.return_airline_time}</div> : null}
                                                        </div>


                                                    </div>

                                                    <div className="row d-flex mb-5 contact-info">

                                                        <div className="col-md-6">
                                                            <span>Email</span>
                                                            <input type="email"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""
                                                                name="email"
                                                                id='email'
                                                                value={formik.values.email ? formik.values.email : email}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.email && formik.errors.email ?
                                                                <div className="text-danger"> {formik.errors.email}</div> : null}
                                                        </div>


                                                        <div className="col-md-6">
                                                            <span>Flight Number</span>
                                                            <input type="text"
                                                                className="form-control rounded input-lg"
                                                                placeholder=""
                                                                name="flightno"
                                                                id='flightno'
                                                                value={formik.values.flightno ? formik.values.flightno : flightno}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.flightno && formik.errors.flightno ?
                                                                <div className="text-danger"> {formik.errors.flightno}</div> : null}
                                                        </div>


                                                    </div>


                                                    <div className="col-md-12 col-sm-6">
                                                        <div className="form-group ">
                                                            <label style={{ marginBottom: "5px" }}>Select Additional Service*</label>

                                                            {/* <InputLabel id="demo-simple-select-label">Select Category</InputLabel> */}

                                                            <select id="unique" class="form-select"
                                                                style={{ width: "100%", padding: "1.5rem" }}
                                                                value={Additional}
                                                                //  onChange={e => setAdditional(e.target.value)}

                                                                onChange={(e) => {
                                                                    console.log("e=>", e)
                                                                    const shif = e.target.value;
                                                                    if (shif == "evcharge") {

                                                                        addMoney(shif)
                                                                        console.log("check toggle1")
                                                                        toggle1()
                                                                    }
                                                                    else if (shif == "gas service fee") {
                                                                        addMoney(shif)

                                                                        setActive1(true)
                                                                        toggle1()

                                                                    }
                                                                    if (!shiftForPost.includes(shif)) {

                                                                        setShiftForPost([...shiftForPost, shif])

                                                                    }


                                                                }}

                                                            >

                                                                <option >Select Your Service  ...</option>
                                                                {services.map((v, i) => {


                                                                    console.log("service v", v)
                                                                    if (v.category_name == "Additional services") {



                                                                        return <option key={v.id} value={v.servicename}>{v.servicename}</option>
                                                                    }

                                                                })}


                                                            </select>
                                                            <h4 className='mt-4'>Selected Additional Services</h4>
                                                            {shiftForPost.map((v, i) => {
                                                                console.log("v", v)

                                                                return <h6 style={{ marginTop: "20px" }}>  <i onClick={() => Remove(i, v)} style={{ color: "red", marginLeft: "0px" }} className="fa fa-window-close " /> {v}</h6>
                                                            })}


                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {Object.keys(totalamount).length != 1 ?
                                                            <>
                                                                {cardinfo.length > 0 ?
                                                                    <div className="col-md-12 ">


                                                                        <div className="ui form mt-30 checkbox_sign">
                                                                            <div className="inline field">
                                                                                <div className="ui checkbox mncheck">
                                                                                    <input onClick={() => { setActive2(true); toggle() }} type="checkbox" tabIndex={0} id="flexCheckChecked" className="hidden" />
                                                                                    <label htmlFor="flexCheckChecked">  Pay with existing Card</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        {/* <div class="form-check">
    <input class="form-check-input" onClick={() => NewONe()} type="checkbox" value="" id="flexCheckChecked" checked />
    <label class="form-check-label" htmlFor="flexCheckChecked">
        Pay with Debit Card
    </label>
</div> */}



                                                                    </div> : ""}




                                                                <div className="col-md-12 ">


                                                                    <div className="ui form  checkbox_sign">
                                                                        <div className="inline field">
                                                                            <div className="ui checkbox mncheck">
                                                                                <input onClick={() => setActive(true)} type="checkbox" tabIndex={0} id="flexCheckChecked1" className="hidden" />
                                                                                <label htmlFor="flexCheckChecked1">  Pay with Cash</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                <div className="col-md-12 ">

                                                                    <div className="type" onClick={() => paymentStripe()}>
                                                                        <div className="logo paystripe">

                                                                            <p> <i className="fa fa-credit-card" /> Pay with Credit Card</p>
                                                                            <div id='paymentmethod' className='d-none'>
                                                                                <StripeCheckout
                                                                                    token={onToken}
                                                                                    stripeKey="pk_test_hWK9CCVSwmaNFT7tlIbxHo5s00GntIdMdu"
                                                                                    name='payment'
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    {/* <div id='paymentmethod' className='d-none'>
                                                                <StripeCheckout
                                                                    token={onToken}
                                                                    stripeKey="pk_test_hWK9CCVSwmaNFT7tlIbxHo5s00GntIdMdu"
                                                                    name='payment'
                                                                />
                                                            </div> */}

                                                                </div>    </> : ""}

                                                    </div>
                                                    {Object.keys(totalamount).length != 1 ?

                                                        <button type="button" onClick={() => AddServices()} className="main-btn ">Book Now</button> : ""
                                                    }
                                                    <button type="button" onClick={() => GetTotalamount()} className="main-btn ">Calculate Amount</button>
                                                    <div className='row mt-4'>
                                                        <div className='col-md-12'>

                                                            <h3>Your Total Amount is : {totalamount.totalamout.toFixed(2)}</h3>
                                                        </div>



                                                    </div>

                                                </form>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.role == "superadmin" ? <AdminFooter /> :

                props.role == "subadmin" ? <ManagerFooter /> : <RiderFooter />}



            {/* //Add MOdal  */}

            <Modal className='text-center' isOpen={modal} size="lg" >
                <ModalHeader >
                    <modalTitle> Debit Card Details</modalTitle>
                    <i onClick={toggle} style={{ fontSize: "24px", marginLeft: "310px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>
                        <div className="row">
                            <div className="col-md-12 item">
                                <MaterialTable
                                    title=""
                                    icons={{
                                        Check: Check,
                                        DetailPanel: ChevronRight,
                                        // Delete: DeleteOutline,
                                        Export: SaveAlt,
                                        Filter: FilterList,
                                        FirstPage: FirstPage,
                                        LastPage: LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        Search: Search,
                                        ThirdStateCheck: Remove,
                                        Add: Add,
                                        SortArrow: ArrowDownward,
                                        Clear: Clear,
                                        Edit: Edit,
                                        ViewColumn: ViewColumn,
                                        ResetSearch: Clear
                                    }}

                                    columns={[
                                        { title: 'Card Holder Name', field: 'cardholdername' },
                                        { title: 'Email', field: 'email' },
                                        { title: 'Contact', field: 'contact' },
                                        { title: 'Card Number', field: 'cardnumber' },


                                        {
                                            title: 'Action', render: (rowData) =>

                                                <>


                                                    <Button type='button' onClick={() => Reserve(rowData.id)} style={{ backgroundColor: "#03bafe", color: "white" }}  >Select </Button>











                                                </>

                                        },






                                    ]}
                                    data={cardinfo}
                                    // actions={[
                                    //     {
                                    //         icon: DeleteIcon,
                                    //         tooltip: 'Delete User',
                                    //         onClick: (event, rowData) => {

                                    //         }
                                    //     },
                                    //     {
                                    //         icon: Edit,
                                    //         tooltip: 'Edit User',
                                    //         onClick: (event, rowData) => {
                                    //             console.log("rowdata=>", rowData)

                                    //         }
                                    //     }
                                    // ]}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#03bafe',
                                            color: '#FFF'
                                        },
                                        actionsColumnIndex: -1,
                                        exportButton: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row text-center justify-content-end mt-2">
                            <button type="button" onClick={() => toggle()} className="col-2 main-btn cancel mx-3">Close</button>
                            {/* <button type="button" className="col-2 main-btn">Add</button> */}
                        </div>
                    </form>
                </ModalBody>
            </Modal>



            <Modal className='text-center' isOpen={modal1} size="md" >
                <ModalHeader >
                    <modalTitle> Amount</modalTitle>
                    <i onClick={toggle1} style={{ fontSize: "24px", marginLeft: "310px" }} className=" "></i>
                </ModalHeader>
                <ModalBody className='text-left'>
                    <form>

                        {active1 == false ?
                            <div className="col-md-12 col-sm-6" style={{ justifyContent: 'space-around', marginBottom: '15px' }}>
                                <label>Evcharge</label>
                                <input type="text" value={evamount} onChange={(e) => setEvamount(e.target.value)} className="form-control" placeholder='Enter evcharge amount' />

                            </div> :

                            active1 == true ?

                                <div className="col-md-12 col-sm-6" style={{ justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <label>Gas Charge</label>

                                    <input type="text" value={gasamount} onChange={(e) => setGasamount(e.target.value)} className="form-control" placeholder='Enter gas amount' />

                                </div> : ""
                        }

                        <div className="row text-center justify-content-end mt-2">
                            <button type="button" onClick={() => toggle1()} className="col-2 main-btn  mx-3">Ok</button>
                            {/* <button type="button" className="col-2 main-btn">Add</button> */}
                        </div>

                    </form>
                </ModalBody>
            </Modal>




        </>
    );
}
