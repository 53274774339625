import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Loader } from "react-overlay-loader"
import logo from "../../Admin/auth/newlogo.png"
import baseUrl from "../../../baseurl/BaseUrl";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import 'react-overlay-loader/styles.css';
import Error from '../../../components/modal/Modal';
import Info from '../../../components/modal/Info';
import Failed from '../../../components/modal/Failed';
import { FaFacebook, FaGoogle, FaEye } from "react-icons/fa";


export default function RiderLogin() {
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [loader, setLoader] = useState(false);

  // SUCCESS STATE
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  console.log("mesg", message, error)
  const handleCloseB = () => setError(false);

  // INFO STATES 
  const [error1, setError1] = useState(false);
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const handleCloseB1 = () => setError1(false);

  //   Failed STATES 

  const [error2, setError2] = useState(false);
  const [message3, setMessage3] = useState("");
  const handleCloseB2 = () => setError2(false);

  const navigate = useNavigate();

  // HIDE SHOW Password 

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const password = values.password

  console.log("password value", password)



  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };



  const handleInputChange = (event, func) => {
    func(event.target.value);
  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      loginUser();
    }
  };

  const loginUser = () => {
    if (Email == "") {
      setError2(true)
      setMessage3("Email or Username is required ")

    } else if (password == "") {
      setError2(true)
      setMessage3("password is required ")
    } else {


      var formdata = new FormData();
      formdata.append("email", Email);
      formdata.append("password", password);
      var requestOptions = {
        method: 'POST',
        body: formdata,
      };
      // setLoader(true);
      fetch(`${baseUrl.baseUrl}webapi/riderlogin`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLoader(false);

          if (result.status) {
            setError(true)
            setMessage("result.message")
            console.log("status check", result.message)
            localStorage.setItem('RiderToken', result.token);
            localStorage.setItem('RiderData', JSON.stringify(result.data));
            // navigate("/RiderReservation", { replace: true });
            //   Router.push('/admin/dashboard/Dashboard')

            setError(true)
            setMessage(result.message)
            setEmail("")
            setPassword("")







          }
          else {
            setError2(true)
            setMessage3(result.message)
          }

        })
        .catch(error => {
          setLoader(false);
          setError2(true)
          setMessage3(error)
        });
    }
  }

  const Navi = () => {
    navigate("/RiderReservation", { replace: true });


  }




  return (<>
    <Error status={error} message={message} func={handleCloseB} navi={Navi} />
    <Info status={error1} message={message1} msg={message2} func={handleCloseB1} />
    <Failed status={error2} message={message3} func={handleCloseB2} />
    {loader == true ? <Loader fullPage loading /> : null}
    <div className="sign_in_up_bg">
      <div className="container">
        <div className="row justify-content-lg-center justify-content-md-center">
          {/* <div className="col-lg-12">
                        <div className="main_logo26" id="logo">

              <img className="logo-inverse" src={logo} width={"450px"} height={'120px'} alt="Tutor Learner" />
            </div>
                    </div> */}
          <div className="col-lg-6 col-md-8">
            <div className="sign_forms">


              <img src={logo} width={"80px"} height={"80px"} alt=""></img>
              <h3>Welcome Vallet</h3>
              <form>
                <label className="label25 text-left">Email<span className="text-danger">*</span></label>
                <div className="ui search focus mt-15 mb-3">
                  <div className="ui left icon input swdh95">
                    <input className="prompt srch_explore" onChange={(e) => handleInputChange(e, setEmail)} defaultValue={Email} type="email" name="emailaddress" id="id_email" required maxLength={64} placeholder="Email Address" onKeyDown={(e) => handleKeypress(e)} />
                    {/* <i className="uil uil-envelope icon icon2" /> */}
                  </div>
                </div>
                <label className="label25 text-left">Password<span className="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <input type={values.showPassword ? "text" : "password"}
                    class="form-control" onChange={handlePasswordChange("password")}
                    value={values.password}
                    placeholder="Password"
                    aria-describedby="basic-addon2"
                    onKeyDown={(e) => handleKeypress(e)} />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" onClick={handleClickShowPassword}><i className=""> <FaEye /> </i></span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">

                    {/* <input type="checkbox" value="remember-me" id="rememberMe" name="rememberMe" style={{ width: "auto", height: "auto", visibility: "visible", left: "63px" }} />
                    <label class="checkbox">
                      Remember me
                    </label> */}
                  </div>
                  <div className="col-md-6">
                    <Link to="/rider/ForgetPassword"> <p><a href="">Forget Password</a></p></Link>
                    <p className="sgntrm145"></p>

                  </div>

                </div>

                <button onClick={loginUser} className="login-btn mb-2" type="button">Sign In</button>

                {/* <p className="mt-2"><i className="mx-2"><FaGoogle/></i> <i className=""> <FaFacebook/> </i></p> */}
                {/* <p><i className=""><FaGoogle/></i></p> */}

                <p className="sgntrm145"></p>

                {/* <button className="login-btn mb-2" onClick={()=>Router.push('/student/auth/signup')} type="button">Sign Up</button> */}

              </form>


              {/* <p className="sgntrm145">or <a href="#">Forget Password</a>.</p> */}

            </div>
            {/* <div className="sign_footer">© 2022 <strong>Tutor Book</strong>. All
              Rights Reserved.</div> */}
          </div>
        </div>
      </div>
    </div>
  </>

  )
}