import React, { useState, useEffect } from 'react';

import "./modal.css"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Message } from '@material-ui/icons';






export default function Failed({ status, message, func }) {
    console.log("status", status, message,func )

   
 
 


    return (
        <>




            <Modal className='text-center' isOpen={status}  size="md" centered={true}>
                <ModalHeader closeButton >
                    <modalTitle className=""></modalTitle>

                    <span className="card__success"><i className="fa fa-window-close" /></span>
                </ModalHeader>
                <ModalBody className='text-left'>
                    
                    <h2 style={{marginTop:"45px"}} class="text-center">{message}</h2>
                  


                </ModalBody>
                <ModalFooter>
                   
                    {/* <button type="button"  onClick={del}  className=" main-btn ">Yes Delete it</button> */}
                    <button type="button" onClick={func}  className=" main-btn">cancel</button>


              

                   

                </ModalFooter>
            </Modal>



        </>
    );
}
