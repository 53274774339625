import { useState } from "react";
import Swal from "sweetalert2";
import { Loader } from "react-overlay-loader"
import logo from "../../Admin/auth/newlogo.png"
import { useNavigate } from "react-router-dom";
import baseUrl from "../../../baseurl/BaseUrl";



export default function ManagForgetPass() {
  const [Email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()

  const handleInputChange = (event, func) => {
    func(event.target.value);
  }

  const Token = localStorage.getItem("ManagerToken")


  const ForgetPass = () => {

        setLoader(true)

        if(Email==""){
            setLoader(false)
            Swal.fire({
                title: "Error",
                text: "Email is required",
                icon: "error",
                confirmButtonColor: "#03bafe",
            });

          }
          else{

        const formData = new FormData()

        formData.append("email", Email);


        var requestOptions = {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + Token
            },
            body: formData,
        };
        // setLoader(true);
        fetch(`${baseUrl.baseUrl}webapi/forgotPasswordlinkSend?role=subadmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoader(false);
                if (result.status) {
                  console.log("result forget password",result.id);

                  navigate( "/ManagVerificationCode",{ state:{userId: result.id}})

                    Swal.fire({
                        title: "Success",
                        text: result.message,
                        icon: "success",
                        confirmButtonColor: "#03bafe",
                    });
                }
                else {

                    Swal.fire({
                        title: "Oops",
                        text: result.message,
                        icon: "error",
                        confirmButtonColor: "#03bafe",
                    });
                }
            })
            .catch(error => {

                setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                    confirmButtonColor: "#03bafe",
                })
            });
        }
    }





  return (<>
    {loader == true ? <Loader fullPage loading /> : null}
    <div className="sign_in_up_bg">
      <div className="container">
        <div className="row justify-content-lg-center justify-content-md-center">
          <div className="col-lg-12">

          </div>
          <div className="col-lg-6 col-md-8">
            <div className="sign_form">
              <img src={logo} width={"80px"} height={"80px"} alt=""></img>

              <h3>Forgot your password?</h3>
              <p className="mt-5">Please enter the email address associated with your account and We will email you a link to reset your password. </p>

              <form>
                <div className="ui search focus mt-50">
                  <label className="label25 text-left">Email<span className="text-danger">*</span></label>
                  <div className="ui left icon input swdh95 mb-3">
                    <input className="prompt srch_explore"  onChange={(e) => handleInputChange(e, setEmail)} value={Email}  type="email" name="emailaddress"  id="id_email" required maxLength={64} placeholder="Email Address" />
                    {/* <i className="uil uil-envelope icon icon2" /> */}
                  </div>


                </div>



                  <button onClick={()=>ForgetPass()}  className="login-btn" type="button">Send Email</button>

              </form>
             {/* <p className="mb-0 mt-30 sgntrm145">Go Back <a href="/">Sign In</a></p> */}


            </div>

          </div>
        </div>
      </div>

    </div>
  </>

  )
}