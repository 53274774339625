import React, { useState, useEffect } from 'react';

import "./modal.css"
import { Button, Form, Row, Col, FormGroup, Option, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';






export default function Error({ status, message,func , navi }) {
    console.log("status", status, message,func,navi  )
 
 


    return (
        <>




            <Modal className='text-center' isOpen={status}  size="md" centered={true}>
                <ModalHeader closeButton >
                    <modalTitle className=""></modalTitle>

                    <span className="card__success"><i className="fa fa-check" /></span>
                </ModalHeader>
                <ModalBody className='text-left'>
                    
                    <h4 style={{marginTop:"45px"}} class="text-center">{message}</h4>

                </ModalBody>
                <ModalFooter>
              

                    <button type="button" onClick={()=>{func();navi()}} className="col-2 main-btn">OK</button>

                </ModalFooter>
            </Modal>



        </>
    );
}
