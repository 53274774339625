import React from 'react'
import Services from '../../../components/servicemanagment/Services'

export default function ManagerServices() {
  return (
    <>


        <Services role={"subadmin"} status={"managerservice"}/>
    </>
  )
}
